import { IonModal, IonCard, IonContent, IonButton, IonToolbar, IonTitle, IonList, IonCardHeader, IonItem, IonItemDivider, IonLabel, IonSelectOption, IonSelect, IonCardSubtitle, IonCardContent } from '@ionic/react';
import React, {useMemo, useState} from 'react';
import { gql, useMutation } from '@apollo/client';
import EditableCard from './EditableCard'
import Input, {yup, useYupValidationResolver, Toggle} from './Input'
import { useForm } from "react-hook-form";
import ApolloStatus from './ApolloStatus'
import TeamAutorization from './TeamAuthorization'
import useTeams from '../hooks/useTeams';
import ConfirmButton from './ConfirmButton';
import { useMutationWithElevatedRole } from '../hooks/hasuraHooks';
import { useTranslation } from 'react-i18next';


const FleetForm: React.FC<any> = ({fleet, updateFleetsQuery, otherFleets, createTeamFleetRelation, onFinish}) => {
  const { control, errors, reset, handleSubmit, formState } = useForm({
      resolver: useYupValidationResolver(useMemo(() => yup.object({
      }), [])),
      defaultValues: {name: fleet.name, description: fleet.description},
  })

  const { allTeams, refetch, loadings } = useTeams()
  const { t } = useTranslation();

  const [roleAndTeam, setRoleAndTeam] = useState<any>({teamObjectRelation: null, teamUserRelation: null, role: "viewer"})

  const [deleteFleet, deleteFleetApollo] = useMutationWithElevatedRole(gql`mutation deleteFleet($id: ID!) {
      deleteFleet(input: {where: {id: $id}}) {fleet {id}}
  }`)
  const deleteFleetClean = (fleet: any) => {
    deleteFleet({variables: {id: fleet.id}})
       // Delete all team-fleet relations
      .then(() => Promise.all(fleet.teams.map((t: any) => deleteTeamFleetRelation({variables: {id: t.id}}))))
      .then(() => onFinish())
      .catch((e: any) => console.error(e))
  }
  const [updateTeamFleetRelation, updateTeamFleetRelationApollo] = useMutationWithElevatedRole(gql`mutation
      updateTeamFleetRelation($id: ID!, $role: ENUM_TEAMFLEETRELATION_ROLE) {
          updateTeamFleetRelation(
              input: {where: {id: $id},
              data: {role: $role}}
          ) {teamFleetRelation {id role}}
  }`)
  const [deleteTeamFleetRelation, deleteTeamFleetRelationApollo] = useMutationWithElevatedRole(gql`mutation deleteTeamFleetRelation($id: ID!){
      deleteTeamFleetRelation(input: {where: {id: $id}}) {teamFleetRelation {id}}
  }`)
  const [updateFleet, updateFleetApollo] = useMutationWithElevatedRole(gql`
  mutation updateFleet($fleet_id: Int, $team_fleet_relations: [team_fleet_relations_insert_input!]!, $fleet_name: String, $fleet_description :String) {
	# updating a many-to-many relationships requires
    #   - removing all relationships
    #   - inserting them all again
    # this is done in one transaction by hasura
    # see: https://hasura.io/docs/latest/graphql/core/databases/postgres/mutations/update.html#replace-all-nested-array-objects-of-an-object

    # delete removed associated teams
    delete_team_fleet_relations(
        where: {
        _and: [
            {fleet_id: {_eq: $fleet_id}},
        ]
        }) {
        affected_rows
    }
    
    # insert new associated teams
    insert_team_fleet_relations(
        objects: $team_fleet_relations
    ) {affected_rows}
    
    # update other fleet fields
    update_fleets(where: {id: {_eq: $fleet_id}}, _set: {name: $fleet_name, description: $fleet_description}) {
        returning {
            id
            name
            description
            teams {
                id
                role
            }
        }  
    }
  }`, {context: {"headers": {"X-Hasura-Role": "admin"}}})
  const [updateRobot, updateRobotApollo] = useMutationWithElevatedRole(gql`mutation
      updateRobot($robot: ID!, $fleet: ID!) {
          updateRobot(
              input: {where: {id: $robot},
              data: {fleet: $fleet}}
          ) {robot {id fleet {id}} }
  }`)
  const addTeamToFleet = (team: any, fleet: any) => {
    createTeamFleetRelation({variables: {"team_id": team.id, role: "viewer", "fleet_id": fleet.id}})
      .then((d: any) => updateFleet({variables: {
          id: fleet.id,
          team_fleet_relations: fleet.teams.map((u: any) => u.id).concat([d.data.createTeamFleetRelation.teamFleetRelation.id]).map((team_id: number)=>{return {"team_id": team_id, "fleet_id": fleet.id}})
        }}))
      .catch((e: any) => console.error(e))
  }

  const changeTeamRole = (teamRelationId: any, role: string) => {
      if (role === "remove") {
        const fleetMap = (f: any) => {
            if (f.id !== fleet.id) {return f}  // Only edit current fleet
            return {...f, teams: f.teams.filter((t: any) => t.id !== teamRelationId)}
        }
        deleteTeamFleetRelation({variables: {id: teamRelationId}})
        .then(() => updateFleetsQuery((prev: any) => ({fleets: prev.fleets.map(fleetMap)})))
        .catch((e: any) => console.error(e))
      }
      else {
        updateTeamFleetRelation({variables: {id: teamRelationId, role: role}}).catch((e: any) => null)
      }
  }

  const onSubmit = handleSubmit((data: any) => {updateFleet({variables: {
      "fleet_id": fleet.id,
      "team_fleet_relations": fleet.teams.map((team : any)=>{return {"team_id": team.id, "fleet_id": fleet.id}}),
      "fleet_name": data.name,
      "fleet_description": data.description
      //...data
    }}).then(() => {onFinish()}).catch((e: any) => null)})
  const numberOfOwners = fleet.teams.filter((team: any) => team.role === "owner").length

  const {role, teamFleetRelation} = roleAndTeam

  // Can only leave fleet if rights to team are given
  // And if fleet has at least one other owner team
  const canLeaveFleet = roleAndTeam?.teamUserRelation?.role === "owner" && (roleAndTeam?.teamObjectRelation?.role !== "owner" || numberOfOwners > 1)

  return <IonModal onDidDismiss={() => onFinish()} isOpen={true}>
        <IonToolbar color="primary"><IonTitle>{t("Edit fleet")}</IonTitle>
            {!formState.isDirty && <IonButton color="light" slot="end" fill="clear" onClick={() => onFinish()}>{t("Cancel")}</IonButton>}
            {formState.isDirty && <IonButton slot="end" fill="clear" color="light" onClick={() => reset()}>{t("Undo")}</IonButton>}
            {formState.isDirty && <IonButton slot="end" color="secondary" onClick={() => onSubmit()}>{t("Save changes")}</IonButton>}
        </IonToolbar>

        <IonContent>
            <TeamAutorization roleAndTeam={roleAndTeam} setRoleAndTeam={(d: any) => {setRoleAndTeam(d); reset()}} name={fleet.name} teamRelations={fleet.teams} />

            <ApolloStatus refetch={refetch} loadings={[deleteFleetApollo.loading].concat(loadings)} errors={[deleteFleetApollo.error, deleteTeamFleetRelationApollo.error, updateFleetApollo.error, updateTeamFleetRelationApollo.error]}/>

            {role !== "viewer" && 
            <IonCard>
                <IonCardHeader><IonCardSubtitle>{t("Edit info")}</IonCardSubtitle></IonCardHeader>
                <IonCardContent>
                <form onSubmit={onSubmit}>
                    <Input control={control} errors={errors} name="name"/>
                    <Input control={control} errors={errors} name="description" component={EditableCard} showLabel={false}/>
                    <input type="submit" style={{visibility: "hidden"}}/>
                </form>
                 </IonCardContent>
            </IonCard>}

            <IonCard>
                <IonCardHeader><IonCardSubtitle>{t("Move Robots")}</IonCardSubtitle></IonCardHeader>
                <IonCardContent>
                    {fleet.robots.map((r: any) => <IonItem key={r.id}>
                        <IonLabel>{r.name}</IonLabel>
                        <IonSelect disabled={role !== "owner"} onIonChange={(e: any) => updateRobot({variables: {fleet: e.detail.value, robot: r.id}}).catch((e: any) => console.error(e))}
                                   value={r.fleet.id}>
                            {otherFleets.map((fleet: any, i: number) => <IonSelectOption key={i} value={fleet.id}>{fleet.name}</IonSelectOption>)}
                        </IonSelect>
                    </IonItem>)}
                </IonCardContent>
            </IonCard>

            <IonCard>
                <IonCardHeader><IonCardSubtitle>{t("Fleet members")}</IonCardSubtitle></IonCardHeader>
                <IonCardContent>
                    {fleet.teams.map((u: any, id: number) => {
                    if (!u?.team) {return null}
                    const changeIsAllowed = (role === "owner" && u.role !== "owner") || (u?.team?.id === teamFleetRelation?.team?.id && role === "owner" && numberOfOwners > 1)
                    return <IonItem key={id}>
                        <IonLabel style={{opacity: 1.}}>{u.team.name}</IonLabel>
                        <IonSelect disabled={!changeIsAllowed} value={u.role} onIonChange={(e: any) => changeTeamRole(u.id, e.detail.value)} >
                            {["owner", "editor", "viewer", "remove"].map((s, i) => <IonSelectOption key={i} value={s}>{s}</IonSelectOption>)}
                        </IonSelect>
                    </IonItem>})}
                    {role !== "viewer" && <IonItem>
                        <IonLabel style={{fontSize: "14px", fontWeight: "500", textTransform: "uppercase"}}>{t("invite team")}</IonLabel>
                        <IonSelect onIonChange={(e: any) => addTeamToFleet(e.detail.value, fleet)}>
                            {allTeams.map((team: any, i: number) => <IonSelectOption key={i} value={team}>{team.name}</IonSelectOption>)}
                        </IonSelect>
                    </IonItem>}
                    <ConfirmButton expand="block" color="danger" fill="clear" disabled={!canLeaveFleet} text={t("Leave fleet?")}
                        onClick={() => deleteTeamFleetRelation({variables: {id: roleAndTeam?.teamObjectRelation?.id}}).then(() => onFinish())}>{t("Leave fleet with {{name}}", {name: roleAndTeam?.teamUserRelation?.team?.name})} </ConfirmButton>
                </IonCardContent>
            </IonCard>

            <IonButton color="danger" expand="block" fill="clear" disabled={numberOfOwners > 1 || role !== "owner"} onClick={() => deleteFleetClean(fleet)}>{t("Delete fleet")}</IonButton>
        </IonContent>
    </IonModal>
}
export default FleetForm;