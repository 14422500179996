import { gql } from "@apollo/client";
import { useEffect, useState } from "react";
import getUsername from "../components/lib/getUsername";
import { useQueryWithElevatedRole, useSubscriptionWithElevatedRole } from "./hasuraHooks";


export const useUsername = () => {
  // Helper to get username without repeatedly checking jwt
  const [username, setUsername] = useState<String|null>(null)
  useEffect(() => {
    // Initially set username
    setUsername(getUsername())
  }, [])
  return username
}


export const useUserId = () => {
  const userName = useUsername()
  const {loading, data} = useQueryWithElevatedRole(
    gql`
      query GetMyUser($name: String!) {
        users(where: {name: {_eq: $name}}) {
          id
          name
        }

      }
    `, {variables: {name: userName || ""}}
  )
  if (loading || !data || !userName) {return undefined}
  // Get the id by going through users
  return data?.users?.find(({name}: any) => name === userName)?.id
}