import React, { FC, useCallback } from "react";
import { IonItem, IonLabel, IonInput, IonText, IonToggle, IonSelect, IonSelectOption } from "@ionic/react";
import {
  Controller,
  Control,
} from "react-hook-form";
import * as YUP from "yup";

export const yup = YUP

export interface InputProps {
  name: string
  control?: Control
  label?: string
  component?: any
  errors?: any
  props?: any
  parse?: any
  showLabel?: boolean
  wrapInItem?: boolean
  position?: "fixed" | "floating" | "stacked" | undefined
}

export const useYupValidationResolver = (validationSchema: any) =>
  useCallback(
    async data => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false
        });

        return {
          values,
          errors: {}
        };
      } catch (errors) {
        return {
          values: {},
          errors: errors.inner.reduce(
            (allErrors: any, currentError: any) => ({
              ...allErrors,
              [currentError.path]: {
                type: currentError.type ?? "validation",
                message: currentError.message
              }
            }),
            {}
          )
        };
      }
    },
    [validationSchema]
  );



export const Toggle: FC<any> = ({onChange, onIonChange, value}) => <IonToggle checked={value} onIonChange={(e: any) => onIonChange({detail: {value: e.detail.checked}})}></IonToggle>
export const Select: FC<any> = ({onChange, onIonChange, value, options}) => <IonSelect value={value} onIonChange={(e: any) => onIonChange({detail: {value: e.detail.value}})}>
  {options?.map((opt: any) => <IonSelectOption key={opt} value={opt}>{opt}</IonSelectOption>)}
</IonSelect>

const Input: FC<InputProps> = ({ name, wrapInItem = true, showLabel = true, position="stacked", control, component = IonInput, label, errors, props, children, parse = (v: any) => v }) => {
  const el = <>
      <Controller
        render={({ onChange, onBlur, value }) => React.createElement(component,
            {
                onIonChange: (e:any) => onChange(parse(e.detail.value)),
                onChange: (e: any) => onChange(parse(e.detail.value)),
                value: value,
                children: children,
                ...props
            })}
        control={control} name={name}
      />
      {errors && errors[name] && (
        <IonText color="danger" className="ion-padding-start">
          <small>
            <span role="alert" id={`${name}Error`}>
              {errors[name].message}
            </span>
          </small>
        </IonText>
      )}
  </>
  if (wrapInItem) {
    return <IonItem>
      {showLabel && <IonLabel style={{textTransform: "capitalize"}} position={position}>{label ? label : name}</IonLabel>}
      {el}
    </IonItem>
  }
  else {
    return el
  }
};

export default Input;
