import { IonButton, IonCard, IonTitle, IonCardContent, IonCardHeader, IonCardTitle, IonPicker, IonModal, IonToolbar, IonContent, IonItem } from '@ionic/react';
import React, {useState} from 'react';
import history from '../history'

const XavierCard: React.FC<any> = ({xavier, availableXaviers, createXavier, updateComponent, onFinish}) => {
    const [addXavier, setAddXavier] = useState(false)

    return <IonCard>
        <IonCardHeader><IonCardTitle>Xavier</IonCardTitle></IonCardHeader>
        <IonCardContent>
            {xavier === null && <IonButton fill="clear" expand="block" onClick={() => setAddXavier(true)}>Add xavier</IonButton>}
            <IonPicker
                onDidDismiss={(data: any) => {setAddXavier(false)}} isOpen={addXavier}
                buttons={[
                    {text: "cancel", role: "submit", handler: (data: any) => {setAddXavier(false)}},
                    {text: "create new xavier", role: "submit", handler: (data: any) => {createXavier()}},
                    {text: "select xavier", role: "submit", handler: (data: any) => updateComponent({variables: {xavier: data.xavier.value}})},
                ]}
                columns={[
                    {name: "xavier", options: availableXaviers.map((p: any) => {return {text: (p.hostname || "unnamed xavier") + ` (${(p.aquila?.name || p.robot?.name || "free")})` , value: p.id}})},
                ]}
            />

            {xavier && <IonItem>
                <IonButton fill="clear" onClick={() => {history.push(`/xavier/${xavier.id}`); onFinish()}}>{xavier.hostname || "unnamed xavier"}</IonButton>
                <IonButton color="danger" fill="clear" slot="end" onClick={() => updateComponent({variables: {xavier: null}})}>remove</IonButton>
            </IonItem>}
        </IonCardContent>
    </IonCard>
}

export default XavierCard;
