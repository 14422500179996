import { IonList, IonButton, IonContent, IonPage, IonItem, IonLabel, IonBadge, IonIcon, IonChip, IonPopover, IonToolbar, IonCard, IonCardHeader, IonCardContent, IonCardSubtitle, IonInput, IonToggle, IonFab, IonFabButton, IonSelect, IonSelectOption, IonSegment, IonSegmentButton, IonGrid, IonRow, IonCol, } from '@ionic/react';
import React, { memo, useState } from 'react';
import Toolbar from '../components/Toolbar'
import Loading from '../components/Loading'
import { useMutationWithElevatedRole, useQueryWithElevatedRole } from '../hooks/hasuraHooks';
import { gql } from '@apollo/client';


const useUpdatePlantVariety = () => {
  const [updatePlantVariety] = useMutationWithElevatedRole(gql`
      mutation UpdatePlantVariety($id: Int!, $_set: plant_varieties_set_input = {}) {
        update_plant_varieties_by_pk(pk_columns: {id: $id}, _set: $_set) {
          id
        }
      }
    `, {})
  return updatePlantVariety
}


const PlantNameEditor: React.FC<any> = ({lang, initialName, plantId}: any) => {
  const [name, setName] = useState(initialName)
  const changed = initialName != name
  const updatePlantVariety = useUpdatePlantVariety()
  return <IonItem>
    <IonInput value={name} onIonChange={(e: any) => setName(e.detail.value)}/>
    <IonButton disabled={!changed} slot="end"
      onClick={() => updatePlantVariety({variables: {id: plantId, _set: {["name_" + lang.toUpperCase()]: name}}})}
    >{lang}</IonButton>
  </IonItem>
}


const IsCrop: React.FC<any> = ({isCrop, plantId}: any) => {
  const updatePlantVariety = useUpdatePlantVariety()
  return <IonItem>
    <IonLabel>{isCrop ? "Crop" : "Weed"}</IonLabel>
    <IonSegment value={isCrop ? "crop" : "weed"} onIonChange={(e: any) => updatePlantVariety({
      variables: {id: plantId, _set: {is_crop: e.detail.value === "crop"}}
    })}  color={isCrop ? "success" : "danger"}>
      <IonSegmentButton value={"weed"}>Weed</IonSegmentButton>
      <IonSegmentButton value={"crop"}>Crop</IonSegmentButton>
    </IonSegment>
  </IonItem>
}


const PlantVarieties: React.FC = () => {
  const { loading, data } = useQueryWithElevatedRole(gql`query{
      plant_varieties (order_by: {is_crop: asc, name_EN: asc}) {
        id
        name_DE
        name_EN
        name_FR
        name_LA
        name_ES
        
        show_on_website
        class_label
        class_name
        is_crop
      }
  }`, {pollInterval: 300});
  const [showDeeplearning, setShowDeeplearning] = useState(false)
  
  const [insertPlants] = useMutationWithElevatedRole(gql`
    mutation InsertPlants($objects: [plant_varieties_insert_input!] = []) {
      insert_plant_varieties(objects: $objects) {
        returning {
          id
        }
      }
    }
  `)

  const [languageCodes, setLanguageCodes] = useState(["la", "en"])
  const updatePlantVariety = useUpdatePlantVariety()

  return (
    <IonPage>
      <Toolbar name="Plant varieties"/>
      <IonContent fullscreen>
          <IonItem>
            <IonLabel>Languages</IonLabel>
            <IonSelect multiple value={languageCodes} onIonChange={(e: any) => setLanguageCodes(e.detail.value)}>
              {["en", "de", "fr", "es", "la"].map((l: string) => <IonSelectOption key={l} value={l}>{l}</IonSelectOption>)}
            </IonSelect>
          </IonItem>
          {loading && <Loading/>}
          {data && <IonGrid>
            <IonRow>
              {languageCodes.map((languageCode: string) => 
                <IonCol key={languageCode}>
                  <strong>Name ({languageCode})</strong>
                </IonCol>
              )}
              <IonCol/>
              <IonCol size="1">Show on website</IonCol>
              <IonCol size={showDeeplearning ? "2" : "1"}>
                <IonButton onClick={() => setShowDeeplearning(!showDeeplearning)}>{showDeeplearning ? "Hide" : "Show more"}</IonButton>
              </IonCol>
            </IonRow>
            {data.plant_varieties.map((plant: any) => <IonRow key={plant.id}>
              {languageCodes.map((languageCode: string) => 
                <IonCol key={languageCode}>
                  <PlantNameEditor lang={languageCode} initialName={plant[`name_${languageCode.toUpperCase()}`]} plantId={plant.id}/>
                </IonCol>
              )}
              <IonCol>
                <IsCrop plantId={plant.id} isCrop={plant.is_crop}/>
              </IonCol>
              <IonCol size="1">
                <IonToggle checked={plant.show_on_website} onIonChange={(e: any) => updatePlantVariety({
                  variables: {id: plant.id, _set: {show_on_website: e.detail.checked}}
                })}/>
              </IonCol>
              <IonCol size={showDeeplearning ? "2" : "1"}>
                {showDeeplearning && plant.class_label !== null && <IonBadge>{plant.class_label} - {plant.class_name}</IonBadge>}
                <IonBadge>ID: {plant.id}</IonBadge>
              </IonCol>
            </IonRow>)}
          </IonGrid>}
      </IonContent>
      <IonFab horizontal='start' vertical='bottom'>
        <IonFabButton color="secondary" onClick={() => insertPlants({variables: {objects: [{}]}})}>+</IonFabButton>
      </IonFab>
    </IonPage>
  );
};


export default PlantVarieties;