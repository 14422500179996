import {
  IonList,
  IonButton,
  IonContent,
  IonPage,
  IonItem,
  IonLabel,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonInput,
  IonAccordion,
  IonAccordionGroup,
  IonTitle,
  IonFab,
  IonRange,
  IonGrid,
  IonRow,
  IonCol,
  IonText,
  IonCardTitle,
  IonIcon,
} from "@ionic/react";
import React, { useState } from "react";
import Toolbar from "../components/Toolbar";
import { gql } from "@apollo/client";
import Loading from "../components/Loading";
import { CropSelector } from "../shared-components/RuleSelector";
import { useSubscriptionWithElevatedRole } from "../hooks/hasuraHooks";
import useImage from "use-image";
import useWindowDimensions from "../useWindowDimensions";
import { NumberInput } from "../shared-components/NumberInput";
import { information } from "ionicons/icons";
import { SprayerConfigurator } from "../components/SprayerConfigurator";
import { useTranslation } from "react-i18next";
const humanizeDuration = require("humanize-duration");

const TimeUnits: React.FC<any> = ({ t_s }: any) => {
  return <span style={{ fontWeight: "bolder" }}>{humanizeDuration(t_s * 1000, { round: true, largest: 2 })}</span>;
};

export const ImageView: React.FC<{ filepath: string }> = ({ filepath }: any) => {
  const { isLargeScreen } = useWindowDimensions();
  const [image] = useImage(filepath);
  return <img src={filepath} style={{ flex: 1, objectFit: "cover", height: "100%", width: "100%" }} />;
};

export const Configurator: React.FC = () => {
  const { loading, data } = useSubscriptionWithElevatedRole(gql`
    subscription Demo {
      plant_varieties(where: { is_crop: { _eq: true } }) {
        id
        name_EN
        name_DE
        name_FR
        name_ES
        name_LA
        param_config_rules(order_by: { id: desc }, where: { validated: { _eq: true } }) {
          id
          description
          tags {
            id
            tag {
              name
              id
            }
          }
          recommended_param_config {
            id
            description
            data
          }
          plant_variety {
            id
          }
        }
      }
    }
  `);
  const [cropId, setCropId] = useState<any>(1);

  const defaultData = {
    acreageHa: 15.,
    interrowDistance_m: 0.45,
    robotRows: 6,
    timePerTurn_s: 120,
    weedingSpeed_m_per_s: 1.5 / 3.6,
    weatherDowntime: 0.4,
    errorDowntime: 0.5,
    time_s: 24 * 60 * 60,
    includeTurningTime: false,
    pureWeedingTime_s: 24 * 60 * 60,
    fullCycleTime_s: 14 * 24 * 60 * 60,
    fuelConsumption_L_per_h: 1.5,
    fuelTankVolume_L: 30,
    fuelCost_eur_per_ha: 50,
    fuelCost_per_L: 1.8,
    knifeSelection: ["smallFlat", "smallSteep", "largeFlat", "LargeSteep"],
    adjustmentRanges_mm: [1350, 1500, 1800, 2000, 2250],
    knifeAngle: "steep",
    adjustedRange_mm: 1350,
    adjustmentRange_index: 3,
    timelineWidth: 800,
  };
  const intProps: any = { type: "number", min: "1", step: "1" };
  const [dataCalculations, setData] = useState<any>(defaultData);

  const { acreageHa, interrowDistance_m, robotRows, timePerTurn_s, weedingSpeed_m_per_s, weatherDowntime, errorDowntime, time_s, pureWeedingTime_s, includeTurningTime, fullCycleTime_s, fuelConsumption_L_per_h, fuelCost_per_L } =
    dataCalculations;

  const fieldWidth_m = Math.sqrt(acreageHa * 10000);
  const fieldLength_m = Math.sqrt(acreageHa * 10000);
  const fieldWidthPixels = 200;
  const fieldLengthPixels = 200;

  const fieldRows = Math.round(fieldWidth_m / interrowDistance_m);
  const fieldTracks = fieldRows / robotRows;
  const timeSpentTurning_s = fieldTracks * timePerTurn_s;
  const timeSpentWeedingPerTrack_s = fieldLength_m / weedingSpeed_m_per_s;
  const timeSpentWeeding_s = fieldTracks * timeSpentWeedingPerTrack_s;
  const timeSpent_s = timeSpentTurning_s + timeSpentWeeding_s;
  const timeSpentWithDownTime_s = timeSpent_s / (1 - errorDowntime) / (1 - weatherDowntime);

  const weedingDistancePerTime = time_s * weedingSpeed_m_per_s;
  const weedingAcreageHaPerTime = (weedingDistancePerTime * robotRows * interrowDistance_m) / 10000;
  const timeWithTurning_s = time_s - timeSpentTurning_s;
  const fuelCost_per_ha = (timeSpentWeeding_s / 60 / 60 / acreageHa) * fuelConsumption_L_per_h * fuelCost_per_L;
  const knifeSize = interrowDistance_m * 1000 >= 400 ? "Large" : "Small";
  dataCalculations.timelineWidth = useWindowDimensions().width * (useWindowDimensions().isLargeScreen ? 0.6 : 0.8);

  return (
    <IonPage>
      <Toolbar name="Configurator">
        <IonTitle>Configurator</IonTitle>
        <IonButton slot="end" onClick={() => setData({ ...defaultData })}>Reset</IonButton>
      </Toolbar>

      <IonContent fullscreen>
        {loading && <Loading />}
        {data && <div style={{
                display: "flex",
                flexWrap: "wrap",
                marginLeft: 0,
                position: "relative",
              }}
            >
              {" "}
              <IonCard style={{flexGrow: 1}}>
                <IonCardContent style={{ display: "flex", flexWrap: "Wrap", alignItems: "center"}}>
                  <FieldVisualizer fieldWidthPixels={fieldWidthPixels} fieldLengthPixels={fieldLengthPixels} weedingAcreageHaPerTime={weedingAcreageHaPerTime}
                    fieldLength_m={fieldLength_m} fieldWidth_m={fieldWidth_m} acreageHa={acreageHa} setData={setData} intProps={intProps}/>
                  <TimelineVisualizer timeSpentWeeding_s={timeSpentWeeding_s} fullCycleTime_s={fullCycleTime_s} timeSpentTurning_s={timeSpentTurning_s}
                    pureWeedingTime_s={pureWeedingTime_s} dataCalculations={dataCalculations}/>
                </IonCardContent>
              </IonCard>
            <RowInputs dataCalculations={dataCalculations} setData={setData} intProps={intProps} robotRows={robotRows} data={data} setCropId={setCropId} cropId={cropId}
              defaultData={defaultData} knifeSize={knifeSize}/>
            <SprayerConfigurator fieldSizeHa={acreageHa}/>

            {DetailsPanel(
              dataCalculations,
              setData,
              intProps,
              fieldTracks,
              timeSpentTurning_s,
              includeTurningTime,
              timeWithTurning_s,
              pureWeedingTime_s,
              timeSpentWeedingPerTrack_s,
              timeSpentWeeding_s,
              weedingAcreageHaPerTime,
              weatherDowntime,
              errorDowntime,
              timeSpent_s,
              timeSpentWithDownTime_s,
              fuelConsumption_L_per_h,
              weedingSpeed_m_per_s,
              fieldWidth_m,
              fieldLength_m,
              fuelCost_per_L,
              fuelCost_per_ha
            )}
        </div>}
      </IonContent>
    </IonPage>
  );
};
export default Configurator;

export const FieldVisualizer: React.FC<any> = ({
  fieldWidthPixels, fieldLengthPixels, weedingAcreageHaPerTime, fieldLength_m, fieldWidth_m, acreageHa, setData, intProps
}: any) => {
  const {t} = useTranslation()
  return (
    <IonCardContent>
      <IonItem
        style={{
          position: "flex",
        }}
      >
        <IonLabel position="stacked">{t("Acreage")} (ha)</IonLabel>
        <IonInput
          value={acreageHa}
          onIonChange={(e: any) =>
            setData((prev: any) => ({
              ...prev,
              acreageHa: e.detail.value,
            }))
          }
          {...intProps}
        ></IonInput>
      </IonItem>
      <IonCardContent
        style={{
          width: fieldWidthPixels + 22,
          marginLeft: 20,
        }}
      >
        <IonCard style={{ boxShadow: "none" }}>
          <IonContent
            color="primary"
            style={{
              height: fieldLengthPixels,
              width: fieldWidthPixels,
            }}
          >
            <IonContent
              color="secondary"
              style={{
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                position: "relative",
                borderRadius: 4,
                height: (Math.sqrt(weedingAcreageHaPerTime * 10000) / fieldLength_m) * fieldLengthPixels < fieldLengthPixels ? (Math.sqrt(weedingAcreageHaPerTime * 10000) / fieldLength_m) * fieldLengthPixels : fieldLengthPixels,
                width: (Math.sqrt(weedingAcreageHaPerTime * 10000) / fieldWidth_m) * fieldWidthPixels < fieldWidthPixels ? (Math.sqrt(weedingAcreageHaPerTime * 10000) / fieldWidth_m) * fieldWidthPixels : fieldWidthPixels,
              }}
            >
              <IonLabel
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                {Math.round(weedingAcreageHaPerTime * 100) / 100} ha in 24 h
              </IonLabel>
            </IonContent>
          </IonContent>
        </IonCard>
      </IonCardContent>
    </IonCardContent>
  );
}

export const RowInputs: React.FC<any> = ({
  dataCalculations,
  setData,
  intProps,
  robotRows,
  data,
  setCropId,
  cropId,
  knifeSize,
}: any) => {
  const {t} = useTranslation()
  return <>
    <IonCard style={{ display: "flex", flexWrap: "wrap", flexGrow: 1, flexDirection: "column"}}>
      <div style={{ borderBottom: "1px solid"}}>
        <IonItem>
          <IonLabel position="stacked">{t("Number of rows")}</IonLabel>
          <IonInput
            value={dataCalculations.robotRows}
            onIonChange={(e: any) =>
              setData((prev: any) => ({
                ...prev,
                robotRows: e.detail.value,
              }))
            }
            {...intProps}
            min={3}
            max={6}
          ></IonInput>
        </IonItem>
        <IonRange
          aria-label="Number of rows"
          min={3}
          max={6}
          pin={true}
          value={dataCalculations.robotRows}
          ticks={true}
          snaps={true}
          pinFormatter={(value: number) => `${value}`}
          onIonKnobMoveEnd={(e: any) => {
            setData((prev: any) => ({
              ...prev,
              robotRows: e.detail.value,
            }));
          }}
        ></IonRange>
      </div>
      <div style={{ borderBottom: "1px solid"}}>
        <IonItem>
          <IonLabel position="stacked">{t("Interrow distance")} (cm)</IonLabel>
          <IonInput
            value={Math.round(dataCalculations.interrowDistance_m * 100 * 10) / 10}
            onIonChange={(e: any) => {
              setData((prev: any) => ({
                ...prev,
                interrowDistance_m: e.detail.value / 100,
              }));
            }}
            {...intProps}
            min={35}
            max={300 / robotRows}
          ></IonInput>
        </IonItem>

        <IonRange
          aria-label="interrow distance"
          min={35}
          max={300 / robotRows}
          pin={true}
          value={dataCalculations.interrowDistance_m * 100}
          pinFormatter={(value: number) => `${value} cm`}
          onIonKnobMoveEnd={(e: any) => {
            setData((prev: any) => ({
              ...prev,
              interrowDistance_m: e.detail.value / 100,
            }));
          }}
        ></IonRange>
      </div>
      <div>
        <IonItem style={{ marginBottom: 20 }}>
          <IonLabel position="stacked">{t("Track width")}</IonLabel>
          {dataCalculations.adjustmentRanges_mm[dataCalculations.adjustmentRange_index - 1]} mm
        </IonItem>

        <IonRange
          aria-label="Adjustment range"
          ticks={true}
          snaps={true}
          min={0}
          max={dataCalculations.adjustmentRanges_mm.length}
          value={dataCalculations.adjustmentRange_index}
          onIonChange={({ detail }) => {
            setData((prev: any) => ({
              ...prev,
              adjustmentRange_index: detail.value,
            }));
          }}
          {...intProps}
        ></IonRange>
      </div>
      
    </IonCard>
    <IonCard style={{display: "flex", flexDirection: "column", flexGrow: 1}}>

      <IonItem>
        <IonLabel position="stacked">{t("Crop")}</IonLabel>
        <CropSelector rules_by_crop={data.plant_varieties} setCropId={setCropId} cropId={cropId} skipCropsWithNoRule={true} />
      </IonItem>

      <div style={{ position: "relative", width: 250, height: 330 }}>
        <KnifeImageSwitch knifeSize={knifeSize}/>
      </div>

    </IonCard>
  </>
}

function DetailsPanel(
  dataCalculations: any,
  setData: React.Dispatch<any>,
  intProps: any,
  fieldTracks: number,
  timeSpentTurning_s: number,
  includeTurningTime: any,
  timeWithTurning_s: number,
  pureWeedingTime_s: any,
  timeSpentWeedingPerTrack_s: number,
  timeSpentWeeding_s: number,
  weedingAcreageHaPerTime: number,
  weatherDowntime: any,
  errorDowntime: any,
  timeSpent_s: number,
  timeSpentWithDownTime_s: number,
  fuelConsumption_L_per_h: number,
  weedingSpeed_m_per_s: number,
  fieldWidth_m: number,
  fieldLength_m: number,
  fuelCost_per_L: number,
  fuelCost_per_ha: number
) {
  return (
    <IonCardContent style={{ flex: 0.5 }}>
      <IonAccordionGroup expand="inset">
        <IonAccordion value={"MoreData"}>
          <IonItem slot="header" color="light">
            {"Details"}
          </IonItem>
          <IonList lines="none" slot="content">
            <IonCard style={{ margin: 10 }}>
              <IonCardHeader>Weeding</IonCardHeader>
              <IonCardContent style={{ display: "flex", flexWrap: "wrap" }}>
                <IonItem>
                  <IonLabel position="stacked">Time spent weeding</IonLabel>
                  <TimeUnits t_s={timeSpentWeeding_s} />
                </IonItem>
                <IonItem>
                  <IonLabel position="stacked">Weeding speed (km / h)</IonLabel>
                  <IonInput
                    value={Math.round(weedingSpeed_m_per_s * 3.6 * 100) / 100}
                    onIonChange={(e: any) =>
                      setData((prev: any) => ({
                        ...prev,
                        weedingSpeed_m_per_s: e.detail.value / 3.6,
                      }))
                    }
                    step="0.05"
                    type="number"
                    min="0.1"
                  />
                </IonItem>

                <IonItem>
                  <IonLabel position="stacked">Time spent weeding per track</IonLabel>
                  <TimeUnits t_s={timeSpentWeedingPerTrack_s} />
                </IonItem>
              </IonCardContent>
            </IonCard>

            <IonCard style={{ margin: 10 }}>
              <IonCardHeader>Fuel and Cost</IonCardHeader>
              <IonCardContent style={{ display: "flex", flexWrap: "wrap" }}>
                <IonItem>
                  <IonLabel position="stacked">Fuel cost per ha</IonLabel>
                  {Math.round(fuelCost_per_ha * 100) / 100} €
                </IonItem>

                <IonItem>
                  <IonLabel position="stacked">Fuel price (€ / liter)</IonLabel>
                  <IonInput
                    value={dataCalculations.fuelCost_per_L}
                    onIonChange={(e: any) =>
                      setData((prev: any) => ({
                        ...prev,
                        fuelCost_per_L: e.detail.value,
                      }))
                    }
                    {...intProps}
                  />
                </IonItem>

                <IonItem>
                  <IonLabel position="stacked">Fuel for weeding (liters)</IonLabel>
                  {Math.round(fuelConsumption_L_per_h * (timeSpentWeeding_s * (1 / (60 * 60))) * 10) / 10}
                </IonItem>

                <IonItem>
                  <IonLabel position="stacked">total fuel cost</IonLabel>
                  {Math.round(fuelConsumption_L_per_h * (timeSpentWeeding_s * (1 / (60 * 60))) * fuelCost_per_L * 100) / 100} €
                </IonItem>
              </IonCardContent>
            </IonCard>

            <IonCard style={{ margin: 10 }}>
              <IonCardHeader>Turns</IonCardHeader>
              <IonCardContent style={{ display: "flex", flexWrap: "wrap" }}>
                <IonItem>
                  <IonLabel position="stacked">Time per turns (min)</IonLabel>
                  <IonInput
                    value={dataCalculations.timePerTurn_s / 60}
                    onIonChange={(e: any) =>
                      setData((prev: any) => ({
                        ...prev,
                        timePerTurn_s: 60 * e.detail.value,
                      }))
                    }
                    {...intProps}
                  />
                </IonItem>

                <IonItem>
                  <IonLabel position="stacked">Number of tracks (or turns)</IonLabel>
                  {Math.round(fieldTracks * 10) / 10}
                </IonItem>

                <IonItem>
                  <IonLabel position="stacked">Time spent turning</IonLabel>
                  <TimeUnits t_s={timeSpentTurning_s} />
                </IonItem>

                <IonItem>
                  <IonLabel position="stacked">Monitored work time </IonLabel>
                  <TimeUnits t_s={(2 * (fieldWidth_m + fieldLength_m)) / weedingSpeed_m_per_s} />
                </IonItem>
              </IonCardContent>
            </IonCard>
          </IonList>
        </IonAccordion>
      </IonAccordionGroup>
    </IonCardContent>
  );
}

export const TimelineVisualizer: React.FC<any> = ({
  timeSpentWeeding_s, fullCycleTime_s, timeSpentTurning_s, pureWeedingTime_s, dataCalculations
}: any) => {
  const timelineWidth = dataCalculations.timelineWidth;
  const weedingTimelineWidth = (timelineWidth * timeSpentWeeding_s) / fullCycleTime_s;
  const turningTimelineWidth = (timelineWidth * timeSpentTurning_s) / fullCycleTime_s;
  const timelineIsFull = timelineWidth - (weedingTimelineWidth + turningTimelineWidth) < 0;
  const weatherMargin = (fullCycleTime_s - timeSpentTurning_s - timeSpentWeeding_s) / fullCycleTime_s;
  const textFitContainerMinSize = 100;
  const weedingTextFits = weedingTimelineWidth > textFitContainerMinSize;
  const turningTextFits = turningTimelineWidth > textFitContainerMinSize;
  const weatherMarginTextFits = weatherMargin * timelineWidth > 170;
  const {t} = useTranslation()
  return (
    <IonCardContent>
      <IonItem style={{ alignItems: "center", justifyContent: "center" }}>
        <IonCardHeader>
          <IonLabel>14 {t("days")} Timeline</IonLabel>
        </IonCardHeader>
      </IonItem>

      <IonCardContent
        style={{
          height: 80,
          display: "flex",
          justifyContent: "left",
          width: timelineWidth,
          paddingLeft: 8,
          paddingRight: 8,
          borderRadius: 10,
          borderWidth: 3,
          marginBottom: 20,
        }}
      >
        <IonCard
          color={timelineIsFull ? "danger" : "success"}
          style={{
            textTransform: "none",
            width: weedingTimelineWidth,
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow: "visible",
            boxShadow: "none",
          }}
        >
          <IonLabel color={weedingTextFits ? "light" : "success"} style={{ width: textFitContainerMinSize, position: "absolute", top: "50%", left: "50%", transform: weedingTextFits ? "translate(-50%, -50%)" : "translate(-30%, 240%)" }}>
            {Math.round(timeSpentWeeding_s / 60 / 60)} h {t("weeding")}
          </IonLabel>
        </IonCard>
        <IonCard
          color={timelineIsFull ? "danger" : "primary"}
          style={{
            textTransform: "none",
            position: "relative",
            width: turningTimelineWidth,
            display: "flex",
            boxShadow: "none",
            overflow: "visible",
          }}
        >
          <IonLabel color={turningTextFits ? "light" : "primary"} style={{ width: textFitContainerMinSize, position: "absolute", top: "50%", left: "50%", transform: turningTextFits ? "translate(-50%, -50%)" : "translate(-30%, 150%)" }}>
            {Math.round(timeSpentTurning_s / 60 / 60)} h {t("turning")}
          </IonLabel>
        </IonCard>

        <IonCard
          color={timelineIsFull ? "danger" : "medium"}
          style={{
            textTransform: "none",
            width: timelineWidth - (weedingTimelineWidth + turningTimelineWidth),
            position: "relative",
            display: "flex",
            overflow: "visible",
            boxShadow: "none",
          }}
        >
          <IonLabel color={weatherMarginTextFits ? "light" : "medium"} style={{ width: 170, position: "absolute", top: "50%", left: "50%", transform: weatherMarginTextFits ? "translate(-50%, -50%)" : "translate(-73%, 240%)" }}>
            {Math.round(weatherMargin * 100)}% {t("margin for weather")}
          </IonLabel>
        </IonCard>
      </IonCardContent>
    </IonCardContent>
  );
}


export const KnifeImageSwitch: React.FC<any> = ({knifeSize}: any) => {
  const [knifeType, setKnifeType] = useState<"Flat"|"Steep">("Flat")
  const {t} = useTranslation()
  return <>
    {(["Flat", "Steep"] as ("Flat"|"Steep")[]).map((kt: "Flat"|"Steep") => <IonRow
        style={{border: knifeType === kt ? "solid" : undefined, cursor: "pointer"}} onClick={() => setKnifeType(kt)}>
      <IonCol style={{height: 100, width: 100}}>
        <ImageView filepath={`/assets/configurator/${knifeSize}${kt}.png`}></ImageView>
      </IonCol>
      <IonCol>
        {kt === "Flat" ? t("Minimal soil movement") : t("Soil movement")}
      </IonCol>

    </IonRow>)}

    <IonItem>
      <IonIcon icon={information} slot="start"/>
      {t("Other knives are available for interrow distance")} {knifeSize === "Small" ? " >= " : " < "} 40 cm
    </IonItem>

  </>
}