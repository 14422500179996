import { IonButton, IonContent, IonPage, IonItem, IonProgressBar, IonChip, IonBadge, IonIcon, IonLabel, IonButtons, IonCard, IonLoading, IonPopover, IonModal, IonToggle, } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import Toolbar from '../components/Toolbar'
import { gql } from '@apollo/client';
import { useQueryWithElevatedRole } from '../hooks/hasuraHooks';
import { Pagination, usePagination } from '../components/Pagination';
import useWindowDimensions from '../useWindowDimensions';
import { chevronDown, chevronUp, close, expand, refresh } from 'ionicons/icons';
import useImage from 'use-image';
import { useFetchJson } from '../components/ShowEvaluation';
import { SearchableSelect } from '../shared-components/SearchableSelect';
const moment = require('moment');

export const ImageView: React.FC<{filepath: string}> = ({filepath}: any) => {
  const {isLargeScreen} = useWindowDimensions()
  const [image] = useImage(filepath)
  return <img src={filepath} style={{maxWidth: isLargeScreen ? "33%" : "100%", height: "auto"}}/>
}

export const VideoView: React.FC<{filepath: string}> = ({filepath}: any) => {
  const {isLargeScreen} = useWindowDimensions()
  return <div style={{maxWidth: isLargeScreen ? "33%": "100%"}}>
    <video controls autoPlay style={{ maxWidth: "100%", objectFit: "scale-down" }}>
      <source src={filepath} type="video/mp4"/>
      Your browser does not support the video tag.
    </video>
  </div>
}

export const Location: React.FC<{filepath: string}> = ({filepath}: any) => {
  const {data, loading} = useFetchJson(filepath)
  if (data == null) return null
  return <a href={"http://maps.google.com/maps?z=12&t=m&q=loc:" + data.latitude + "+" + data.longitude}>location</a>
}


const getType = (filename: string) => {
    if (filename.includes("mipc.jpeg")) return "Tools"
    if (filename.includes("mipc.mp4")) return "Tools video"
    if (filename.includes("reolink")) return "Front / rear cams"
    if (filename.includes("stem_detection")) return "Stem detection"
    if (filename.includes("stiched.jpeg")) return "Stiched image"
    if (filename.includes("stiched.json")) return "Stiched json"
    return "Other"
}

function compareOrientaion(a: string, b: string) {
  enum Orientation {
    Undefind,
    Left,
    Center,
    Right,
  }
  var enum_a: Orientation = Orientation.Undefind
  var emum_b: Orientation = Orientation.Undefind
  for (var i = 0; i < Object.keys(Orientation).length / 2; i++)
  {
    var item: string = Orientation[i]
    if (a.includes(item))
    {
      enum_a =  Orientation[item as keyof typeof Orientation]
    }
    if (b.includes(item))
      emum_b = Orientation[item as keyof typeof Orientation]
  }
  if (enum_a === Orientation.Undefind || emum_b === Orientation.Undefind) {
    return a.localeCompare(b)
  }
  return enum_a - emum_b
}

export const SnapshotContent: React.FC<any> = ({snapshot}: any) => {
  if (!snapshot) return null
  const groupedFilenames = snapshot?.filenames?.reduce((l: any, filename: string) => {
    const type = getType(filename)
    const filePath = filename.includes("snapshot-files") ? filename : `/evaluation-files/snap${snapshot.id}.${filename}`
    l[type] = [...(l[type] || []), filePath]
    return l
  }, {}) || {}
  return <>{Object.keys(groupedFilenames).sort().reverse().map((rowName: string) => <div style={{display: "flex", flexWrap: "wrap", justifyContent: "space-around"}}>
    {groupedFilenames[rowName].sort(compareOrientaion).map((filepath: string) => {
      if (filepath.includes("stiched.jpeg")) {
        return <ImageView filepath={filepath} />
      }
      else if (filepath.includes("jpeg")) {
        return <ImageView filepath={filepath}/>
      }
      else if (filepath.includes("mp4")) {
        return <VideoView filepath={filepath}/>
      }
      else if (filepath.includes("gps.json")) {
        return <Location filepath={filepath}/>
      }
      else {
        return <a href={filepath}>{filepath}</a>
      }
    })}
  </div>)}</>
}


export const SnapshotFilter: React.FC<any> = ({where, setWhere}: any) => {
    const [taurusIds, setTaurusIds] = useState<any>([])
    const [types, setTypes] = useState<any>([])
    const { loading, error, data} = useQueryWithElevatedRole(gql`
      query Tauruses {
          tauruses(order_by: {id: asc}) {id}
          snapshots(distinct_on: type, where: {type: {_is_null: false}}) {type}
      }
    `)
    useEffect(() => {
      const newWhere : any = {is_sim: {_eq: false}}  // Sim hidden by default (only for testing)
      if (taurusIds.length > 0) {
        newWhere.taurus_id = {_in: taurusIds}
      }
      if (types.length > 0) {
        newWhere.type = {_in: types}
      }
      setWhere(newWhere)
    }, [taurusIds, types])

    return <>
      {data && <IonItem color="transparent">
        <IonLabel position="stacked">Robots</IonLabel>
        <SearchableSelect value={taurusIds} onChange={setTaurusIds} options={data.tauruses.map(({id}: any) => [id, `GT${id}`])} />
      </IonItem>}
      {data && <IonItem color="transparent">
        <IonLabel position="stacked">Type</IonLabel>
        <SearchableSelect value={types} onChange={setTypes} options={data.snapshots.map(({type}: any) => [type, type.replaceAll("_", " ")])} />
      </IonItem>}
    </>
}
 

export const SnapshotItem: React.FC<any> = ({snapshot, mustExpand}: any) => {
  const [expanded, setExpanded] = useState(false)
  const {isLargeScreen} = useWindowDimensions()
  const expandable = snapshot.filenames && !mustExpand

  const buttons = <>
    {expandable && <IonButton slot="end" onClick={() => setExpanded(!expanded)} fill="clear">
        <IonIcon icon={expanded ? chevronUp : chevronDown} slot={"icon-only"}/>
    </IonButton>}
  </>

  const types: string[] = ([...new Set(snapshot?.filenames?.map((filename: string) => getType(filename)) || [])] as string[])

  const headerItem = <>
    <IonItem lines={"full"} onClick={() => setExpanded(expandable && !expanded)} style={{cursor: expandable ? "pointer" : null}}>
      {/* the status of the evaluation is shown on the left side */}
      {snapshot.is_sim 
        ? <IonBadge color="medium" slot="start">sim</IonBadge>
        : snapshot.taurus_id && <IonBadge slot="start" color="secondary">GT{snapshot.taurus_id}</IonBadge>}
      <IonBadge color="primary" slot="start">{
         moment(snapshot.created_at).fromNow()
      }</IonBadge>
      {!snapshot.filenames && <IonBadge slot="start">
        Waiting for upload
      </IonBadge>}
      <IonLabel>
        {snapshot.type ? <strong style={{textTransform: "capitalize"}}>{snapshot.type.replaceAll("_", " ")}</strong> : types.join(" - ")}
        {snapshot.filenames?.length < 1 && <IonBadge color="danger">No files</IonBadge>}
      </IonLabel>
      {isLargeScreen && <IonBadge slot="end">#{snapshot.id}</IonBadge>}
      {isLargeScreen && <IonButtons slot="end">{buttons}</IonButtons>}
      {!isLargeScreen && expanded && <IonIcon icon={close} slot="end"/>}
    </IonItem>
  </>
  
  return <>
    {(!isLargeScreen || !expanded) && !mustExpand && headerItem}
    {(mustExpand || expanded) && isLargeScreen && <IonCard style={{margin: "15px"}}>
        {headerItem}
        <SnapshotContent snapshot={snapshot}/>
    </IonCard>}
    <IonModal isOpen={expanded && !isLargeScreen} onDidDismiss={() => setExpanded(false)}>
      {headerItem}
      <IonContent>
        <SnapshotContent snapshot={snapshot}/>
      </IonContent>
    </IonModal>
  </>
}


export const Snapshots: React.FC = () => {
  // An offset is set based on the page number (but only if expandAll is set)
  const pagination = usePagination()
  const [where, setWhere] = useState<any>({})
  const fetchVariables = {where, ...pagination.state}
  // Sort by id (faster than sort by updated_at)
  const { loading, data } = useQueryWithElevatedRole(gql`
    query Snapshots($limit: Int!, $offset: Int!, $where: snapshots_bool_exp!) {
        snapshots (order_by: {created_at: desc}, limit: $limit, offset: $offset, where: $where) {
          id
          created_at
          taurus_id
          is_sim
          filenames
          type
        }
        snapshots_aggregate (where: $where) {aggregate{count}}
    }
  `, {
    variables: fetchVariables,
    pollInterval: 1000,
  });
  const [expandAll, setExpandAll] = useState(false)

  return (
    <IonPage>
      <Toolbar name="Snapshots">
        <div style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
        }}>
          <SnapshotFilter where={where} setWhere={setWhere}/>
          <IonItem color="transparent">
            <IonLabel>Expand all</IonLabel>
            <IonToggle checked={expandAll} onIonChange={(e: any) => setExpandAll(e.detail.checked)}/>
          </IonItem>
        </div>
      </Toolbar>

      <IonContent fullscreen>
        {loading && <IonProgressBar type="indeterminate"/>}
        {data && <>
          <Pagination numItems={data.snapshots?.length} itemType={"snapshots"} {...pagination} totalItems={data?.snapshots_aggregate?.aggregate?.count}/>
          {data.snapshots.length <= 100 && data.snapshots.map((snapshot: any) => <SnapshotItem snapshot={snapshot} key={snapshot.id} mustExpand={expandAll}/>)}
          {data.snapshots.length > 100 && <IonItem>
            Too many snapshots to show.
          </IonItem>}
          <Pagination numItems={data?.snapshots?.length} itemType={"snapshots"} {...pagination} totalItems={data?.snapshots_aggregate?.aggregate?.count}/>
        </>}
      </IonContent>
    </IonPage>
  );
};