import { IonCard, IonItem, IonLabel, IonSelectOption, IonSelect } from '@ionic/react';
import React, {useContext} from 'react';
import { gql, useQuery } from '@apollo/client';
import UserContext from '../userContext'
import ApolloStatus from './ApolloStatus'
import useTeams from '../hooks/useTeams';
import getUsername from './lib/getUsername';


const TeamAuthorization: React.FC<any> = ({setRoleAndTeam, roleAndTeam, name, teamRelations}) => {
    const myId = useContext(UserContext);
    const { loading, error, data } = useQuery(gql`query teams ($username :String){
        user: users_permissions_user(where: {username: {_eq: $username}}) {
          teams {
            id
            role
            team {
              id
              name
            }
          }
        }
      }`, {"variables": {"username": getUsername()}, "context": {"headers": {"X-Hasura-Role": "admin"}}});
    const {teamRelations: allTeamRelations} = useTeams()

    const teamIds = teamRelations.map((t: any) => t?.team?.id)
    const availableTeams = allTeamRelations?.filter((t: any) => teamIds.includes(t?.team?.id)) || []

    const setTeam = (team: any) => {
        const teamObjectRelation = teamRelations.find((t: any) => t?.team?.id === team?.team?.id)
        let role = teamObjectRelation?.role || "viewer"
        if (role === "owner" && team.role !== "owner") {
            role = "editor"
        }
        if (role === "editor" && !["owner", "editor"].includes(team.role)) {
            role = "viewer"
        }
        setRoleAndTeam({role: role, teamObjectRelation: teamObjectRelation, teamUserRelation: team})
    }

    // Set the first team if no team is set yet
    if (availableTeams.length > 0 && roleAndTeam.teamObjectRelation === null) {
        // Do not set directly since components needs to render first
        setTimeout(() => setTeam(availableTeams[0]), 0)
    }
    return <IonCard style={{border: "solid var(--ion-color-primary)"}}>
        <ApolloStatus loadings={[loading]} errors={error}/>
        <IonItem>
            <IonLabel>Edit as</IonLabel>
            <IonSelect onIonChange={(e: any) => {setTeam(e.detail.value)}} value={roleAndTeam.teamUserRelation}>
                {availableTeams.map((t: any) => <IonSelectOption value={t} key={t.id}>{t.team.name}</IonSelectOption>)}
            </IonSelect>
        </IonItem>
        {roleAndTeam.role && <IonItem>
            <strong style={{textTransform: "capitalize"}}>{roleAndTeam?.role}</strong>:<pre> </pre>
            {roleAndTeam?.teamUserRelation?.role === "viewer" && ` you are viewer of ${roleAndTeam.teamUserRelation.team.name}`}
            {roleAndTeam?.teamUserRelation?.role === "editor" && ` you are editor of ${roleAndTeam.teamUserRelation.team.name}`}
            {roleAndTeam?.teamUserRelation?.role === "owner" && ` you are owner of ${roleAndTeam.teamUserRelation.team.name}`}

            {roleAndTeam?.teamObjectRelation?.role === "viewer" && ` which is viewer of ${name}`}
            {roleAndTeam?.teamObjectRelation?.role === "editor" && ` which is editor of ${name}`}
            {roleAndTeam?.teamObjectRelation?.role === "owner" && ` which is owner of ${name}`}
        </IonItem>}
    </IonCard>
}
export default TeamAuthorization;