import { IonModal, IonContent, IonButton, IonToolbar, IonTitle } from '@ionic/react';
import React, {useMemo} from 'react';
import { gql, } from '@apollo/client';
import EditableCard from './EditableCard'
import Input, {yup, useYupValidationResolver} from './Input'
import { useForm } from "react-hook-form";
import { goBackOrGoto } from '../history'
import ApolloStatus from '../components/ApolloStatus'
import XavierCard from '../components/XavierCard'
import { useMutationWithElevatedRole, useQueryWithElevatedRole } from '../hooks/hasuraHooks';


const AquilaForm: React.FC<any> = ({aquila, onFinish, refetchParent}) => {
  const { control, errors, reset, handleSubmit, formState } = useForm({
      resolver: useYupValidationResolver(useMemo(() => yup.object({
          name: yup.string().required("Required"),
          cameraType: yup.string().required("Required").oneOf(["jai1", "jai2"]),
      }), [])),
      defaultValues: aquila
  })

  const { refetch, loading, error: fetchError, data } = useQueryWithElevatedRole(gql`query{
      xaviers {id hostname description aquila {name} }
  }`);

  const [updateAquila, updateAquilaApollo] = useMutationWithElevatedRole(gql`mutation
      update($name: String, $description: String, $xavier: ID, $jaiMacNir: String, , $jaiMacRgb: String, $cameraType: ENUM_AQUILA_CAMERATYPE) {
          updateAquila(
              input: {where: {id: ${aquila.id}},
              data: {name: $name, description: $description, xavier: $xavier, jaiMacRgb: $jaiMacRgb, jaiMacNir: $jaiMacNir, cameraType: $cameraType}}
          ) {aquila {id}}
  }`)

  const [createXavier, {error: createXavierError}] = useMutationWithElevatedRole(gql`mutation
      createXavier {
          createXavier(
              input: {data: {aquila: ${aquila.id}}},
          ) {xavier {id}}
  }`)

  const [deleteAquila, deleteAquilaApollo] = useMutationWithElevatedRole(gql`mutation {
      deleteAquila(input: {where: {id: ${aquila.id}}}) {aquila {id}}
  }`)
  // Catch errors on submits (errors are handled via updateAquilaApollo)
  const onSubmit = handleSubmit((data: any) => {updateAquila({variables: data}).then(() => {onFinish()}).catch((e: any) => null)})


  return <IonModal onDidDismiss={() => onFinish()} isOpen={true}>
        <IonToolbar color="primary"><IonTitle>Edit camera</IonTitle>
            {!formState.isDirty && <IonButton color="light" slot="end" fill="clear" onClick={() => onFinish()}>cancel</IonButton>}
            {formState.isDirty && <IonButton slot="end" fill="clear" color="light" onClick={() => reset()}>Undo</IonButton>}
            {formState.isDirty && <IonButton slot="end" color="secondary" onClick={() => onSubmit()}>Save changes</IonButton>}
        </IonToolbar>
        <IonContent>
        <ApolloStatus loadings={[loading, updateAquilaApollo.loading, deleteAquilaApollo.loading]} errors={[fetchError, createXavierError, updateAquilaApollo.error, deleteAquilaApollo.error]}/>
        <form onSubmit={onSubmit}>
            <Input control={control} errors={errors} name="name"/>
            <Input control={control} errors={errors} name="description" component={EditableCard} showLabel={false}/>
            <Input control={control} errors={errors} name="jaiMacNir"/>
            <Input control={control} errors={errors} name="jaiMacRgb"/>
            <Input control={control} errors={errors} name="cameraType"/>
            <input type="submit" style={{visibility: "hidden"}}/>
        </form>

        {data && <XavierCard updateComponent={(data: any) => updateAquila(data).then(() => {refetchParent(); refetch()}).catch((e: any) =>null)}
                    createXavier={() => createXavier().then(() => refetchParent()).catch((e: any) => null)}
                    availableXaviers={data.xaviers} xavier={aquila.xavier}
                    onFinish={onFinish}/>}
        </IonContent>
        <IonButton onClick={() => {deleteAquila().then(() => {onFinish(); goBackOrGoto("/aquilas")}).catch((e: any) => null)}} color="danger" expand="full">delete aquila</IonButton>
    </IonModal>
}

export default AquilaForm;
