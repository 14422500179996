import { IonList, IonButton, IonContent, IonItem, IonLabel, IonBadge, IonIcon, IonPopover, IonCard, IonCardHeader, IonCardTitle, IonCheckbox, IonButtons, IonSkeletonText, IonChip, IonProgressBar, IonToolbar, IonInput, IonSpinner } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { gql } from '@apollo/client';
import { close, thumbsDown, thumbsUp, } from 'ionicons/icons';
import { useMutationWithElevatedRole, } from '../hooks/hasuraHooks';
import getUsername from './lib/getUsername';
import compareStems from './lib/compareStems';
import { Stem } from './Stem';
import { useExtendedResultData } from './ShowEvaluation';
import { fixEvaluationsRegardingGroundTruth } from './EvaluationItem';


export const MatchedStem: React.FC<any> = ({matchedStem, showBasicInfo, setInfo, maxDistance, ...props}: any) => {
  const {stem, groundtruthStem, distance} = matchedStem
  props.onMouseOver = () => setInfo(`Match (distance: ${(distance * 1000).toFixed(2)} mm)`)
  props.onMouseOut = showBasicInfo
  return <>
    <Stem stem={stem} radius={0.02  /* same as fused prediction */} {...props} stroke="black"/>
    <Stem stem={groundtruthStem} radius={maxDistance} stroke="darkgreen" strokeWidth={0.02} {...props}/>
  </>
}


export const useScoreEvaluation = () => {
  const [updateEvaluation] = useUpdateEvaluation()
  const [errors, setErrors] = useState<any>({})
  const [maxDistance, setMaxDistance] = useState(0.04)
  const [scoreData, setScoreData] = useState<any>(null)
  const scoreEvaluation = (evaluation: any, resultData: any) => {
    const stems = resultData?.stems?.fused_stems
    const groundTruth = evaluation?.bag?.ground_truths?.at(0)
    const groundTruthStems = groundTruth?.data?.fused_stems
    const {error, matchedStems, falsePositives, falseNegatives} = compareStems({stems, groundTruthStems, maxDistance})
    if (error) {
      setErrors((prev: any) => ({...prev, [`${evaluation.id}`]: error}))
      console.error(error)
    }
    else {
      if (errors) {
        setErrors((prev: any) => {
          if (prev[`${evaluation.id}`]) {
            delete prev[`${evaluation.id}`]
          }
          return prev
        })
      }
      const nTP = matchedStems?.length || 0
      const nFP = falsePositives?.length || 0
      const nFN = falseNegatives?.length || 0
      const recall = nTP / (nTP + nFN)
      const precision = nTP / (nTP + nFP)
      const _set = {
        score_ground_truth_id: groundTruth.id,
        score_author_name: null,  // the 'author' is the ground truth
        score_details: {matchedStems, falsePositives, falseNegatives},
        score_data: {
          nTP, nFP, nFN, recall, precision, maxDistance
        },
        score_passed: recall == 1 && precision == 1,
      }
      updateEvaluation({variables: {
        id: evaluation.id,
        _set
      }})
      setScoreData(_set)
    }
  }
  return {
    scoreEvaluation,
    scoreData,
    errors,
    maxDistance,
    setMaxDistance,
    resetErrors: () => setErrors({})
  }
}


export const ScoreErrors: React.FC<any> = ({errors, resetErrors}) => {
  const [updateEvaluation] = useUpdateEvaluation()
  if (!errors || Object.keys(errors).length === 0) return null
  return <IonCard>
    {Object.keys(errors)?.length > 0 && <IonButton expand="full" onClick={() => Object.keys(errors).map((key: string) => {
      updateEvaluation({variables: {id: parseInt(key), _set: {result_error: errors[key]}}})
      resetErrors()
    })}>Mark all {Object.keys(errors)?.length} evaluations with error in scoring as failed</IonButton>}
    {Object.keys(errors)?.map((key: string) => <IonItem key={key} color="danger">
      <IonBadge>{key}</IonBadge>{errors[key]}
    </IonItem>)}
  </IonCard>
}


export const useUpdateEvaluation = () => useMutationWithElevatedRole(gql`
  mutation UpdateEvaluation($id: Int!, $_set: evaluations_set_input!) {
    update_evaluations_by_pk(pk_columns: {id: $id}, _set: $_set) {
      id
    }
  }
`)


export const EvaluationScore: React.FC<any> = ({evaluation, ...props}: any) => {
  const [updateEvaluation] = useUpdateEvaluation()
  const [event, setEvent] = useState<any>(null)
  const username = getUsername()  // Saved in updates
  const style = {cursor: "pointer"}
  const setPassed = (newPassed: boolean) => updateEvaluation({variables: {id: evaluation.id, _set: {score_passed: newPassed, score_author_name: username, score_data: null, score_details: null}}})
  if (evaluation.score_passed === null) {
    return <>
      {[true, false].map((newPassed: boolean) => <IonBadge key={String(newPassed)} color={"medium"} onClick={() => setPassed(newPassed)} style={style}>
        <IonIcon icon={newPassed ? thumbsUp: thumbsDown}/>
      </IonBadge>)}
    </>
  }
  const passed = evaluation.score_passed === true
  return <>
    <IonPopover event={event} isOpen={!!event} onDidDismiss={() => setEvent(null)}>
      <IonContent>
        <IonCardHeader>
          {evaluation.score_passed ? "Passed" : "Failed"}
        </IonCardHeader>
        {evaluation.score_author_name && <IonItem>
          {evaluation.score_author_name}
        </IonItem>}

        {evaluation.score_data && <pre>
          {JSON.stringify(evaluation.score_data, null, 2)}
        </pre>}
        <IonButton onClick={() => setPassed(!evaluation.score_passed)}
          expand="block" color={evaluation.score_passed ? "danger" : "success"} fill="clear"
        >
          <IonIcon icon={evaluation.score_passed ? thumbsDown : thumbsUp} slot="start"/>
          Set to {evaluation.score_passed ? "failed" : "passed"}
        </IonButton>

        <ScoreButton evaluations={[evaluation]} expand="block" fill="clear"/>
      </IonContent>
    </IonPopover>
    <IonBadge color={passed ? "success": "danger"} onClick={setEvent} style={style} {...props}>
      <IonIcon icon={passed ? thumbsUp: thumbsDown}/>
      {evaluation?.score_data?.recall !== undefined && <>
        {" "} {(evaluation?.score_data?.recall * 100).toFixed(0)}%
      </>}
      {/* Show precision after recall if recall is 100% but not precision.
        This makes the "red" failed color on the badge more understandable (failed because of precision, not recall) */
      }
      {evaluation?.score_data?.precision < 1.0 && <>
        {" "} | {(evaluation?.score_data?.precision * 100).toFixed(0)}%
      </>}
    </IonBadge>
  </>
}


export const ScoreVisualization: React.FC<any> = ({evaluation, scale, layerRotation, setInfo}: any) => {
  const {error, matchedStems, falsePositives, falseNegatives} = evaluation?.score_details || {}
  const {nFP, nTP, nFN, maxDistance} = evaluation?.score_data || {}

  const showBasicInfo = () => {
    setInfo(`False positives: ${nFP}, false negatives: ${nFN}, total groundtruth: ${nTP + nFN}`)
  }

  useEffect(() => {
    if (error) {
      setInfo(error)
    }
    else {
      // Show basic info
      showBasicInfo()
    }
  }, [error])

  if (!evaluation.score_details || !evaluation.score_data) {return null}

  if (error) {
    return null
  }

 return <>
    {matchedStems?.map((matchedStem: any, i: number) => <MatchedStem matchedStem={matchedStem} key={i} opacity={0.5}
      scale={scale} layerRotation={layerRotation} maxDistance={maxDistance} setInfo={setInfo} showBasicInfo={showBasicInfo}
    />)}

    {falseNegatives?.map(
      (s: number[], i: number) => <Stem scale={scale} stem={s} layerRotation={layerRotation} radius={maxDistance} strokeWidth={0.02} stroke={"red"} setInfo={() => setInfo("False negative")} key={`fused_${i}`} />)}

    {falsePositives?.map(
      (s: number[], i: number) => <Stem scale={scale} stem={s} layerRotation={layerRotation} radius={0.02} stroke={"red"} setInfo={() => setInfo("False positive")} key={`fused_${i}`} />)}
  </>
}


export const ScoreButton: React.FC<any> = ({evaluations, children, ...props}: any) => {
  const [event, setEvent] = useState<any>(null)
  const [scoringEvaluations, setScoringEvaluations] = useState<any[]>([])
  const scoringEvaluationIds: Set<number> = new Set<number>(scoringEvaluations.map(({id}: any) => id))
  useEffect(() => {
    setScoringEvaluations([])
  }, [event])
  const evaluationsWithCorrectGroundTruth = fixEvaluationsRegardingGroundTruth(evaluations)
  // With groundtruth and result data and no error
  const scorableEvaluations = evaluationsWithCorrectGroundTruth?.filter((e: any) => e.bag.ground_truths?.length && e.result_data && !e.result_error && !scoringEvaluationIds.has(e.id))

  // Which have not been scored with latest groundtruth
  const unscoredEvaluations = scorableEvaluations?.filter((e: any) => e.bag.ground_truths[0]?.id !== e.score_ground_truth_id)

  if (scorableEvaluations.length < 1 && scoringEvaluations.length < 1) {
    // Those evaluations cannot be scored
    return null
  }

  return <>
    <IonButton onClick={setEvent} {...props}>{children || "score"}</IonButton>
    <IonPopover event={event} isOpen={!!event} class="wide-popover" onDidDismiss={() => setEvent(null)}>
      <IonList style={{overflowY: "auto", maxHeight: "400px"}}>
        <IonButton fill="clear" onClick={() => setEvent(null)} expand="block">
          <IonIcon icon={close}/>
        </IonButton>
        {unscoredEvaluations.length > 0 && <IonButton expand="block" onClick={() => setScoringEvaluations(unscoredEvaluations)}>
          Score {unscoredEvaluations.length} unscored evaluations
        </IonButton>}
        {scorableEvaluations.length > 0 && <IonButton expand="block" onClick={() => setScoringEvaluations(scorableEvaluations)}>
          Re-score {scorableEvaluations.length} evaluations</IonButton>}
        {scoringEvaluations.map((evaluation: any) => <ScoreSingleEvaluation evaluation={evaluation} key={evaluation.id}/>)}
      </IonList>
    </IonPopover>
  </>
}


export const ScoreSingleEvaluation: React.FC<any> = ({evaluation}: any) => {
  const resultData = useExtendedResultData(evaluation)
  const {errors, scoreData, scoreEvaluation} = useScoreEvaluation()
  const evalWithUpdatedScore = {...evaluation, ...scoreData}
  useEffect(() => {
    scoreEvaluation(evaluation, resultData)
  }, [resultData])
  return <IonItem>
    <IonBadge>
      {evaluation.id}
    </IonBadge>
    {errors[evaluation.id] && <IonBadge color="danger" slot="end">{errors[evaluation.id]}</IonBadge>}
    {!resultData?.stems && <IonSpinner/>}
    <EvaluationScore evaluation={evalWithUpdatedScore} slot="end"/>
  </IonItem>
}