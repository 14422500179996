import { IonItem, IonContent, IonPage, IonCardTitle, IonButton, IonIcon, IonModal, IonToolbar, IonTitle, IonList, IonItemDivider, } from '@ionic/react';
import React, { useState } from 'react';
import { useParams } from 'react-router';
import { gql, } from '@apollo/client';

import { useQueryWithElevatedRole } from '../hooks/hasuraHooks';
import { useGetChecklistActions } from '../hooks/useGetChecklistActions';
import { ChecklistForComponent } from './Checklist';
import { shortenComponentType } from './Processes';
import { useTranslation } from 'react-i18next';
import { close, list } from 'ionicons/icons';
import { ProcessGroupSelect } from './ProcessGroups';
const moment = require('moment-twitter');



export const RobotConfigChecklistPage: React.FC = () => {
  const { robotConfigIdAsString } = useParams<{ robotConfigIdAsString: string }>();
  const robotConfigId = parseInt(robotConfigIdAsString)
  return <IonPage>
    <RobotConfigChecklist robotConfigId={robotConfigId}/>
  </IonPage>
}


export const RobotConfigChecklistModal: React.FC<any> = ({robotConfigId, children, ...props}) => {
  const [show, setShow] = useState(false)
  const {t} = useTranslation()

  return <>
    <IonButton onClick={() => setShow(true)} {...props}>
      {children}
    </IonButton>

    <IonModal isOpen={show} onDidDismiss={() => setShow(false)} className="wide-popover">
      <IonButton expand="block" fill="clear" onClick={() => setShow(false)}>
        <IonIcon icon={close}/>
      </IonButton>
      <RobotConfigChecklist robotConfigId={robotConfigId}/>
    </IonModal>
  </>
}


export const RobotConfigChecklist: React.FC<any> = ({robotConfigId}) => {
  let { data } = useQueryWithElevatedRole(gql`
    query RobotConfigChecklist($id : Int!) {
      robot_configs_by_pk(id: $id) {
        id
        aquila_configs {
          aquila_id
        }
        talpa_configs(order_by: {offset_y: asc}) {
          talpa_id
        }
        taurus_configs {
          taurus_id
        }
        generator {
          id
          serial_number
        }
      }
    }`,
    {notifyOnNetworkStatusChange: true, "variables" : {"id": robotConfigId}}
  );
  const robotConfig = data?.robot_configs_by_pk
  return <>{robotConfig && <RobotConfigChecklistContent robotConfig={robotConfig}/>}</>
}


export const RobotConfigChecklistContent: React.FC<any> = ({robotConfig}) => {
    const [processGroups, setProcessGroups] = useState([2, 3, 6, 8])  // Most common groups
    const aquilaIds = robotConfig.aquila_configs.map(({aquila_id}: any) => aquila_id)
    const talpaIds = robotConfig.talpa_configs.map(({talpa_id}: any) => talpa_id)
    const taurusIds = robotConfig.taurus_configs.map(({taurus_id}: any) => taurus_id)
    const getChecklistActions = useGetChecklistActions()
    return <>
      <IonToolbar>
        <IonItem>
          <ProcessGroupSelect multiple={true}
            onIonChange={({detail: {value}}: any) => setProcessGroups(value)} value={processGroups}
          />
        </IonItem>
      </IonToolbar>

      <IonContent>
        <ComponentChecklists componentIds={taurusIds} componentType={"taurus"} getChecklistActions={getChecklistActions} processGroups={processGroups}/>
        <ComponentChecklists componentIds={aquilaIds} componentType={"aquila"} getChecklistActions={getChecklistActions} processGroups={processGroups}/>
        <ComponentChecklists componentIds={talpaIds} componentType={"talpa"} getChecklistActions={getChecklistActions} processGroups={processGroups}/>
        {robotConfig.generator && <ComponentChecklists componentIds={[robotConfig.generator.id]} componentType={"generator"}
          getChecklistActions={getChecklistActions} processGroups={processGroups} title={`Generator ${robotConfig.generator.serial_number}`} />}
      </IonContent>
    </>
}


export const ComponentChecklists: React.FC<any> = ({componentIds, componentType, getChecklistActions, processGroups, title}) => {
  const componentTypeShort = shortenComponentType(componentType)
  return componentIds.map((componentId: number) => <ChecklistForComponent componentType={componentType} componentId={componentId} allowAddingItem={false}
    getChecklistActions={getChecklistActions} showTable={false} processGroups={processGroups}
    prefix={<strong>{title || `${componentTypeShort} ${componentId}`}: </strong>}
  />)
}