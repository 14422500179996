import { IonPage, IonContent, } from '@ionic/react';
import React, { } from 'react';
import { useDateRange } from '../components/DateRangePicker';
import { Timeline, useTimelineOptions } from './Timeline';
import { useParams } from 'react-router';


export const ChatGTPage: React.FC<any> = ({}) => {
  const {createdWhere} = useDateRange()
  const { taurusId } = useParams<any>()
  const timelineOptions = useTimelineOptions({showMessages: true, taurusId: parseInt(taurusId)})
  return <IonPage>
    <IonContent>
      <Timeline createdWhere={createdWhere} {...timelineOptions} taurusId={timelineOptions.taurusId} />
    </IonContent>
  </IonPage>
}