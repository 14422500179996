import { IonButton, IonItem, IonLabel, IonCard, IonCardHeader, IonCardTitle, IonCardContent } from '@ionic/react';
import React from 'react';
import history from '../history'


const XavierItem: React.FC<any> = ({xavier, ...props}) => {
    return <IonItem {...props}>
        {xavier
            ? <IonButton fill="clear" onClick={() => history.push(`/xavier/${xavier.id}`)}>xavier:{xavier.hostname || "unnamed xavier"}</IonButton>
            : "No xavier associated"}
    </IonItem>}

export default XavierItem;
