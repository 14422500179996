import { IonCard, IonLoading, IonButton, IonCardHeader, IonCardTitle, IonCardContent } from '@ionic/react';
import React, {useState, useCallback, useMemo} from 'react';
import Input, { useYupValidationResolver} from '../components/Input'
import * as yup from "yup";
import { useForm } from "react-hook-form";


const LoginCard: React.FC<any> = ({login}) => {

  const validationSchema = useMemo(() =>
      yup.object({
        username: yup.string().required("Required"),
        password: yup.string().required("Required")
      }),
    []
  );
  const resolver = useYupValidationResolver(validationSchema);
  const { control, errors, handleSubmit } = useForm({resolver: resolver, defaultValues: {username: "", password: ""}})

  return <IonCard>
        <IonCardHeader>Log in</IonCardHeader>
        <IonCardContent>
            <form onSubmit={handleSubmit((data: any) => login({variables: data}))}>
                <Input control={control} errors={errors} name="username" label="email"/>
                <Input control={control} errors={errors} name="password" props={{type:"password"}}/>
                <IonButton type="submit" expand="full">Login</IonButton>
                <input type="submit" style={{visibility: "hidden"}}/>
            </form>
        </IonCardContent>
    </IonCard>
}

export default LoginCard;
