import React from 'react';
import ErrorCard from '../components/ErrorCard'

const Error: React.FC<any> = ({error, errors}) => {
  if (errors === undefined) {
      errors = []
  }
  errors.push(error)
  const errorDicts = errors.reduce((accumulator: any[], currentValue: any) => {
      if (currentValue === undefined) {
          return accumulator
      }
      const tmp = JSON.parse(JSON.stringify(currentValue.graphQLErrors))
      if (currentValue.networkError) {
          tmp.push(currentValue.networkError)
      }
      tmp.forEach((a: any) => a.extraInfo = currentValue.extraInfo)
      return accumulator.concat(tmp)
  }, [])
  if (errorDicts.length < 1) return <></>
  return <React.Fragment>
    {errorDicts.map((e: any, index: number) => <ErrorCard key={`${index}`} error={e}/>)}
  </React.Fragment>
};

export default Error;
