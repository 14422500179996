import { IonProgressBar, IonTextarea } from '@ionic/react';
import React, {useEffect, useState} from 'react';
import {Stage, Layer, Arrow, Text, Circle} from 'react-konva';
import { useKonvaStageProps } from '../hooks/useKonvaStageProps';
import { rainbowColor } from '../rainbowColor';
import { OriginFrame } from './Map';
import { useFetchJson } from './ShowEvaluation';
import { SidePanel } from './SidePanel';


export const FusedStem: React.FC<any> = ({x, y, row_number, scale, setInfo, radius=0.02, ...props}: any) => {
  if (!x || !y || !row_number) {return null}
  const color = rainbowColor(row_number, 10)
  const info = `Row ${row_number} (${x} ${y})`
  return <Circle onMouseOver={() => setInfo(info)} onMouseOut={() => setInfo(null)} x={x} y={y}
    strokeWidth={radius / ((scale < 1200) ? 2. : 8.)} radius={radius} fill={color} {...props}/>
}


export const TrackFinder: React.FC<any> = ({evaluation}: any) => {
  const {data, loading} = useFetchJson(`/evaluation-files/ev${evaluation["id"]}.track_finder.json`)
  return <>
    {loading && <IonProgressBar type="indeterminate"/>}
    {data && <FusedStemMap initialData={data}/>}
  </>
}


export const FusedStemMap: React.FC<any> = ({initialData}: any) => {
  const [data, setData] = useState(initialData)
  const [info, setInfo] = useState<any>(null)
  const [scale, stageProps, center] = useKonvaStageProps({}) /* when user moves / change map: deactivate follow mode */

  const dataJson = JSON.stringify(data, null, 2)
  const setDataJson = (text: string) => {
    try {
      setData(JSON.parse(text))
    }
    catch {
      console.error("JSON parse error")
    }
  }

  useEffect(() => {
    if (data?.stems?.length > 0) {
        const firstStem = data.stems[0]
        if (firstStem.x && firstStem.y) {
          center(80, firstStem.x, firstStem.y)
        }
    }
  }, [data])

  return <>
    <SidePanel info={info}>
      <IonTextarea rows={30} value={dataJson} onIonChange={(e: any) => setDataJson(e.detail.value)}/>
    </SidePanel>
    <Stage style={{ height: stageProps.height+"px"}} {...stageProps}>
        <Layer scaleY={-1 /* important: y goes down in konva */}>
            <OriginFrame/>
            {data?.stems?.map((stem: any, i: number) => <FusedStem
                {...stem} setInfo={setInfo} key={i}
            />)}
            {data?.track?.map((waypoint: any, i: number) => <Circle {...waypoint} radius={0.04} stroke="black" strokeWidth={0.01}/>)}
        </Layer>
    </Stage>
  </>
}