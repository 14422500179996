import React from 'react';
import { IonRouterOutlet } from '@ionic/react';
import { Redirect, Route } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';

import Event from '../pages/Event'
import Robot from '../pages/Robot'
import Aquila from '../pages/Aquila'
import EventType from '../pages/EventType'
import AboutMe from '../pages/AboutMe'
import Field from '../pages/Field'
import User from '../pages/User'
import Xavier from '../pages/Xavier'
import { pages, defaultRoute } from '../pages'
import Evaluation from '../pages/Evaluation';
import Stats from '../pages/Stats';
import getRoles from '../shared-components/utils/getRoles';
import { RobotConfigChecklistPage } from '../pages/RobotConfigChecklist';
import { ChatGTPage } from '../pages/ChatGT';


const RouterOutlet: React.FC<any> = () => {
    let fallback = defaultRoute;
    const roles = getRoles();
    const { data } = useQuery(gql("query {teams{id}}"));
    if (roles.has("annotator")) {
        fallback = "/evaluations"
    } else if (data?.teams.length > 0) {
        fallback = "/fleets"
    } else {
        fallback = "/about-me"
    }
    return <IonRouterOutlet id="main">
        {pages.map((page, index) => {
            return (<Route key={index} path={page.route || ("/" + page.name)} component={page.component} exact />)})}
        <Route path="/event/:id" component={Event}/>
        <Route path="/chat-gt/:taurusId" component={ChatGTPage}/>
        <Route path="/robot/:id/:name?" render={props=><Robot key={props.match.params.id} />} />
        <Route path="/robot-checklist/:robotConfigId" component={RobotConfigChecklistPage}/>
        <Route path="/camera/:id/:name?" component={Aquila}/>
        <Route path="/event-type/:id/:name?" component={EventType}/>
        <Route path="/about-me" component={AboutMe}/>
        <Route path="/field/:id/:name?" component={Field}/>
        <Route path="/user/:id/:name?" component={User}/>
        <Route path="/xavier/:id/:name?" component={Xavier}/>
        <Route path="/evaluation/:id" component={Evaluation}/>
        <Route path="/stats" component={Stats}/>
        <Redirect exact from="/" to={fallback} />
    </IonRouterOutlet>
};

export default RouterOutlet;
