import { IonList, IonItem, IonLabel, IonSkeletonText, IonCol, IonModal, IonContent, IonRow, IonButton } from '@ionic/react';
import React, { useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import Error from '../components/Error'
import EventTimeline from './EventTimeline'
import Refresher from './Refresher'
import DecoratedText from '../shared-components/DecoratedText';
import useWindowDimensions from '../useWindowDimensions';
import { useQueryWithElevatedRole } from '../hooks/hasuraHooks';

const EventList: React.FC<any> = ({where, show}) => {
  const {isLargeScreen} = useWindowDimensions()
  let where_hasura : Object = {};
  for (let key in where) {
    if (!where.hasOwnProperty(key)) {
      continue
    }
    if (where[key].length == 0) {
      continue
    }
    (where_hasura as any)[key + "_id"] = {"_in": where[key]}
  }
  const [event_popup_data, set_event_popup_data] = useState<string>("null");
  
  const { loading, error, data, refetch } = useQueryWithElevatedRole(
      gql`query events($where: events_bool_exp){
        events(where:$where, order_by:{id:desc}, limit: 20){
          id created_at
          details_string
          details_data
          component
          type {
            name
          }
          created_at
        } 
      }`,
      {
        variables: {where: where_hasura},
        fetchPolicy: "no-cache",
      }
  )
  if (loading) return (<IonList>{(new Array(8)).fill(0).map((n, i) => <IonItem key={i}><IonLabel><IonSkeletonText animated style={{ width: '60%' }}/></IonLabel></IonItem>)}</IonList>)
  if (error) return <Error error={error}/>

  return <>
        {false && <Refresher refetch={refetch}/>}
        {false && data?.events && <EventTimeline events={data?.events} show={show}/>}
        {data?.events?.map((e: any, index : number)=>{ return <IonItem style={{'cursor': 'pointer'}} key={index} onClick={()=>set_event_popup_data(JSON.stringify(e, null, 2))}>
          <IonCol size={"4"} style={{fontSize: "small"}}>{new Date(Date.parse(e.created_at)).toLocaleString()}</IonCol>
          <IonCol>{e?.details_data?.diagnostics?.find((v: any) => v?.name === e?.details_data?.component)?.message}</IonCol>
          {isLargeScreen && <IonCol><DecoratedText text={e?.details_data?.component}/></IonCol>}
        </IonItem>})}
    </>
}

export default EventList;
