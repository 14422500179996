import {IonCard, IonRow, IonCol, IonCardSubtitle, IonModal, IonToolbar, IonCardContent, IonCardHeader, IonToast, IonButton, IonContent, IonTitle, IonLabel, IonPage, IonList, IonItem, useIonViewDidEnter } from '@ionic/react';
import React, {useEffect, useState} from 'react';
import { useMutation, gql } from '@apollo/client';
import EventsWithFilter from '../components/EventsWithFilter'
import EventTimeline from '../components/EventTimeline'
import ExpandButton from '../components/ExpandButton'
import history from '../history'
import getMaxRole from './lib/getMaxRole';
import { useMutationWithElevatedRole } from '../hooks/hasuraHooks';
import AddButton from './AddButton';

const EventsCard: React.FC<any> = ({events, ...props}) => {
    const [showModal, setShowModal] = useState(false)

    const [createEvent] = useMutationWithElevatedRole(gql`mutation createEvent($date: DateTime){
          createEvent(input: {data: {start: $date}}) {event {id}}
    }`, {
        context: {
            headers: {
              "x-hasura-role": getMaxRole()
            }
          }
    })

    return <>
        <IonModal class="fullscreen" showBackdrop={false} onDidDismiss={() => setShowModal(false)} isOpen={showModal}>
            <IonToolbar color="primary"><IonTitle>Activity</IonTitle>
                <IonButton color="light" slot="end" fill="clear" onClick={() => setShowModal(false)}>close</IonButton>
            </IonToolbar>
            <IonContent>
                <EventsWithFilter showRefresher={false} {...props}/>
            </IonContent>
        </IonModal>
        <IonCard>
             <IonCardHeader><IonCardSubtitle>Activity</IonCardSubtitle></IonCardHeader>
             <IonCardContent>
                <EventTimeline events={events} show={props.show}/>
                <IonRow>
                    <IonCol>
                        <AddButton onClick={() => createEvent({variables: {date: new Date()}}).then((data: any) => history.push(`/event/${data?.data?.createEvent?.event?.id}`))}/>
                    </IonCol>
                    <IonCol>
                        <ExpandButton onClick={() => setShowModal(true)}/>
                    </IonCol>
                </IonRow>
             </IonCardContent>
        </IonCard>
    </>
};

export default EventsCard;
