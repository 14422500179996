import { gql, useMutation, useQuery } from '@apollo/client'
import {useContext} from 'react'
import UserContext from '../userContext'


const useTeams = () => {
  const myId = useContext(UserContext);
  const { refetch, loading, error, data, updateQuery } = useQuery(gql`query($my_id :String) {
    userTeams: users_m2m_teams(where: {user: {name: {_eq: $my_id}}}) {
      id
      role
      team {
        id
        name
        description
        users {
          id
          role
          user {
            id
            name
          }
        }
      }
    }
  }`, {
    notifyOnNetworkStatusChange: true, // This reloads the component and sets loading to true on refetch
    variables: {"my_id": myId}
  });

  const [createTeam, createTeamApollo] = useMutation(gql`mutation createTeam($users: [ID], $name: String){
      createTeam(input: {data: {users: $users, name: $name}}) {team {id name users {id}}}
  }`)
  const [createTeamUserRelation, createTeamUserRelationApollo] = useMutation(gql`
  mutation createTeamUserRelation($user: ID!, $team: ID, $role: ENUM_TEAMUSERRELATION_ROLE){
      createTeamUserRelation(input: {data: {user: $user, team: $team, role: $role}}) {
        teamUserRelation {id role user {id username}}
      }
  }`)
  const [deleteTeamUserRelation, deleteTeamUserRelationApollo] = useMutation(gql`mutation deleteTeamUserRelation($id: ID!){
      deleteTeamUserRelation(input: {where: {id: $id}}) {teamUserRelation {id}}
  }`)
  const [updateTeam, updateTeamApollo] = useMutation(gql`mutation updateTeam ($id: Int!, $name: String!, $description: String) {
    update_teams_by_pk(
        pk_columns: {id: $id},
        _set: {name: $name, description: $description}
    ) {id name description}
  }`)
  const [updateTeamUserRelation, updateTeamUserRelationApollo] = useMutation(gql`mutation
    updateTeamUserRelation($id: Int!, $role: String!) {
        update_users_m2m_teams_by_pk(
            pk_columns :{id: $id},
            _set: {role: $role}
        ) {id role user {id name}}
    }`)

  const createTeamClean = () => {
    createTeamUserRelation({variables: {user: myId, role: "owner"}})
      .then((d: any) => createTeam({variables: {users: [d.data.createTeamUserRelation.teamUserRelation.id], name: `New team`}}))
      .then((data: any) => updateQuery((prev: any) => ({userTeams: [...prev.userTeams, data?.data?.createTeam?.team]})))
      .catch((e: any) => console.error(e))
  }
  const addUserToTeam = (user: any, team: any) => {
    createTeamUserRelation({variables: {user: user.id, team: team?.id, role: "viewer"}})
      .then((data: any) => updateQuery((prev: any) => ({userTeams: [...prev.userTeams, data?.data?.createTeamUserRelation?.teamUserRelation]})))
      .catch((e: any) => console.error(e))
  }

  const leaveTeam = (team: any) => {
    deleteTeamUserRelation({variables: {id: team.id}})
      .then(() => updateQuery((prev: any) => ({userTeams: prev.userTeams.filter((t: any) => t?.team?.id !== team?.team?.id)})))
      .catch((e: any) => console.error(e))
  }

  const errors: any[] = [error, createTeamApollo.error, createTeamUserRelationApollo.error, deleteTeamUserRelationApollo.error, updateTeamUserRelationApollo.error, updateTeamApollo.error]
  const loadings = [loading, createTeamApollo.loading, createTeamUserRelationApollo.loading, deleteTeamUserRelationApollo.loading, updateTeamUserRelationApollo.loading, updateTeamApollo.loading]
  const teamRelations = data?.userTeams?.filter((t: any) => !!t?.team?.id)
  const teams = teamRelations?.map((t: any) => t?.team).filter((t: any) => t)
  const allTeams = data?.teams

  return {
    createTeamClean,
    addUserToTeam,
    leaveTeam,
    errors,
    loadings,
    teamRelations,
    teams,
    refetch,
    updateTeamUserRelation,
    updateTeam,
    allTeams,
  }
}

export default useTeams