import React, { useState, } from "react";
import {
  IonList,
  IonContent,
  IonPage,
  IonItem,
  IonAccordionGroup,
  IonAccordion,
  IonIcon,
  IonFab,
  IonFabButton,
  IonLabel,
  IonBadge,
} from "@ionic/react";
import { add, } from "ionicons/icons";
import Toolbar from "../components/Toolbar";
import Loading from "../components/Loading";
import { gql } from "@apollo/client";
import { useSubscriptionWithElevatedRole } from "../hooks/hasuraHooks";
import { useMutationWithElevatedRole } from "../hooks/hasuraHooks";
import { ProcessContent } from "./Checklist";


export const shortenComponentType = (componentType: string) => componentType.replace("generator", "GEN").replace("taurus", "GT").replace("talpa", "TLP").replace("aquila", "AQL")


const Process = ({ process }: any) => {
  return (
    <IonAccordionGroup expand="inset">
      <IonAccordion value={process.id}>
        <IonItem slot="header" color="light">

          {process.archived && <IonBadge color="medium" slot="start">archived</IonBadge>}
          <IonLabel>
            {process.description}
          </IonLabel>

          {process.component_type && <IonBadge slot="end">{
            shortenComponentType(process.component_type)
          }</IonBadge>}
        
        </IonItem>
        <IonList lines="none" slot="content">
          <ProcessContent process={process}/>
        </IonList>
      </IonAccordion>
    </IonAccordionGroup>
  );
};

const Processes = () => {
  const { loading, data } = useSubscriptionWithElevatedRole(
    gql`
      subscription Processes {
        processes(order_by: {id: asc, component_type: asc, process_group_id: asc}) {
          id
          created_at
          updated_at

          description

          component_type

          interval_s
          duration_s
          remote
          archived

          process_group_id
        }
      }
    `,
  )

  const [addProcess] = useMutationWithElevatedRole(
    gql`
      mutation InsertProcess {
        insert_processes_one(object: {}) {
          id
        }
      }
    `,
    {}
  );

  return <IonPage>
    <Toolbar name="Processes" />
    <IonContent fullscreen>
      {loading && <Loading />}
      <IonFab horizontal="end" vertical="bottom" slot="fixed">
        <IonFabButton onClick={() => addProcess()}>
          <IonIcon icon={add}></IonIcon>
        </IonFabButton>
      </IonFab>
      {data?.processes && <IonList>
        {data.processes.map((process: any) => <Process process={process} key={process.id} />)}
      </IonList>}
    </IonContent>
  </IonPage>
};
export default Processes;