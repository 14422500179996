import { IonButton, IonIcon} from '@ionic/react';
import React from 'react';
import history from '../history'
import useWindowDimensions from '../useWindowDimensions'

const RobotButton: React.FC<any> = ({robot, compact, text, shortenOnSmallScreens = true, children, ...props}) => {
    const {isLargeScreen} = useWindowDimensions()
    props.fill = props.fill || "solid"

    let name = text || robot?.name || "unnamed robot"
    if (!isLargeScreen && shortenOnSmallScreens) {name = name.replace("w04-0", " ")}

    const onClick = (e:any) => {
        e.stopPropagation()
        history.push(`/robot/${robot.id}/${robot.name}`)
    }
    let a = true

   return <IonButton color="secondary" onClick={onClick} {...props}>
        {children || <>{name}</>}
    </IonButton>
};

export default RobotButton;
