import { IonList, IonButton, IonContent, IonPage, IonItem, IonLabel, IonIcon, IonChip, IonCard, IonCardContent, IonInput } from '@ionic/react';
import React, { useState } from 'react';
import Toolbar from '../components/Toolbar'
import { useQuery, useMutation, gql } from '@apollo/client';
import Loading from '../components/Loading'
import { bag, chevronDown, chevronUp } from 'ionicons/icons';
import { BagItemFromId } from '../components/BagItem';
import { AddEvaluation, EvaluationItemFromId } from '../components/EvaluationItem';
import getMaxRole from '../components/lib/getMaxRole';


export const TagCard: React.FC<any> = ({tag}) => {
  const [expanded, setExpanded] = useState(false)
 
  const headerItem = <>
    <IonItem style={{fontWeight: expanded ? "bold": ""}} onClick={() => setExpanded(!expanded)}>
      <IonLabel>
        {tag.name}
      </IonLabel>
      {tag.bag_tags.length > 0 && <IonChip outline>{tag.bag_tags.length} x<IonIcon icon={bag}/></IonChip>}
      <IonButton slot="end" onClick={() => setExpanded(!expanded)} color="secondary" fill="clear">
          <IonIcon icon={expanded ? chevronUp : chevronDown} slot={"icon-only"}/>
      </IonButton>
    </IonItem>
  </>
  
  if (expanded) {
    return <IonCard>
        {headerItem}
        <IonCardContent>
          {tag.bag_tags.map((bag_tag: any) => <BagItemFromId 
            AddEvaluation={AddEvaluation} EvaluationItemFromId={EvaluationItemFromId}
            key={bag_tag.id} bag_id={bag_tag.bag.id}/>)}
        </IonCardContent>
      </IonCard>
  }
  return headerItem
}


const Tags: React.FC = () => {
  const [newTagName, setNewTagName] = useState("")
  const { loading, error, data} = useQuery(gql`
    query Tags{
        tags(order_by: {id: desc}) {
          id
          name
          bag_tags {
            id
            bag {
              id
              name
              path
            }
          }
        }
    }
  `, {pollInterval: 1000, context: {
    headers: {
      "x-hasura-role": getMaxRole()
    }
  }});
  const [createTag] = useMutation(gql`
    mutation CreateTag($object: tags_insert_input!) {
      insert_tags_one(object: $object) {
        id
        name
      }
    }
  `, {
    update: () => {},
    context: {
      headers: {
        "x-hasura-role": getMaxRole()
      }
    }
  })
  return (
    <IonPage>
      <Toolbar name="Tags"/>
      <IonContent fullscreen>
        {loading && <Loading/>}
        <IonItem>
          <IonInput value={newTagName} placeholder="New tag name" onIonChange={(e: any) => setNewTagName(e.detail.value)}/>
          {newTagName?.length > 0 && <IonButton fill="outline" expand="block" onClick={() => createTag({variables: {object: {name: newTagName}}})}>
            Add tag '{newTagName}'
          </IonButton>}
        </IonItem>
        {data && <>
          <IonList>
            {data.tags.map((tag: any, index: number) => <TagCard tag={tag} key={index} />)}
          </IonList>
        </>}
      </IonContent>
    </IonPage>
  );
};
export default Tags;