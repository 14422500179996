import { IonChip } from '@ionic/react';
import React, { useState } from 'react';

export const textToTags = (text: string) => {
    return text?.split("/")[1]?.split("__").map((subText: string) => {
        let t = subText?.split(":")[0]?.replaceAll("_", " ").replaceAll("/", " ")
        if (t.startsWith("taurus")) {
            t = "🚘 " + t
        }
        if (t.startsWith("cam") && t.length === 4) {
            t = t.replace("cam", "📷 ")
        }
        if (t.startsWith("row")) {
            if (t.includes("right")) {
                t =  t.replace("right", "") + "↰"
            }
            else {
                t = "↱" + t.replace("left", "")
            }
        }
        return t
    }).flat()
}

const DecoratedText: React.FC<any> = ({text}) => {
    if (!text) {return null}
    return <>{textToTags(text).map((subText: string) => {
            return <IonChip>{subText}</IonChip>
    })}</>
}
export default DecoratedText