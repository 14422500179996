import React, { useEffect, useRef, useState, } from 'react';
import {Group, } from 'react-konva';
import {Html} from 'react-konva-utils';
import { MultiContextProvider } from './MultiContextProvider';


export const KonvaHtml: React.FC<any> = ({contexts, x, y, children, scale=1/100., zIndex=0, ...props}: any) => {
  const [trigger, setTrigger] = useState<any>(null)
  props.scaleX = props.scaleX || scale
  props.scaleY = props.scaleY || scale

  // Check width and height of child element to move it by half in order to center around x, y
  const ref = useRef<any>()
  const width = ref?.current?.clientWidth || 0
  const height = ref?.current?.clientHeight || 0
  if (!ref?.current) {
    // Hack: if ref is not available yet, setTrigger to force rerender and compute width and height properly
    setTimeout(() => setTrigger({}), 10)
  }

  return <Group x={x} y={y}>
    <Html
      divProps={{
        style: {
          position: 'absolute',
          zIndex,
        },
      }}
      transformFunc={(a: any) => {/* no need to scale and rotate: we only need the position */ a.rotation = 0; a.scaleX *= props.scaleX; a.scaleY *= -props.scaleY; return a}}
      >
      <MultiContextProvider contexts={contexts}>
        <div ref={ref} style={{display: "flex", height: "fit-content", width: "fit-content", marginLeft: -width / 2, marginTop: -height / 2, justifyContent: "center", alignItems: "center"}}>
          {children}
        </div>
      </MultiContextProvider>
    </Html>
  </Group>
}