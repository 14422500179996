import { IonContent, IonCardContent, IonCard, IonList, IonItem, IonPage, IonTitle, IonToolbar, } from '@ionic/react';
import React, {useState} from 'react';
import { useParams } from 'react-router';
import { useQuery, useMutation, gql } from '@apollo/client';
import EditButton from '../components/EditButton'
import Toolbar from '../components/Toolbar'
import EventsCard from '../components/EventsCard'
import EventTypeForm from '../components/EventTypeForm'
import ApolloStatus from '../components/ApolloStatus'
import ReactMarkdown from 'react-markdown'


const EventType: React.FC = () => {
  const { id } = useParams<{ id: string; }>();
  const { data, refetch, loading, error } = useQuery(gql`
    query ($id :Int){
      event_types(where: {id: {_eq: $id}}) {
        id
        name
        description
        children {
          id
        }
        parents {
          id
        }
        events(order_by: {start:desc}, limit: 5) {
          id
          start
          name
          description
          event_type {
            id
            name
            is_error
          }
        }
      }
    }`, {"variables": {id}});
  const [edit, setEdit] = useState(false)

  return (
    <IonPage>
      <Toolbar>
          <IonTitle>{data?.event_types[0]?.name || "Event type"}</IonTitle>
          <EditButton slot="end" fill="outline" color="secondary" onClick={() => setEdit(true)}/>
      </Toolbar>
      <IonContent fullscreen>
         <ApolloStatus loadings={[loading]} errors={[error]} refetch={refetch}/>
         {data?.event_types[0] && <>
            {edit && <EventTypeForm eventType={data.event_types[0]} refetchParent={refetch} onFinish={() => {refetch(); setEdit(false)}}/>}
            <EventsCard events={data?.event_types[0]?.events} eventTypesFilterDefault={[id]} show={{eventType: false}}/>
         </>}
      </IonContent>
    </IonPage>
  );
};

export default EventType;
