import { IonButton, IonItem, IonLabel, IonSelect, IonSelectOption } from '@ionic/react';
import React, {useState} from 'react';
import {Stage, Layer, Line, Arrow, Text, Circle} from 'react-konva';


// from https://9to5answer.com/map-a-range-of-values-e-g-0-255-to-a-range-of-colours-e-g-rainbow-red-blue-in-javascript
function rainbowColor(length: number, maxLength: number, opacity: number = 1.)
{
    var i = (length * 255 / maxLength);
    var r = Math.round(Math.sin(0.024 * i + 0) * 127 + 128);
    var g = Math.round(Math.sin(0.024 * i + 2) * 127 + 128);
    var b = Math.round(Math.sin(0.024 * i + 4) * 127 + 128);
    return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity + ')';
}


export const LocalizationMap: React.FC<any> = ({evaluation, resultData}: any) => {
  const width = window.visualViewport.width
  const height = window.visualViewport.height
  const [scale, setScale] = useState(300.0)

  const zoomIn = ()=>setScale(scale / 0.9);
  const zoomOut = ()=>setScale(0.9 * scale)

  const [activePaths, setActivePaths] = useState<any>(new Set(resultData?.localization?.paths?.map((p: any) => p.name)))

  const togglePathName = (pathName: string) => {
    if (activePaths?.has(pathName)) {
        activePaths.delete(pathName)
    }
    else {
        activePaths.add(pathName)
    }
    setActivePaths(new Set(activePaths.values()))
  }

  const color = (i: number, opacity: number) => rainbowColor(i, resultData?.localization?.paths?.length, opacity)

  return <>
    <div style={{display: "flex", flexDirection: "column", zIndex: 1000, top: 50, maxWidth: 400}}>
        {resultData?.localization?.paths?.map((path: any, i: number) => <IonButton
            fill={activePaths.has(path.name) ? "outline": "clear"} size="small"
            style={{
                backgroundColor: activePaths.has(path.name) ? color(i, 0.3) : "",
            }} onClick={() => togglePathName(path.name)}
        >
            {path.name}
        </IonButton>)}
    </div>
    <Stage
        draggable
        onWheel={(e: any) =>{e.evt.preventDefault(); ((e.evt.deltaY < 0)? zoomIn: zoomOut)()}}
        width={width}
        height={height}
        style={{width: width+"px", height: height+"px"}}
        x={width / 2} y={height / 2}
        >
        <Layer scaleX={scale} scaleY={-scale}>
            {/* Origin frame */}
            <Arrow points={[0, 0, 0, 0.1]} stroke="black" strokeWidth={0.005} pointerLength={0.01} pointerWidth={0.01} />
            <Text x={0} y={0.1} text={"y"} scaleY={-1} fontSize={0.05} />
            <Arrow points={[0, 0, 0.1, 0.]} stroke="black" strokeWidth={0.005} pointerLength={0.01} pointerWidth={0.01} />
            <Text x={0.1} y={0} scaleY={-1} text={"x"} fontSize={0.05} />
                
            {resultData?.localization?.paths?.map((path: any, i: number) => 
                activePaths?.has(path.name) ? path.points.map((p: any) => <Circle x={p[0]} y={p[1]} radius={0.005} fill={color(i, 0.5)}/>): null)
            }
        </Layer>
    </Stage>
  </>
  return null
}
