import { IonContent, IonPage, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCard, IonCardContent, IonItem } from '@ionic/react';
import React, {useState} from 'react';
import { useParams } from 'react-router';
import { useQuery, gql } from '@apollo/client';
import Toolbar from '../components/Toolbar'
import RobotButton from '../components/RobotButton'
import AquilaForm from '../components/AquilaForm'
import EventsCard from '../components/EventsCard'
import EditButton from '../components/EditButton'
import ReactMarkdown from 'react-markdown'
import ApolloStatus from '../components/ApolloStatus'
import XavierItem from '../components/XavierItem'

const Aquila: React.FC = () => {
  const { id, name } = useParams<{ id: string; name: string}>();
  const { data, loading, error, refetch } = useQuery(gql`query{aquilas(where: {id: {_eq: ${id}}}){
    id
    name
    xavier {
      id
      hostname
      eth0_mac
      aquila {
        id
      }
    }
    # events(order_by:{start:desc}, limit: 5){id start name description event_type {id name is_error} aquila {id name } robot {id name} field {id name} user {id username}}
  }}`);
  const [edit, setEdit] = useState(false)

  const aquila = data?.aquilas[0];

  return (
    <IonPage>
      <Toolbar name={aquila?.name ? aquila?.name : "Camera"}/>
      <IonContent fullscreen>
        <ApolloStatus loadings={[loading]} errors={[error]} refetch={refetch}/>
        {data && <>
            {!edit && <IonCard>
                <IonCardHeader>
                    <IonCardSubtitle>Configuration</IonCardSubtitle>
                    <IonCardTitle>{aquila?.name || "Unnamed Aquila"}</IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                    <ReactMarkdown>{aquila?.description || "No description yet."}</ReactMarkdown>
                    {aquila?.robotRelation && <RobotButton robot={aquila?.robotRelation?.robot}>:{aquila?.robotRelation?.position}</RobotButton>}
                    <XavierItem xavier={aquila?.xavier}/>
                    {aquila?.cameraType && <IonItem>{aquila?.cameraType}</IonItem>}
                    <EditButton onClick={() => setEdit(true)}/>
                </IonCardContent>
            </IonCard>}

            {data?.aquila && edit && <AquilaForm aquila={aquila} refetchParent={refetch} onFinish={() => {refetch(); setEdit(false)}}/>}
        </>}

        {aquila?.id && <EventsCard events={aquila?.events} aquilasFilterDefault={[aquila?.id]} show={{aquila: false}}/>}
      </IonContent>
    </IonPage>
  );
};

export default Aquila;
