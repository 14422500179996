import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { setupIonicReact } from '@ionic/react';
import './shared-components/utils/i18n'

setupIonicReact({
    
  });

ReactDOM.render(<App />, document.getElementById('root'));

console.log("registering service worker");
serviceWorkerRegistration.register({
    onSuccess: () => console.log("service worker running"),
    onUpdate: () => {
        console.log("service worker updated")
        // window.location.reload()
    }});
console.log("registered service worker?");

//serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();