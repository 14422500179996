import { IonList,
   IonButton,
    IonContent, 
    IonPage, 
    IonItem, 
    IonLabel, 
    IonBadge, 
    IonIcon, 
    IonChip, 
    IonPopover, 
    IonToolbar, 
    IonCard, 
    IonCardHeader, 
    IonCardContent, 
    IonCardSubtitle, 
    IonModal, 
    IonCardTitle, 
    IonCheckbox, 
    IonButtons, 
    IonFab, 
    IonFabButton, 
    IonSkeletonText,
    IonInput, 
    IonCol, 
    IonRow, 
    IonListHeader, 
    IonTitle, 
    IonItemDivider, 
    IonAccordion, 
    IonAccordionGroup,
  } from '@ionic/react';
import React, { useState } from 'react';
import Toolbar from '../components/Toolbar'
import { useQuery, useMutation, gql } from '@apollo/client';
import Loading from '../components/Loading'
import { checkmarkCircle, chevronDown, chevronUp, removeCircle, trash, } from 'ionicons/icons';
import ConfirmButton from '../shared-components/ConfirmButton';
import ParamConfigButton from '../components/ParamConfigButton';
import { AddEvaluation, EvaluationItemFromId } from '../components/EvaluationItem';
import getMaxRole from '../components/lib/getMaxRole';
import { useMutationWithElevatedRole, useQueryWithElevatedRole } from '../hooks/hasuraHooks';
import useWindowDimensions from '../useWindowDimensions';
import './Rules.css'


export const RuleCard: React.FC<any> = ({rule, duplicate, ...props}) => {
  const {isLargeScreen} = useWindowDimensions()
  const [removeTag] = useMutationWithElevatedRole(gql`
    mutation RemoveTag($id: Int!) {
      delete_param_config_rules_m2m_tags_by_pk(id: $id) {id}
    }
  `)
  const [updateRule] = useMutationWithElevatedRole(gql`
    mutation UpdateRule($id: Int!, $_set: param_config_rules_set_input = {}) {
      update_param_config_rules_by_pk(pk_columns: {id: $id}, _set: $_set) {
        id
      }
    }
  `)
  if (!rule?.id) {return null}
  
  const headerItem = (
    <>
      <IonIcon
        icon={rule?.validated ? checkmarkCircle : removeCircle}
        color={rule?.validated ? "success" : "medium"}
        slot="start"
      />
      <IonLabel>
        {rule?.recommended_param_config?.description}
        <IonButtons>
          {rule.tags.map(({ id, tag }: any) => (
            <ConfirmButton
              text={`Remove tag '${tag.name}'`}
              key={tag.id}
              color="secondary"
              size="small"
              onClick={(e: any) => {
                removeTag({ variables: { id: id } });
              }}
            >
              {tag.name}
            </ConfirmButton>
          ))}
        </IonButtons>
      </IonLabel>
      <IonButtons slot="end">
        {rule?.is_dev && (
          <IonChip color="tertiary" style={{ padding: 15, margin: 15 }}>
            <IonLabel>Dev</IonLabel>
          </IonChip>
        )}
        <IonItem>
          <ConfirmButton
            text="This change will be deployed to all robots. Please confirm."
            fill="clear"
            onClick={() =>
              updateRule({
                variables: {
                  id: rule.id,
                  _set: { validated: !rule.validated },
                },
              })
            }
          >
            {rule.validated ? "Archive" : "Restore"}
          </ConfirmButton>
        </IonItem>
        {rule?.recommended_param_config && (
          <ParamConfigButton param_config={rule.recommended_param_config} />
        )}
      </IonButtons>
    </>
  );
  
  if(isLargeScreen){
    return <IonItem>{headerItem}</IonItem>
  }
  return <IonList>{headerItem}</IonList>
}


const getSubVarietyNames = (plantVariety: any) => {
  // Recursively get names of all sub-varieties
  return plantVariety?.sub_plant_varieties?.map((subPlant: any) => [subPlant.name_EN, ...(getSubVarietyNames(subPlant) || [])]).flat()
}


const ParentRuleAccordion : React.FC<any>  = ({ plant_variety }) => {
  const subVarietyNames = getSubVarietyNames(plant_variety)
  return (
    <>
      <IonAccordionGroup expand="inset">
        <IonAccordion value={plant_variety?.id}>
          <IonItem slot="header" color="light">
            <IonLabel>
              {plant_variety?.name_EN}
              {subVarietyNames.length > 0 && <> (+ {subVarietyNames.join(", ")})</>}
            </IonLabel>
          </IonItem>
          {plant_variety?.param_config_rules.map((rule:any, index: Number ) => {
            return (
              <div className="ion-padding" slot="content" key={rule?.id}>
                <RuleCard rule={rule} />
              </div>
            )
          })}
        </IonAccordion>
      </IonAccordionGroup>
    </>
  )
}


export const Rules: React.FC = () => {
  const [showOnlyValidated, setShowOnlyValidated] = useState(true)  // Option to only show validated rules
  const { loading, error, data} = useQueryWithElevatedRole(gql`
    query RulesByCrop($where: param_config_rules_bool_exp!) {
      plant_varieties(where: {param_config_rules_aggregate: {count: {predicate: {_gt: 0}}}}) {
        id
        name_EN

        sub_plant_varieties {
          name_EN
          sub_plant_varieties {
            name_EN
          }
        }

        param_config_rules(order_by: {plant_variety_id: asc, validated: desc}, where: $where) {
          id
          validated
          is_dev
          tags {
            id
            tag {
              name
              id
            }
          }
          recommended_param_config {
            id
            description
            data
          }
        }
      }
    }
  `, {
    pollInterval: 1000,
    variables: {where: showOnlyValidated ? {validated: {_eq: true}}: {}},
  });

  return (
    <IonPage>
      <Toolbar name="Rules">
        <IonTitle>Rules</IonTitle>
        <IonButton slot="end" onClick={() => setShowOnlyValidated(!showOnlyValidated)}>{showOnlyValidated ? "show" : "hide"}{" archived"}</IonButton>
      </Toolbar>
      <IonContent fullscreen>
        {loading && <Loading/>}
        {data && <>
          <IonList>
            {data?.plant_varieties?.map((plant_variety: any, index: number) => <ParentRuleAccordion 
              plant_variety={plant_variety} 
              key={plant_variety.id}
              />)}
          </IonList>
        </>}
      </IonContent>
    </IonPage>
  );
};
export default Rules;