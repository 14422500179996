import { IonList, IonItem, IonItemDivider } from '@ionic/react';
import React from 'react';
const moment = require('moment')

const EventTimeline: React.FC<any> = ({events = [], show}) => {
  return null
  /*
  return <>
        <IonList>
            { events.map((event: any, index: number) => {
                let timespanName = null
                while (moment(event.start) < startOfNextDay) {
                    startOfNextDay = startOfNextDay.subtract(1, "day")
                    timespanName = startOfNextDay.format("LL")
                    if (timespanName === moment().startOf("day").format("LL")) {
                        timespanName = "today"
                    }
                    if (timespanName === moment().startOf("day").subtract(1, "day").format("LL")) {
                        timespanName = "yesterday"
                    }
                }
                return <React.Fragment key={index}>
                    {timespanName && <IonItemDivider>{timespanName}</IonItemDivider>}
                    <EventItem onlyShowTime={true} event={event} show={show}/>
                </React.Fragment>
            }
            )}
            {events?.length < 1 && <IonItem>No events found</IonItem>}
        </IonList>
    </>
    */
}

export default EventTimeline;
