import { IonPage, IonContent, IonButton, IonProgressBar, IonPopover, IonItemDivider, IonLabel, IonItem, IonIcon, IonToggle, IonBadge, IonSelectOption, IonInput, } from '@ionic/react';
import React, { useState } from 'react';
import { gql } from '@apollo/client';
import Toolbar from '../components/Toolbar'
import { useSubscriptionWithElevatedRole } from '../hooks/hasuraHooks';
import { GenericEventItem, } from '../components/TimelineItem';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';
import { funnel, } from 'ionicons/icons';
import { ComponentIdSelect, ComponentType } from '../components/ComponentIdSelect'
import { DateRangePicker, useDateRange } from '../components/DateRangePicker';
import { AddMessage } from '../components/AddMessage';


export const useTimelineOptions = (initial: any = null) => {
  const [showSimulation, setShowSimulation] = useState(initial?.showSimulation || false)
  const [simulationName, setSimulationName] = useState("")
  const [taurusId, setTaurusId] = useState<number|null>(initial?.taurusId || null)  // Null: no taurus

  const [showMessages, setShowMessages] = useState(initial?.showMessages || false)
  const [showEvents, setShowEvents] = useState(initial?.showEvents || false)
  const [showFieldProgress, setShowFieldProgress] = useState(initial?.showFieldProgress || false)
  const [showParams, setShowParams] = useState(initial?.showParams || false)
  const [showProcessExecutions, setShowProcessExecutions] = useState(initial?.showProcessExecutions || false)
  const [showRobotConfigs, setShowRobotConfigs] = useState(initial?.showRobotConfigs || false)
  return {
    showEvents, setShowEvents, showProcessExecutions, setShowProcessExecutions, showRobotConfigs, setShowRobotConfigs, showParams, setShowParams,
    showMessages, setShowMessages,
    showFieldProgress, setShowFieldProgress,
    showSimulation, setShowSimulation, simulationName, setSimulationName,
    taurusId, setTaurusId
  }
}

export const TimelineOptions: React.FC<any> = ({timelineOptions}: any) => {
  const [event, setEvent] = useState<any>(null)
  const {t} = useTranslation()
  return <>
    <IonButton onClick={setEvent}>
      <IonIcon icon={funnel} slot="start"/>
      {t("Filter")}
    </IonButton>
    <IonPopover isOpen={!!event} event={event} onDidDismiss={() => setEvent(null)}>

      <IonItem>
        <IonLabel position="stacked">{t("Robot filter")}</IonLabel>
        <ComponentIdSelect componentType={ComponentType.Taurus} componentId={timelineOptions.taurusId}
          setComponentId={(newValue: number) => timelineOptions.setTaurusId(newValue)}
          selectChildren={<IonSelectOption value={null}>{t("Show all")}</IonSelectOption>}/>
      </IonItem>

      <IonItem>
        <IonLabel>{t("Show simulated events")}</IonLabel>
        <IonToggle checked={timelineOptions.showSimulation} onIonChange={(e: any) => timelineOptions.setShowSimulation(e.detail.checked)} slot="end"/>
      </IonItem>

      {timelineOptions.showSimulation && <IonItem>
        <IonLabel position="stacked">{t("Simulation name")}</IonLabel>
        <IonInput value={timelineOptions.simulationName} onIonChange={(e: any) => timelineOptions.setSimulationName(e.detail.value)}/>
      </IonItem>}

      <IonItemDivider>
        <IonLabel>{t("Event types")}</IonLabel>
      </IonItemDivider>

      <IonItem>
        <IonLabel>{t("Messages")}</IonLabel>
        <IonToggle checked={timelineOptions.showMessages} onIonChange={(e: any) => timelineOptions.setShowMessages(e.detail.checked)} slot="end"/>
      </IonItem>
 
      <IonItem>
        <IonLabel>{t("Robot events")}</IonLabel>
        <IonToggle checked={timelineOptions.showEvents} onIonChange={(e: any) => timelineOptions.setShowEvents(e.detail.checked)} slot="end"/>
      </IonItem>
    
      <IonItem>
        <IonLabel>{t("Checklist items")}</IonLabel>
        <IonToggle checked={timelineOptions.showProcessExecutions} onIonChange={(e: any) => timelineOptions.setShowProcessExecutions(e.detail.checked)} slot="end"/>
      </IonItem>

      <IonItem>
        <IonLabel>{t("Robot configs")}</IonLabel>
        <IonToggle checked={timelineOptions.showRobotConfigs} onIonChange={(e: any) => timelineOptions.setShowRobotConfigs(e.detail.checked)} slot="end"/>
      </IonItem>

      <IonItem>
        <IonLabel>{t("Parameters")}</IonLabel>
        <IonToggle checked={timelineOptions.showParams} onIonChange={(e: any) => timelineOptions.setShowParams(e.detail.checked)} slot="end"/>
      </IonItem>

      <IonItem>
        <IonLabel>{t("Field progress")}</IonLabel>
        <IonToggle checked={timelineOptions.showFieldProgress} onIonChange={(e: any) => timelineOptions.setShowFieldProgress(e.detail.checked)} slot="end"/>
      </IonItem>

    </IonPopover>
  </>
}


export const TimelinePage: React.FC<any> = ({}) => {
  const {createdWhere, ...dateRange} = useDateRange()
  const timelineOptions = useTimelineOptions({
    showMessages: true, showEvents: true, showFieldProgress: true, showParams: true, showRobotConfigs: true, showProcessExecutions: true,
  })

  return <IonPage>
    <Toolbar name="Events">
      <DateRangePicker dateRange={dateRange}/>
      <TimelineOptions timelineOptions={timelineOptions}/>
    </Toolbar>
    <IonContent>
      <Timeline createdWhere={createdWhere} {...timelineOptions} taurusId={timelineOptions.taurusId} />
    </IonContent>
  </IonPage>
}


const mergeResults = ({results, maxNumberOfItems}: any) => {
  const resultLists = Object.entries(results).map(([typeName, obj]: any) => ({typeName, ...obj}))
  const loading = resultLists.some(({loading}: any) => loading)
  const errors = resultLists.filter(({error}: any) => error !== undefined).map(({error, typeName}: any) => ({error, typeName}))  // Only keep if not undefined
  const tooManyEvents = resultLists.filter(({data}: any) => data?.events?.length >= maxNumberOfItems).map(({typeName}: any) => typeName)
  const events = resultLists.map(({data}: any) => data?.events).filter((events: any) => !!events).flat().sort((a: any, b: any) => a.created_at.localeCompare(b.created_at))
  return {
    loading, errors, events, tooManyEvents
  }
}



export const Timeline: React.FC<any> = ({
    createdWhere, showProcessExecutions, showEvents, showMessages, showRobotConfigs, showParams, showFieldProgress,
    taurusId, showSimulation, simulationName,
    containerStyle
  }) => {
  const maxNumberOfItems = 50  // Limit the number of items per query
  const taurusWhere = taurusId ? {taurus_id: {_eq: taurusId}} : {}  // optional filter by taurus_id
  const simulationWhere = showSimulation ? {simulation_name: {_regex: simulationName}} : {simulation_name: {_is_null: true}}  // hide simulation or filter by simulation
  const robotEvents = useSubscriptionWithElevatedRole(gql`
    subscription CurrentEvents($eventsWhere: events_bool_exp = {}, $maxNumberOfItems: Int!) {
        events(order_by: {id: desc}, where: $eventsWhere, limit: $maxNumberOfItems) {
          id
          created_at
          data
          is_sim
          simulation_name
          robot_config {
            taurus_configs {
              taurus_id
            }
          }
          field_id
        }
    }
  `, {
    variables: {eventsWhere: {...createdWhere, ...taurusWhere, ...simulationWhere}, maxNumberOfItems},
    skip: !showEvents,
  });
  
  const taurusRobotConfigWhere = taurusId ? {taurus_configs: {taurus_id: {_eq: taurusId}}} : {}
  const robotConfigs = useSubscriptionWithElevatedRole(gql`
    subscription CurrentRobotConfigs($eventsWhere: robot_configs_bool_exp = {}, $maxNumberOfItems: Int!) {
        events: robot_configs(order_by: {id: desc}, where: $eventsWhere, limit: $maxNumberOfItems) {
          created_at
          taurus_configs {
            taurus_id
          }
          id
        }
    }
  `, {
    variables: {eventsWhere: {...createdWhere, ...taurusRobotConfigWhere}, maxNumberOfItems},
    skip: !showRobotConfigs,
  });

  const params = useSubscriptionWithElevatedRole(gql`
    subscription CurrentParams($eventsWhere: param_config_states_bool_exp = {}, $maxNumberOfItems: Int!) {
        events: param_config_states(order_by: {id: desc}, where: $eventsWhere, limit: $maxNumberOfItems) {
          created_at
          taurus_id
          overrides
          last_update_description
          param_config_id
          simulation_name
          previous_state {
            created_at
            last_update_description
          }
        }
    }
  `, {
    variables: {eventsWhere: {...createdWhere, ...taurusWhere, ...simulationWhere}, maxNumberOfItems},
    skip: !showParams,
  });

  const processExecutions = useSubscriptionWithElevatedRole(gql`
    subscription CurrentProcessExecutions($where: process_executions_bool_exp = {}, $maxNumberOfItems: Int!) {
        events: process_executions(order_by: {id: desc}, where: $where, limit: $maxNumberOfItems) {
          created_at
          author_name

          aquila_id
          taurus_id
          talpa_id
          generator_id

          process {
            description
          }
        }
    }
  `, {
    variables: {where: {...createdWhere, ...taurusWhere}, maxNumberOfItems},
    skip: !showProcessExecutions,
  });
  
  const fieldProgress = useSubscriptionWithElevatedRole(gql`
    subscription CurrentFieldProgresses($where: field_progresses_bool_exp = {}, $maxNumberOfItems: Int!) {
        events: field_progresses(order_by: {id: desc}, where: $where, limit: $maxNumberOfItems) {
          created_at
          simulation_name
          robot_config_id
          taurus_id
          treated_ratio
          total_area_m2
          field_id
        }
    }
  `, {
    variables: {where: {...createdWhere, ...taurusWhere, ...simulationWhere}, maxNumberOfItems},
    skip: !showFieldProgress,
  });

  
  const robotConfigWhere = taurusId ? {robot_config: {taurus_configs: {taurus_id: {_eq: taurusId}}}} : {}
  const message = useSubscriptionWithElevatedRole(gql`
    subscription CurrentMessages($where: messages_bool_exp = {}, $maxNumberOfItems: Int!) {
        events: messages(order_by: {id: desc}, where: $where, limit: $maxNumberOfItems) {
          id
          created_at
          text_md
          author_user {
            name
          }
          robot_config {
            taurus_configs {
              taurus_id
            }
          }
        }
    }
  `, {
    variables: {where: {...createdWhere, ...robotConfigWhere, ...simulationWhere}, maxNumberOfItems},
    skip: !showMessages,
  });

  const {loading, events, errors, tooManyEvents} = mergeResults({
    results: {processExecutions, robotEvents, params, robotConfigs, fieldProgress, message},
    maxNumberOfItems
  })

  return <>
    {errors.map(({error, typeName}: any, i: number) => <IonItem key={i}>
      <IonBadge color="danger" slot="start">{t("Error for {{typeName}}", {typeName})}</IonBadge>
      {JSON.stringify(error)}
    </IonItem>)}
    {tooManyEvents.map((typeName: string) => <IonItem key={typeName}>
      <IonBadge>
        {t("There are too many events for {{typeName}} for this range and they have been limited to the latest {{maxNumberOfItems}}", {typeName, maxNumberOfItems})}
      </IonBadge>
    </IonItem>)}
    {events.map((data: any) => <GenericEventItem data={data} key={data.created_at}/>)}
    {loading && <IonProgressBar type="indeterminate"/>}
    <AddMessage fixedTaurusId={taurusId}/>
  </>
};