import React, { useState, } from "react";
import {
  IonList,
  IonContent,
  IonPage,
  IonItem,
  IonAccordionGroup,
  IonAccordion,
  IonIcon,
  IonFab,
  IonFabButton,
  IonLabel,
  IonInput,
} from "@ionic/react";
import { add, } from "ionicons/icons";
import Toolbar from "../components/Toolbar";
import Loading from "../components/Loading";
import { gql } from "@apollo/client";
import { useSubscriptionWithElevatedRole } from "../hooks/hasuraHooks";
import { useMutationWithElevatedRole } from "../hooks/hasuraHooks";
import { ComponentEditorButtonsItem, useComponentEditor } from "../components/ComponentEditor";


const Router = ({ router }: any) => {
  const [updateRouter] = useMutationWithElevatedRole(
    gql`
      mutation UpdateRouter($id: Int!, $_set: routers_set_input!) {
        update_routers_by_pk(pk_columns: { id: $id }, _set: $_set) {
          id
        }
      }
    `,
    {}
  );
  const {save, reset, props, changedFields, tmp, } = useComponentEditor(router, updateRouter)

  return (
    <IonAccordionGroup expand="inset">
      <IonAccordion value={router.id}>
        <IonItem slot="header" color="light">
          {router.serial_number}
          <IonLabel slot="end">
            {router.description}
          </IonLabel>
        </IonItem>
        <IonList lines="none" slot="content">
          <ComponentEditorButtonsItem component={router} save={save} reset={reset} changedFields={changedFields}/>

          <IonItem>
            <IonLabel position="stacked">Model</IonLabel>
            <IonInput {...props("model")} value={tmp.model} />
          </IonItem>

          <IonItem>
            <IonLabel position="stacked">Manufacturer</IonLabel>
            <IonInput {...props("manufacturer")} value={tmp.manufacturer} placeholder="Manufacturer"/>
          </IonItem>

          <IonItem>
            <IonLabel position="stacked">Description</IonLabel>
            <IonInput {...props("description")} value={tmp.description} placeholder="Description"/>
          </IonItem>

          <IonItem>
            <IonLabel position="stacked">Default password</IonLabel>
            <IonInput {...props("default_password")} value={tmp.default_password} placeholder="Default Password"/>
          </IonItem>

        </IonList>
      </IonAccordion>
    </IonAccordionGroup>
  );
};


const Routers = () => {
  const { loading, data } = useSubscriptionWithElevatedRole(
    gql`
      subscription Routers {
        routers {
          id
          updated_at
          description
          model
          manufacturer
          default_password
        }
      }
    `,
  )

  const [addRouter] = useMutationWithElevatedRole(
    gql`
      mutation InsertRouter {
        insert_routers_one(object: {}) {
          id
        }
      }
    `,
    {}
  );

  return (
    <IonPage>
      <Toolbar name="Routers" />
      <IonContent fullscreen>
        {loading && <Loading />}
        <IonFab horizontal="end" vertical="bottom" slot="fixed">
          <IonFabButton onClick={() => addRouter()}>
            <IonIcon icon={add}></IonIcon>
          </IonFabButton>
        </IonFab>
        {data?.routers && <IonList>
          {data.routers.map((router: any) => <Router router={router} key={router.id} />)}
        </IonList>}
      </IonContent>
    </IonPage>
  );
};
export default Routers;