import {
  IonList,
  IonContent,
  IonPage,
  IonItem,
  IonIcon,
  IonLabel,
  IonProgressBar,
  IonSelect,
  IonSelectOption,
  IonBadge,
  IonPopover,
  IonButton,
  IonInput,
  IonToggle,
  IonTextarea,
  IonItemDivider,
  IonToolbar,
} from "@ionic/react";
import { addCircle, checkboxOutline, checkmark, checkmarkCircle, list, pencilSharp, personAdd, reload, squareOutline, trash, } from "ionicons/icons";
import Toolbar from "../components/Toolbar";
import { gql, useSubscription } from "@apollo/client";
import { useMutationWithElevatedRole, useSubscriptionWithElevatedRole } from "../hooks/hasuraHooks";
import React, { useEffect, useState } from "react";
import getUsername from "../components/lib/getUsername";
import useWindowDimensions from "../useWindowDimensions";
import { ComponentEditorButtonsItem, useComponentEditor } from "../components/ComponentEditor";
import { ProcessGroupSelect, useProcessGroups } from "./ProcessGroups";
import { useUsername } from "../hooks/userHooks";
import { useGetChecklistActions } from "../hooks/useGetChecklistActions";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { ComponentIdSelect, ComponentType, ComponentTypeSelect } from "../components/ComponentIdSelect";
import { useComponentIds } from "../hooks/useComponentIds";
const moment = require('moment');


export const ProcessContent = ({ process, afterSave = () => {}}: any) => {
  const [updateProcess] = useMutationWithElevatedRole(
    gql`
      mutation UpdateProcess($id: Int!, $_set: processes_set_input!) {
        update_processes_by_pk(pk_columns: { id: $id }, _set: $_set) {
          id
        }
      }
    `,
    {}
  );
  const {save, reset, props, changedFields, tmp, archived, toggleArchived} = useComponentEditor(process, updateProcess)

  return <>
    <ComponentEditorButtonsItem component={process} save={() => {save(); afterSave()}} reset={reset} archived={archived} toggleArchived={toggleArchived} changedFields={changedFields}/>

    <IonItem>
      <IonLabel position="stacked">Description</IonLabel>
      <IonTextarea autoGrow={true} {...props("description")} value={tmp.description} placeholder="Description"/>
    </IonItem>

    <IonItem>
      <IonLabel position="stacked">Interval (s)</IonLabel>
      <IonInput {...props("interval_s")} value={tmp.interval_s} placeholder="Interval" type="number"/>
    </IonItem>

    <IonItem>
      <IonLabel position="stacked">Duration (s)</IonLabel>
      <IonInput {...props("duration_s")} value={tmp.duration_s} placeholder="duration" type="number"/>
    </IonItem>

    <IonItem>
      <IonLabel position="stacked">Remote</IonLabel>
      <IonToggle {...props("remote")} value={tmp.remote}/>
    </IonItem>
  
    {tmp.component_type && <IonItem>
      <ComponentTypeSelect value={tmp.component_type} {...props("component_type")}/>
    </IonItem>}

    <IonItem>
      <IonLabel position="stacked">Group</IonLabel>
      <ProcessGroupSelect {...props("process_group_id")} value={tmp.process_group_id}/>
    </IonItem>
  </>
}


const ProcessExecution = ({processExecution, checklistActions}: any) => {
  const {author_name, created_at} = processExecution
  return <IonItem>{author_name}: {moment(created_at).fromNow()}
    <IonButton slot="end" fill="clear" onClick={() => checklistActions.deleteProcessExecution(processExecution.id)}>
      <IonIcon icon={trash} slot="icon-only"/>
    </IonButton>
  </IonItem>
}


const ChecklistItem = ({process, checklistActions}: any) => {
  const done = process.process_executions.length > 0
  const [showExecutionsEvent, setShowExecutionsEvent] = useState<any>(null)
  const [showProcessEvent, setShowProcessEvent] = useState<any>(null)
  const [showAssignEvent, setShowAssignEvent] = useState<any>(null)
  const {isLargeScreen} = useWindowDimensions()
  const todo = process.todos[0]  // Only care about one todo
  return <IonItem>
    <IonLabel style={{whiteSpace: "pre-wrap"}} onClick={setShowProcessEvent}>
      {process.description}
    </IonLabel>
    <IonButton size="small" fill="clear" onClick={setShowProcessEvent}>
      <IonIcon icon={pencilSharp}/>
    </IonButton>
    {!todo && <IonButton size="small" fill="clear" onClick={setShowAssignEvent}>
      <IonIcon icon={personAdd}/>
    </IonButton>}

    {todo && <IonBadge color="danger" style={{cursor: "pointer"}}
      onClick={() => checklistActions.deleteTodo(todo.id)}>
      TODO
      {isLargeScreen && <>@{todo.assigned_to?.name || "No assignee"}</>}
    </IonBadge>}

    {isLargeScreen && process.process_executions.slice(0, 1).map(({author_name, created_at, id}: any) => <IonBadge
        slot="end" color="success" onClick={setShowExecutionsEvent} style={{cursor: "pointer"}} key={id}>
      <IonIcon icon={checkmark}/>
      {author_name}: {moment(created_at).fromNow()}
    </IonBadge>)}
    {done ? <>
      <IonIcon slot="end" icon={reload} style={{cursor: "pointer"}} onClick={() => checklistActions.setAsDone(todo?.id)} />
      <IonIcon slot="end" icon={checkboxOutline}  style={{cursor: "pointer"}} onClick={setShowExecutionsEvent} color="success"/>
    </>: <>
      <IonIcon slot="end" icon={squareOutline} style={{cursor: "pointer"}} onClick={() => checklistActions.setAsDone(todo?.id)} />
    </>}
    <IonPopover className='wide-popover' event={showExecutionsEvent} isOpen={!!showExecutionsEvent} onDidDismiss={() => setShowExecutionsEvent(null)}>
      <IonList>
        {process.process_executions.map((processExecution: any) => <ProcessExecution
          processExecution={processExecution} key={processExecution.id} checklistActions={checklistActions}/>)}
      </IonList>
    </IonPopover>
    <IonPopover className='wide-popover' event={showProcessEvent} isOpen={!!showProcessEvent} onDidDismiss={() => setShowProcessEvent(null)}>
      <ProcessContent process={process} afterSave={() => setShowProcessEvent(null)} />
    </IonPopover>
    <IonPopover className='wide-popover' event={showAssignEvent} isOpen={!!showAssignEvent} onDidDismiss={() => setShowAssignEvent(null)}>
      <AssignTo checklistActions={checklistActions} afterAssign={() => setShowAssignEvent(null)} />
    </IonPopover>

  </IonItem>
}


const groupBy = <T, K extends keyof any>(arr: T[], key: (i: T) => K) =>
  arr.reduce((groups, item) => {
    (groups[key(item)] ||= []).push(item);
    return groups;
  }, {} as Record<K, T[]>);


const AssignTo = ({checklistActions, afterAssign = () => {}}: any) => {
  const [userId, setUserId] = useState<null|number>(null)

  const {loading, data} = useSubscriptionWithElevatedRole(
    gql`
      subscription Users {
        users {
          id
          name
        }

      }
    `, {}
  )
  const myName = useUsername()
  useEffect(() => {
    if (data?.users && !userId && myName) {  // Set my user by default
      const myId = data.users.find(({name}: any) => name == myName)?.id
      if (myId) {
        setUserId(myId)
      }
    }
  }, [data, myName])
  if (loading || !data || !myName) {
    return <IonProgressBar type="indeterminate"/>
  }

  return <>
    <IonItem>
      <IonSelect value={userId} onIonChange={({detail: {value}}: any) => setUserId(value)} placeholder="Pick assignee" slot="start" style={{maxWidth: "50%"}}>
        {data?.users?.map(({id, name}: any) => <IonSelectOption value={id} key={id}>{name}</IonSelectOption>)}
      </IonSelect>
      <IonButton onClick={() => {checklistActions.assignTodo(userId); afterAssign()}} slot="end" disabled={!userId} fill="outline">
        <IonIcon icon={personAdd} slot="start"/>
        Add todo
      </IonButton>
    </IonItem>
  </>

}


const Todo = ({todo, checklistActions}: any) => {
  return <IonItem color="danger">
    <IonLabel>
      TODO @{todo.assigned_to?.name || "No assignee"}
    </IonLabel>
    <IonButton slot="end" onClick={() => checklistActions.setAsDone(todo.id)} color="light">
      <IonIcon icon={checkmarkCircle} slot="start"/>
      Set as done
    </IonButton>
    <IonButton slot="end" fill="clear" onClick={() => checklistActions.deleteTodo(todo.id)}>
      <IonIcon icon={trash} slot="icon-only"/>
    </IonButton>
  </IonItem>
}


const ExecutionsOverviewBadge = ({componentType, componentId, executions, setComponentId, todos, checklistActions}: any) => {
  const [showExecutionsEvent, setShowExecutionsEvent] = useState<any>(null)
  
  const color = (() => {
    if (todos) return "danger"  // 'todo' processes
    if (executions) return "success"  // 'done' processes
    return "medium"
  })()

  return <>
    <IonBadge color={color} onClick={setShowExecutionsEvent} style={{cursor: "pointer"}}>
      {componentId}
    </IonBadge>
    <IonPopover className='wide-popover' event={showExecutionsEvent} isOpen={!!showExecutionsEvent} onDidDismiss={() => setShowExecutionsEvent(null)}>
      <IonItemDivider style={{textTransform: "capitalize"}}>{componentType.replaceAll("_", " ")} {componentId}</IonItemDivider>

      {todos?.map((todo: any) => <Todo todo={todo} key={todo.id} checklistActions={checklistActions}/>)}

      {setComponentId && <IonButton expand="block" fill="clear" onClick={() => {setComponentId(componentId); setShowExecutionsEvent(null)}}>
        <IonIcon icon={list} slot="start"/>
        Show checklist
      </IonButton>}

      {!todos && <IonButton expand="block" fill="clear" onClick={() => checklistActions.setAsDone()}>
        <IonIcon icon={checkboxOutline} slot="start"/>
        Mark as done
      </IonButton>}

      <AssignTo checklistActions={checklistActions}/>

      <IonList>
        {executions?.map((processExecution: any) => <ProcessExecution
          processExecution={processExecution} checklistActions={checklistActions} key={processExecution.id}
        />)}

      </IonList>
    </IonPopover>
  </>
}


const ChecklistOverviewItem = ({process, componentType, getChecklistActions, setComponentId, allComponentIds, showTable}: any) => {
  const [showProcessEvent, setShowProcessEvent] = useState<any>(null)

  if (!allComponentIds) {
    return <IonProgressBar type="indeterminate"/>
  }
  const executionsPerId = groupBy(process.process_executions, (e: any) => e[`${componentType}_id`])
  const todosPerId = groupBy(process.todos, (t: any) => t[`${componentType}_id`])

  return <IonItem style={{display: "flex", marginRight: -50, marginTop: 3}}>
    <IonLabel style={{whiteSpace: "pre-wrap"}} onClick={setShowProcessEvent}>
      {process.description}
    </IonLabel>
    <IonButton size="small" fill="clear" onClick={setShowProcessEvent}>
      <IonIcon icon={pencilSharp}/>
    </IonButton>

    {showTable && <div style={{width: "50%"}}>
      {allComponentIds?.map((id: number) => <ExecutionsOverviewBadge
        checklistActions={getChecklistActions(process.id, componentType, id)}
        executions={executionsPerId[id]} todos={todosPerId[id]} key={id}
        componentId={id} setComponentId={setComponentId} componentType={componentType}
      />)}
    </div>}

    <IonPopover className='wide-popover' event={showProcessEvent} isOpen={!!showProcessEvent} onDidDismiss={() => setShowProcessEvent(null)}>
      <ProcessContent process={process} afterSave={() => setShowProcessEvent(null)} />
    </IonPopover>
  </IonItem>
}


const NewChecklistItem = ({componentType, componentId = null, processGroupName, processGroupId}: any) => {
  const [description, setDescription] = useState("")
  const username = getUsername()  // Saved in updates
  const [insertProcess] = useMutationWithElevatedRole(
    gql`
      mutation InsertProcess($object: processes_insert_input!) {
        insert_processes_one(object: $object) {
          id
        }
      }
    `, {})
  const { t } = useTranslation()

  const addProcess = (setAsDone: boolean = false) => {
    const object: any = {
      description: description,
      component_type: componentType,
      process_group_id: processGroupId,
    }
    if (setAsDone && componentId) {
      object["process_executions"] = {data: {
        author_name: username,
        [`${componentType}_id`]: componentId,
      }}
    }
    insertProcess({variables: {object}})
    setDescription("")
  }

  const placeholder = processGroupName ? t("Add new item to '{{processGroupName}}'", {processGroupName}): t("Add new item here")
  return <IonItem>
    <IonInput placeholder={placeholder} onIonChange={({detail: {value}}: any) => setDescription(value)} value={description} />

    {description.length > 0 && <>
      <IonIcon slot="end" icon={addCircle} style={{cursor: "pointer"}} onClick={() => addProcess(false)} />
      {componentId && <IonIcon slot="end" icon={squareOutline} style={{cursor: "pointer"}} onClick={() => addProcess(true)} />}
    </>}
  </IonItem>
}


export const ChecklistForComponent = ({componentType, setComponentId, componentId = null, showTable, getChecklistActions, processGroups, allowAddingItem = true, prefix}: any) => {
  // When componentId is null: show overview

  const processExecutionsWhere = componentId === null ? {} : {
    [`${componentType}_id`]: {_eq: componentId}  // Filter by component if componentId is not provided
  }
  // Same filter as for executions but also disregard todos that have been closed
  const processTodosWhere = {...processExecutionsWhere, closed_by_process_execution_id: {_is_null: true}}

  // Get ids for component
  const { data, loading, } = useSubscription(
    gql`
      subscription Checklist($componentType: String!, $processExecutionsWhere: process_executions_bool_exp!, $processTodosWhere: process_todos_bool_exp!, $processGroups: [Int]) {
        processes(where: {component_type: {_eq: $componentType}, archived: {_eq: false}, process_group_id: {_in: $processGroups}}, order_by: {id: asc}) {
          id
          created_at
          updated_at

          description

          interval_s
          duration_s
          remote

          process_group_id
          group {
            id
            description
          }

          process_executions(where: $processExecutionsWhere, order_by: {id: desc}) {
            id created_at author_name ${componentType}_id 
          }
        
          todos(where: $processTodosWhere, order_by: {id: desc}) {
            id created_at ${componentType}_id assigned_to {id name} 
          }

        }
      }
    `, {variables: {
      componentType, processExecutionsWhere, processTodosWhere, processGroups
    }}
  )
  const groups = useProcessGroups()?.data?.process_groups
  const processesByGroup = groupBy(data?.processes || [], (p: any) => p.process_group_id || "")
  const groupIds = Object.keys(processesByGroup).sort()
  const groupDescriptionById = Object.fromEntries(groups?.map(({id, description}: any) => [`${id}`, description]) || [])

  const allComponentIds = useComponentIds(componentType)

  if (loading || allComponentIds === null) {
    return <IonProgressBar type="indeterminate"/>
  }
  
  if (componentId === null) {
    // No componentId picked: show overview
    return <IonList>
      {groupIds.map((groupId: string) => <React.Fragment key={groupId}>
        <IonItemDivider sticky color="medium">
          <IonLabel>
            {prefix}{groupDescriptionById[groupId] || ""}
          </IonLabel>
        </IonItemDivider>
        {processesByGroup[groupId].map((process: any) => <ChecklistOverviewItem process={process} key={process.id}
          getChecklistActions={getChecklistActions}
          componentType={componentType} allComponentIds={allComponentIds} setComponentId={setComponentId} showTable={showTable}/>)}
      
        {allowAddingItem && <NewChecklistItem componentType={componentType} processGroupId={groupId} processGroupName={groupDescriptionById[groupId]} />}
      </React.Fragment>)}
    </IonList>
  }
  
  else {
    // Show checklist items for component
    return <>
      
      {groupIds.map((groupId: string) => <React.Fragment key={groupId}>
        <IonItemDivider sticky color="medium">
          <IonLabel>
            {prefix}{groupDescriptionById[groupId] || ""}
          </IonLabel>
        </IonItemDivider>
        {processesByGroup[groupId].map((process: any) => <ChecklistItem process={process} key={process.id}
          checklistActions={getChecklistActions(process.id, componentType, componentId)}
        />)}
        {allowAddingItem && <NewChecklistItem componentType={componentType} componentId={componentId} processGroupId={groupId} processGroupName={groupDescriptionById[groupId]} />}
      </React.Fragment>)}
    </>
  }
}


const Checklist = () => {
  const [componentType, setComponentType] = useState(ComponentType.Talpa)
  const [processGroups, setProcessGroups] = useState([2, 3, 6, 8])  // Most common groups
  const [view, setView] = useState<number|string>("list")

  const componentId = (["table", "list"] as any[]).includes(view) ? null : view

  const getChecklistActions = useGetChecklistActions()

  useEffect(() => { 
    setView("table")  // Set to table if type changed (previous ID is not valid anymore)
  }, [componentType])

  return <IonPage>
    <Toolbar name="Checklist" />

    <IonToolbar>
      <div style={{display: "flex", justifyContent: "flex-start", flexWrap: "wrap"}}>
        <IonItem lines="none" style={{maxWidth: 150}}>
          <ComponentTypeSelect value={componentType} onIonChange={({detail: {value}}: any) => setComponentType(value)}/>
        </IonItem>

        <IonItem style={{maxWidth: 250}} lines="none">
           <ComponentIdSelect componentType={componentType} componentId={view} setComponentId={setView}
            selectChildren={<>
              <IonSelectOption value={"list"}>Overview (list)</IonSelectOption>
              <IonSelectOption value={"table"}>Overview (table)</IonSelectOption>
            </>}/>
        </IonItem>

        <IonItem lines="none" style={{maxWidth: 600}}>
          <ProcessGroupSelect multiple={true}
            onIonChange={({detail: {value}}: any) => setProcessGroups(value)} value={processGroups}
          />
        </IonItem>

      </div>
    </IonToolbar>

    <IonContent fullscreen>
      {componentType && <ChecklistForComponent componentType={componentType} componentId={componentId} setComponentId={setView}
        getChecklistActions={getChecklistActions} showTable={view === "table"} processGroups={processGroups} />}
    </IonContent>
  </IonPage>
};
export default Checklist;