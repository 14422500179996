import { IonAlert, IonIcon, IonButton } from '@ionic/react';
import React, {useState} from 'react';
import { add } from 'ionicons/icons';

const ConfirmButton: React.FC<any> = ({children, text, onClick, ...props}) => {
    const [show, setShow] = useState(false)

    return <><IonAlert
          isOpen={show}
          onDidDismiss={() => setShow(false)}
          header={text}
          buttons={[
            {
              text: 'Cancel',
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => {
                  setShow(false)
              }
            },
            {
              text: 'OK',
              handler: () => {
                  onClick()
              }
            }
          ]}
        />
        <IonButton onClick={() => setShow(true)} {...props}>{children}</IonButton>
    </>
};

export default ConfirmButton;
