import { useQuery, gql } from '@apollo/client';
import React, { useState } from 'react';
import { IonList, IonButton, IonItem, IonLabel, IonBadge, IonIcon, IonChip, IonCard, IonModal, IonSkeletonText, } from '@ionic/react';
import { addCircle, arrowUndo, chevronDown, chevronUp, reader, trash } from 'ionicons/icons';
import CopyButton from './CopyButton';
import { SearchableSelect } from '../shared-components/SearchableSelect';
import ConfirmButton from '../shared-components/ConfirmButton';
import useWindowDimensions from '../useWindowDimensions';
import getMaxRole from './lib/getMaxRole';
import { useMutationWithElevatedRole, useQueryWithElevatedRole } from '../hooks/hasuraHooks';
import { TagPicker } from './TagPicker';
import { FilePaths } from './CopyFile';


export const useAddTagsMutation = () => useMutationWithElevatedRole(gql`
    mutation InsertTags($objects: [bag_tags_insert_input!] = []) {
      insert_bag_tags(objects: $objects) {
        returning {
          id
        }
      }
    }
`, {update: () => {}})


export const useUpdateBagMutation = () => useMutationWithElevatedRole(gql`
    ${BAG_FRAGMENT}
    mutation UpdateBag($id: Int!, $_set: bags_set_input = {}) {
      update_bags_by_pk(pk_columns: {id: $id}, _set: $_set) {
        ...BagFields
      }
    }
  `, {})


export const BAG_FRAGMENT = gql`
  fragment BagFields on bags {
    name
    file_paths {
      id
      path
      computer_name
      file_copy_operations {id}
    }
    id
    position
    archived
    started_recording_at
    taurus_id

    aquila_had_wide_view
    aquila_had_camera_type

    aquila_id
    bag_tags {
      id
      tag {id name}
    }
    plant_variety {
      name_EN
      id
    }
    evaluations(order_by: {id: desc}) {id}
    ground_truths(limit: 1, where: {archived: {_eq: false}}, order_by: {id: desc}) {
      id
    }
  }
`;


export const PlantVarietySelect: React.FC<any> = ({onChangePlantVariety, plant_variety_id, text}: any) => {
  const { loading, error, data} = useQueryWithElevatedRole(gql`
    query PlantVarieties {
      plant_varieties {
        id name_EN
      }
    }
  `, {});
 
  return <>
    {loading && <IonSkeletonText/>}
    {data && <SearchableSelect name={text || "Pick crop type"} value={plant_variety_id} onChange={onChangePlantVariety}
      options={data.plant_varieties.map((t: any) => [t.id, t.name_EN])} closeOnChange={true} />}
  </>
}


export const DateInfo = ({date}: any) => {
  const {isLargeScreen} = useWindowDimensions()
  if (!date) {return null}
  const d = new Date(date)
  const dateText = `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear() - 2000}`
  const timeText = ` ${d.getHours().toString().padStart(2, "0")}:${d.getMinutes().toString().padStart(2, "0")}`
 
  if (isLargeScreen) {
    // Show more info on large screens
    return <IonChip outline color="secondary">{dateText} {timeText}</IonChip>
  }
  else {
    return <IonBadge color="secondary">{dateText}</IonBadge>
  }
}


export const BagBadge: React.FC<any> = ({bag}: any) => {
  const {isLargeScreen} = useWindowDimensions()
  if (!bag) {return null}
  const cameraType = bag?.aquila_had_camera_type
  if (!isLargeScreen) {
    return <>
      <IonBadge>
        {bag?.taurus_id && <>GT{bag.taurus_id} </>}
        {bag?.aquila?.id && <>AQL{bag.aquila.id}</>}
      </IonBadge>
      <DateInfo date={bag.started_recording_at}/>
    </>
  }
  return <>
    {(bag?.taurus_id || bag?.aquila?.id || bag?.position) && <IonBadge>
      {bag?.taurus_id && <>GT{bag.taurus_id} </>}
      {bag?.aquila?.id && <>AQL{bag.aquila.id}</>}
      {bag?.position && <> {bag.position}</>}
    </IonBadge>}
    <DateInfo date={bag.started_recording_at}/>
    {cameraType?.includes("jai") && <IonBadge color="secondary">
      {cameraType}
      {bag?.aquila_had_wide_view && <>-wide</>}
    </IonBadge>}
    {bag?.plant_variety?.name_EN && <IonBadge color="secondary">{bag.plant_variety.name_EN}</IonBadge>}
    {bag.ground_truths?.length > 0 && <IonBadge>groundtruth</IonBadge>}
  </>
}


export const BagTags: React.FC<any> = ({bag, showTagPicker = true}: any) => {
  const [removeTag] = useMutationWithElevatedRole(gql`
    mutation RemoveTag($id: Int!) {
      delete_bag_tags_by_pk(id: $id) {id tag_id bag_id}
    }
  `, {})

  const [addTags] = useAddTagsMutation()
  const [updateBag] = useUpdateBagMutation()

  return <>
    {bag.bag_tags.map((bag_tag: any, i: number) => <ConfirmButton text={`Remove tag '${bag_tag.tag.name}'`} key={i} color="secondary" size="small" onClick={(e: any) => {
      removeTag({variables: {id: bag_tag.id}})
    }}>
      {bag_tag.tag.name}
    </ConfirmButton>)}
    {showTagPicker && <TagPicker name={"Tag"} onChange={(tag_id: number) => addTags({variables: {objects: [{bag_id: bag.id, tag_id: tag_id}]}})}
      skipTags={bag.bag_tags.map((t: any) => t.tag.id) /* Skip tags that are already set */} closeOnChange
    />}
    <ConfirmButton text={`${bag.archived ? "Restore" : "Archive"} bag`} size="small" fill="clear"
      onClick={() => updateBag({variables: {id: bag.id, _set: {archived: !bag.archived}}})}>
      <IonIcon icon={bag.archived ? arrowUndo: trash } slot="start"/>
      Bag
    </ConfirmButton>
  </>
}


export const BagItem: React.FC<any> = ({bag, AddEvaluation, EvaluationItemFromId}: any) => {
  const {isLargeScreen} = useWindowDimensions()
  const [expanded, setExpanded] = useState(false)
  const [updateBag] = useUpdateBagMutation()
  const [addTags] = useAddTagsMutation()
  const [addEvaluation, setAddEvaluation] = useState(false)
 
  const headerItem = <IonItem style={{fontWeight: expanded ? "bold": ""}}>
      <IonLabel onClick={() => setExpanded(!expanded)} style={{cursor: "pointer"}}>
        {bag.name}
      </IonLabel>
      {isLargeScreen && <BagTags bag={bag}/>}
      <BagBadge bag={bag}/>
      {bag?.evaluations?.length > 0 && <IonChip outline>{bag.evaluations.length}x <IonIcon icon={reader}/></IonChip>}
      <IonButton slot="end" onClick={() => setExpanded(!expanded)} color="medium" fill="clear">
        <IonIcon icon={expanded ? chevronUp : chevronDown} slot={"icon-only"}/>
      </IonButton>
  </IonItem>

  if (expanded) {
    const downloadCommand = `rosrun weeding_recording pull_bags.py ${bag.id}`
    const extractCommand = `rosrun weeding_recording prepare_annotation.py ${bag.id}`
    return <IonCard style={{margin: "15px"}}>
        <IonModal isOpen={addEvaluation !== false} onDidDismiss={() => setAddEvaluation(false)}>
          <AddEvaluation
            bags={[bag]}
            onClose={() => setAddEvaluation(false)}
          />
        </IonModal>
        {headerItem}
        <div style={{display: "flex"}}>
          <IonItem>
            <FilePaths file_paths={bag.file_paths}/>
            <CopyButton text={downloadCommand}>
              pull_bags.py
            </CopyButton>
            <CopyButton text={extractCommand}>
              prepare_annotation.py
            </CopyButton>
          </IonItem>
          <IonItem>
              <PlantVarietySelect plant_variety_id={bag?.plant_variety?.id}
                onChangePlantVariety={(plant_variety_id: number) => updateBag({variables: {id: bag.id, _set: {plant_variety_id: plant_variety_id}}})}/>
          </IonItem>
          <IonItem>
            <TagPicker name={"Add tag"} onChange={(tag_id: number) => addTags({variables: {objects: [{bag_id: bag.id, tag_id: tag_id}]}})}
              skipTags={bag.bag_tags.map((t: any) => t.tag.id) /* Skip tags that are already set */} closeOnChange
            />
          </IonItem>
        </div>
        <IonList style={{maxHeight: 300, overflowY: "auto"}}>
          {bag?.evaluations?.map(({id: evaluation_id}: any) => <EvaluationItemFromId key={evaluation_id} evaluation_id={evaluation_id} setAddEvaluation={setAddEvaluation} showBag={false}/>)}
        </IonList>
        <IonButton expand="block" fill="outline" color="secondary" onClick={() => setAddEvaluation(true)}><IonIcon icon={addCircle} slot="icon-only"/>Evaluation</IonButton>
    </IonCard>
  }
  else {
      return headerItem
  }
}


export const BagItemFromId: React.FC<any> = ({bag_id, ...props}: any) => {
  const { loading, error, data} = useQuery(gql`
    ${BAG_FRAGMENT}
    query GetBag($id: Int!) {
      bags_by_pk(id: $id) {
        ...BagFields
      }
    }
  `, {variables: {id: bag_id}, context: {headers: {"x-hasura-role": getMaxRole()}}});
  const bag = data?.bags_by_pk 

  return <>
    {loading && <IonSkeletonText/>}
    {bag && <BagItem bag={bag} {...props}/>}
  </>
}