import { IonSelect, IonTextarea, IonSelectOption, IonCard, IonCardHeader, IonCardContent, IonToggle, IonCardTitle, IonList, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonModal, IonButton, IonItem, IonLabel, IonInput } from '@ionic/react';
import React, {useState, useMemo} from 'react';
import { gql } from '@apollo/client';
import EditableCard from './EditableCard'
import Input, {yup, useYupValidationResolver, Toggle} from './Input'
import { useForm } from "react-hook-form";
import { goBackOrGoto } from '../history'
import ApolloStatus from '../components/ApolloStatus'
import './EventTypeForm.css'
import getMaxRole from './lib/getMaxRole';
import { useMutationWithElevatedRole, useQueryWithElevatedRole } from '../hooks/hasuraHooks';


const EventTypeForm: React.FC<any> = ({eventType, onFinish, refetchParent}) => {
  const defaultValues = {
      name: eventType.name,
      children: eventType?.children?.map((o: any) => o.id) || [],
      parents: eventType?.parents?.map((o: any) => o.id) || [],
      description: eventType.description || ""
  }

  const { control, errors, reset, handleSubmit, formState } = useForm({
      resolver: useYupValidationResolver(useMemo(() => yup.object({
        name: yup.string().required("Required"),
        description: yup.string(),
      }), [])),
      defaultValues: defaultValues
  })

  const { refetch, loading, error: fetchError, data } = useQueryWithElevatedRole(gql`query{
      eventTypes(sort:"name:asc") {id name}
  }`, {
    context: {
        headers: {
          "x-hasura-role": getMaxRole()
        }
      }
  });

  const [deleteEventType, deleteEventTypeApollo] = useMutationWithElevatedRole(gql`mutation {
      deleteEventType(input: {where: {id: ${eventType.id}}}) {eventType {id}}
  }`,{
    context: {
        headers: {
          "x-hasura-role": getMaxRole()
        }
      }
  })

  const [updateEventType, updateEventTypeApollo] = useMutationWithElevatedRole(gql`mutation
      updateEventType ($name: String, $description: String, $parents: [ID], $children: [ID]){updateEventType(input: {where: {id: ${eventType.id}}, data: {name: $name, children: $children, parents: $parents, description: $description}}) {
          eventType {id name description parents {id} children {id} }
      }}
  `,{
    context: {
        headers: {
          "x-hasura-role": getMaxRole()
        }
      }
  })

  // Catch errors on submits (errors are handled via updateEventTypeApollo)
  const onSubmit = handleSubmit((data: any) => {updateEventType({variables: data}).then(() => {onFinish()}).catch((e: any) => null)})


  return <IonModal onDidDismiss={() => onFinish()} isOpen={true}>
        <IonToolbar color="primary"><IonTitle>Edit event type</IonTitle>
            {!formState.isDirty && <IonButton color="light" slot="end" fill="clear" onClick={() => onFinish()}>cancel</IonButton>}
            {formState.isDirty && <IonButton slot="end" fill="clear" color="light" onClick={() => reset()}>Undo</IonButton>}
            {formState.isDirty && <IonButton slot="end" color="secondary" onClick={() => onSubmit()}>Save changes</IonButton>}
        </IonToolbar>
        <IonContent>
        <ApolloStatus loadings={[loading, updateEventTypeApollo.loading, deleteEventTypeApollo.loading]} errors={[fetchError, updateEventTypeApollo.error, deleteEventTypeApollo.error]} refetch={refetch}/>
        <form onSubmit={onSubmit}>
            <Input control={control} errors={errors} name="name"/>
            <Input control={control} errors={errors} name="children" label="causes" props={{multiple: true}} component={IonSelect}>
                {data?.eventTypes?.filter((e: any) => e.id !== eventType.id).map((eventType: any, index: number) => <IonSelectOption key={index} value={`${eventType.id}`}>{eventType.name}</IonSelectOption>)}
            </Input>
            <Input control={control} errors={errors} name="parents" label="consequences" props={{multiple: true}} component={IonSelect}>
                {data?.eventTypes?.filter((e: any) => e.id !== eventType.id).map((eventType: any, index: number) => <IonSelectOption key={index} value={`${eventType.id}`}>{eventType.name}</IonSelectOption>)}
            </Input>
            <Input control={control} errors={errors} name="description" component={EditableCard} showLabel={false} props={{label: "description"}}/>
            <input type="submit" style={{visibility: "hidden"}}/>
        </form>
        <IonButton onClick={() => {deleteEventType().then(() => {onFinish(); goBackOrGoto("/eventTypes")}).catch((e: any) => null)}} color="danger" expand="full">delete event type</IonButton>
        </IonContent>
    </IonModal>
}

export default EventTypeForm;
