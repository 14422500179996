import { IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonChip, IonLabel } from '@ionic/react';
import React, {useState} from 'react';
import Toolbar from '../components/Toolbar'

const ErrorCard: React.FC<any> = ({error}) => {
      const [show, setShow] = useState<boolean>(false)
      const clickable = (Object.keys(error).length > 0)

      const chip = error?.extraInfo ? <IonChip><IonLabel color="secondary">{error?.extraInfo}</IonLabel></IonChip> : null

      if (error.message === "Failed to fetch") {
          return <IonCard color="medium">
            <IonCardHeader>{chip}Offline mode (failed to fetch from server). Using cached content if available.</IonCardHeader>
        </IonCard>
      }
      return (
        <IonCard color="danger">
            <IonCardHeader
                style={{cursor: clickable ? "pointer" : ""}}
                onClick={() => {if (clickable) setShow(!show)}}>
                <IonCardTitle>{chip}Error: {error.message}</IonCardTitle>
            </IonCardHeader>
            {show && <IonCardContent>{`${JSON.stringify(error)}`}</IonCardContent>}
        </IonCard>
      );
};

export default ErrorCard;
