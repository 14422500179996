import { IonButton, IonDatetime, IonPopover, IonItemDivider, IonLabel, IonItem, IonIcon, } from '@ionic/react';
import React, { useState } from 'react';
import moment from "moment";
import { useTranslation } from 'react-i18next';
import { NumberInput } from '../shared-components/NumberInput';
import { t } from 'i18next';
import { calendar, } from 'ionicons/icons';


export const useDateRange = () => {
  const rangeType = "days"
  const [rangeAmount, setRangeAmount] = useState(2)
  const todayString = moment().endOf('day').format("YYYY-MM-DD")  // Use end of day for "now" (show all messages until now)
  const [endString, setEndString] = useState<string>(todayString)
  const endIsToday = todayString === endString
  const end = moment(endString)

  const createdWhere = (() => {
    if (endIsToday) {
      // Only check gte (until now)
      return {'created_at': {
        _gte: end.clone().subtract(rangeAmount - 1, rangeType).format(),
      }}
    }
    else {
      return {'created_at': {
        _gte: end.clone().subtract(rangeAmount - 1, rangeType).format(),
        _lte: end.add(1, rangeType).format(),
      }}
    }
  })()

  const description = (() => {
    if (endIsToday && rangeAmount === 1) {return t("Today")}
    else if (endIsToday && rangeAmount === 2) {return t("Today and yesterday")}
    else if (endIsToday) {
      return t("{{rangeAmount}} {{rangeType}} until today", {rangeAmount, rangeType: t(rangeType)})
    }
    else {
      return t("{{rangeAmount}} {{rangeType}} until {{endDate}}", {rangeAmount, rangeType: t(rangeType), endDate: endString})
    }
  })()

  return {
    endString, setEndString, rangeAmount, setRangeAmount, rangeType, createdWhere, description
  }
}


export const DateRangePicker: React.FC<any> = ({dateRange}: any) => {
  const {rangeAmount, setRangeAmount, rangeType, setEndString, endString, description} = dateRange
  const [event, setEvent] = useState<any>(null)
  const {t} = useTranslation()

  return <>
    <IonButton onClick={setEvent}>
      <IonIcon icon={calendar} slot="start"/>
      {description}
    </IonButton>
    <IonPopover isOpen={!!event} event={event} onDidDismiss={() => setEvent(null)}>
      <IonItemDivider>{t("Pick end of range")}</IonItemDivider>
      <IonDatetime id="datetime" presentation='date' value={endString} onIonChange={(e: any) => setEndString(e.detail.value)}/>
      <IonItemDivider>{t("Range")}</IonItemDivider>
      <IonItem>
        <IonLabel slot='end'>{t(rangeType)}</IonLabel>
        <NumberInput value={rangeAmount} onChange={setRangeAmount} min={1} max={200}/>
      </IonItem>
    </IonPopover>
  </>
}

