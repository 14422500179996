import {
  IonList,
  IonButton,
  IonContent,
  IonPage,
  IonItem,
  IonLabel,
  IonChip,
  IonCol,
  IonRow,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonAccordionGroup,
  IonAccordion,
  IonCardContent,
  IonItemDivider,
  IonToggle,
} from "@ionic/react";
import React, { useState } from "react";
import Toolbar from "../components/Toolbar";
import { gql, } from "@apollo/client";
import moment from "moment";
import { useMutationWithElevatedRole, useSubscriptionWithElevatedRole } from "../hooks/hasuraHooks";
import EditableField from "../components/EditableCard";
import { t } from "i18next";
import { GraphQLState } from "../components/GraphQLState";

const SoftwareDetail = ({ version }: any) => {

  const [updateSoftwareVersion] = useMutationWithElevatedRole(
    gql`
    mutation UpdateSoftwareVersion($id: Int!, $_set: software_versions_set_input!) {
      update_software_versions_by_pk(pk_columns: {id: $id}, _set: $_set) {
        id description developer_description
      }
    }
    `,
    {}
  );

  const { software_version_states } = version;
  const [description, setDescription] = useState(version.description)

  if (!version?.id) {
    return null;
  }

  const save = () => updateSoftwareVersion({
    variables: {
      id: version.id,
      _set: {
        description: description
      },
    },
  })

  const contentItem = <>
    {version.released_at === null && <IonButton disabled={!version.description} expand="block"
      onClick={() => updateSoftwareVersion({variables: {
        id: version.id, _set: {released_at: new Date().toISOString()}
      }})}
    >
      {t("Release this software version now")}
    </IonButton>}

    {version.released_at !== null && <IonButton expand="block" onClick={() => updateSoftwareVersion({variables: {
      id: version.id, _set: {released_at: null}
    }})}>
      {t("Revert software version from release to candidate")}
    </IonButton>}

    <IonButton expand="block" onClick={() => updateSoftwareVersion({variables: {
      id: version.id, _set: {archived: !version.archived}
    }})}>
      {version.archived ? t("Restore") : t("Archive")}
    </IonButton>

    <IonCard><IonCardContent style={{color: "black"}}>
      <EditableField value={description} onIonChange={(e: any) => setDescription(e.detail.value)}/>
    </IonCardContent></IonCard>

    {description !== version.description && <IonButton onClick={save}>Save</IonButton>}
    {description !== version.description && <IonButton onClick={() => setDescription(version.description)}>Reset</IonButton>}
    
    {version.changelog_dev && <IonCard><IonCardContent style={{color: "black"}}>
      <EditableField value={version.changelog_dev} label="Developer changelog"/>
    </IonCardContent></IonCard>}
    
    {version.repositories && <IonCard>
      <IonCardHeader><IonCardTitle>Repositories</IonCardTitle></IonCardHeader>
      <IonCardContent>
        <pre>
          {JSON.stringify(version.repositories, null, "  ")}
        </pre>
      </IonCardContent>
    </IonCard>}

    
    {software_version_states.length > 0 && <>
      <IonItemDivider>Has been running on:</IonItemDivider>
      <div style={{maxHeight: 500, overflowY: "auto"}}>
        {software_version_states.map((val: any, i: number): any => {
          const createDate = moment(val.created_at);
          return <IonCard>
            <IonCardHeader>
              <IonCardTitle>
                <IonRow>
                  <IonCol>GT{val.taurus_id}</IonCol>
                  {val.dirty && (
                    <IonChip
                      onClick={(e: any) => {}}
                      outline
                      color="secondary"
                    >
                      {t("with local changes")}
                    </IonChip>
                  )}
                </IonRow>
              </IonCardTitle>
            </IonCardHeader>
            <IonCardContent>{createDate.format("lll")}</IonCardContent>
          </IonCard>
        })}
      </div>
    </>}
  </>


  return (
    <IonAccordionGroup expand="inset" key={version?.id}>
      <IonAccordion value={version?.id}>
        <IonItem slot="header" color="light">
          <IonLabel style={{ fontWeight: 'bold' }}>
            {version.released_at ? <>v{version.released_at.split("+")[0].split(".")[0]}</> : <>{t("Not yet released")}</>}
            {" "}(created: {version.created_at.split("+")[0]})
          </IonLabel>
        </IonItem>
        <div className="ion-padding" slot="content">
          {contentItem}
        </div>
      </IonAccordion>
    </IonAccordionGroup>
  );
};

const SoftwareVersions: React.FC = () => {
  const [showArchived, setShowArchived] = useState(false)
  const where = showArchived ? {} : {archived: {_eq: false}}
  const { loading, data, error } = useSubscriptionWithElevatedRole(gql`
    subscription SoftwareVersions($where: software_versions_bool_exp!) {
      software_versions(order_by: { id: desc }, where: $where) {
        id
        archived
        description
        changelog_dev
        created_at
        released_at
        repositories

        software_version_states(order_by: { taurus_id: asc }) {
          id
          taurus_id
          robot_config_id
          created_at
          dirty
        }

      }
    }
  `, {variables: {where}});

  const software_versions = data?.software_versions;

  return (
    <IonPage>
      <Toolbar name="Software Versions">
        <IonItem color="transparent">
          <IonLabel position="stacked">{t("Show archived")}</IonLabel>
          <IonToggle checked={showArchived} onIonChange={({detail: {checked}}: any) => setShowArchived(checked)}/>
        </IonItem>
      </Toolbar>
      <IonContent fullscreen>
        <GraphQLState error={error} loading={loading}/>
        {data && (
          <>
            <IonList>
              {software_versions.map((version: any, index: number) => (
                <SoftwareDetail key={version.id} version={version} />
              ))}
            </IonList>
          </>
        )}
      </IonContent>
    </IonPage>
  );
};
export default SoftwareVersions;