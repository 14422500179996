import Aquilas from './pages/Aquilas'
import AboutMe from './pages/AboutMe'
import Fields from './pages/Fields'
import Teams from './pages/Teams'
import Fleets from './pages/Fleets'
import {videocamSharp, peopleSharp, keypadOutline, calendarSharp, personSharp, gitBranch, compass, imageSharp, bag, construct, pricetag, globeOutline, settings, sparkles, navigate, list, leaf, map, newspaper, shapes, triangleOutline, camera, flash, checkbox, checkmarkCircle, listCircle, checkboxOutline, wifi, listCircleSharp, car, flask, flaskOutline, book, copy, chatbox, } from 'ionicons/icons';
import talpas from './pages/Talpas'
import NTRIPAccounts from './pages/NTRIPAccounts'
import Evaluations from './pages/Evaluations'
import Bags from './pages/Bags'
import ParamConfigs from './pages/ParamConfigs'
import Tags from './pages/Tags'
import SoftwareVersions from './pages/SoftwareVersions'
import Rules from './pages/Rules'
import Demo from './pages/Demo'
import Configurator from './pages/Configurator'
import PlantVarieties from './pages/PlantVarieties'
import Scores from './pages/Scores'
import {TalpaVersion} from './pages/TalpaVersion'
import { Tools } from './pages/Tools'
import { Nozzles } from './pages/Nozzles'
import { Snapshots } from './pages/Snapshots'
import BagOnField from './pages/BagOnField'
import  SimCards from './pages/SimCards'
import Generators from './pages/Generators'
import Processes from './pages/Processes'
import Checklist from './pages/Checklist'
import Routers from './pages/Routers'
import ProcessGroups from './pages/ProcessGroups'
import { Tauruses } from './pages/Tauruses'
import { Ingredients } from './pages/Ingredients'
import { TimelinePage } from './pages/Timeline'
import { Replenishments } from './pages/Replenishments'
import { TranslationsPage } from './pages/Translations'
import { FileCopyOperations } from './pages/FileCopyOperations'
import { ChatGTPage } from './pages/ChatGT'


interface Page {
    name: string,
    component : React.FC,
    icon : string,
    route: string,
    roles: string[],
    requiresTeam : boolean
}

export const pages : Page[] = [
    {name: "fleets", component: Fleets, icon: keypadOutline, route: "/fleets", roles: ['keycloak-authenticated'], requiresTeam: true},
    {name: "teams", component: Teams, icon: peopleSharp, route: "/teams", roles: ['keycloak-authenticated'], requiresTeam: true},
    {name: "Timeline", component: TimelinePage, icon: calendarSharp, route: "/timeline", roles: ['farming-revolution-staff'], requiresTeam: true},
    {name: "Chat GT", component: ChatGTPage, icon: chatbox, route: "/chat-gt", roles: ['farming-revolution-staff'], requiresTeam: true},
    {name: "Snapshots", component: Snapshots, icon: camera, route: "/snapshots", roles: ['keycloak-authenticated'], requiresTeam: false},
    {name: "About me", component: AboutMe, icon: personSharp, route: "/about-me", roles: ['keycloak-authenticated'], requiresTeam: false},
    {name: "Aquilas", component: Aquilas, icon: videocamSharp, route: "/aquilas", roles: ['farming-revolution-staff'], requiresTeam: true},
    {name: "Tauruses", component: Tauruses, icon: car, route: "/tauruses", roles: ['farming-revolution-staff'], requiresTeam: true},
    {name: "Talpas", component: talpas, icon: gitBranch, route: "/talpas", roles: ['farming-revolution-staff'], requiresTeam: true},
    {name: "Talpa versions", component: TalpaVersion, icon: gitBranch, route: "/talpa_versions", roles: ['farming-revolution-staff'], requiresTeam: true},
    {name: "Tools", component: Tools, icon: shapes, route: "/tools", roles: ['farming-revolution-staff'], requiresTeam: true},
    {name: "Nozzles", component: Nozzles, icon: triangleOutline, route: "/nozzles", roles: ['farming-revolution-staff'], requiresTeam: true},
    {name: "NTRIP Accounts", component: NTRIPAccounts, icon: compass, route: "/ntrip_accounts", roles: ['farming-revolution-staff'], requiresTeam: true},
    {name: "Fields", component: Fields, icon: map, route: "/fields", roles: ['farming-revolution-staff'], requiresTeam: true},
    {name: "Evaluations", component: Evaluations, icon: imageSharp, route: "/evaluations", roles: ['farming-revolution-staff', 'annotator'], requiresTeam: false},
    {name: "Scores", component: Scores, icon: newspaper, route: "/scores", roles: ['farming-revolution-staff', 'annotator'], requiresTeam: false},
    {name: "Bags", component: Bags, icon: bag, route: "/bags", roles: ['farming-revolution-staff', 'annotator'], requiresTeam: false},
    {name: "Copy operations", component: FileCopyOperations, icon: copy, route: "/file-copy-operations", roles: ['farming-revolution-staff'], requiresTeam: false},
    {name: "BagOnField", component: BagOnField, icon: map, route: "/BagOnField", roles: ['farming-revolution-staff', 'annotator'], requiresTeam: false},
    {name: "Param Configs", component: ParamConfigs, icon: construct, route: "/param-configs", roles: ['farming-revolution-staff', 'annotator'], requiresTeam: false},
    {name: "Tags", component: Tags, icon: pricetag, route: "/tags", roles: ['farming-revolution-staff', 'annotator'], requiresTeam: false},
    {name: "Rules", component: Rules, icon: sparkles, route: "/rules", roles: ['farming-revolution-staff', 'annotator'], requiresTeam: false},
    {name: "Browse", component: Demo, icon: navigate, route: "/demo", roles: ['farming-revolution-staff'], requiresTeam: true},
    {name: "Plants", component: PlantVarieties, icon: leaf, route: "/plant-varieties", roles: ['farming-revolution-staff'], requiresTeam: true},
    {name: "Translations", component: TranslationsPage, icon: book, route: "/translations", roles: ['farming-revolution-staff'], requiresTeam: true},
    {name: "Configurator", component: Configurator, icon: list, route: "/configurator", roles: ['keycloak-authenticated'], requiresTeam: false},
    {name: "Software Versions", component: SoftwareVersions, icon: settings, route: "/software-versions", roles: ['farming-revolution-staff', 'annotator', 'keycloak-authenticated'], requiresTeam: false},
    {name: "Sim Cards", component: SimCards, icon: globeOutline, route: "/simcards", roles: ['farming-revolution-staff'], requiresTeam: false},
    {name: "Generators", component: Generators, icon: flash, route: "/generators", roles: ['farming-revolution-staff'], requiresTeam: false},
    {name: "Routers", component: Routers, icon: wifi, route: "/routers", roles: ['farming-revolution-staff'], requiresTeam: false},
    {name: "Processes", component: Processes, icon: listCircle, route: "/processes", roles: ['farming-revolution-staff'], requiresTeam: false},
    {name: "Process groups", component: ProcessGroups, icon: listCircleSharp, route: "/process_groups", roles: ['farming-revolution-staff'], requiresTeam: false},
    {name: "Checklist", component: Checklist, icon: checkboxOutline, route: "/checklist", roles: ['farming-revolution-staff'], requiresTeam: false},
    {name: "Ingredients", component: Ingredients, icon: flask, route: "/ingredients", roles: ['farming-revolution-staff'], requiresTeam: false},
    {name: "Replenishments", component: Replenishments, icon: flaskOutline, route: "/replenishments", roles: ['farming-revolution-staff', 'keycloak-authenticated'], requiresTeam: true},
]

export const defaultRoute = "/fleets"