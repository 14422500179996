import { IonCard, IonContent, IonCardHeader, IonIcon, IonButton, IonToolbar } from '@ionic/react';
import React, {useState} from 'react';
import EventList from '../components/EventList'
import Filters from '../components/Filters'
import { caretDownSharp, caretUpSharp } from 'ionicons/icons';

const EventsWithFilter: React.FC<any> = ({usersFilterDefault = [], fieldsFilterDefault = [], robotsFilterDefault = [], aquilasFilterDefault = [], eventTypesFilterDefault = [], show}) => {
  const [showFilter, setShowFilter] = useState(false);
  const [robotsFilter, setRobotsFilter] = useState(robotsFilterDefault)
  const [aquilasFilter, setAquilasFilter] = useState(aquilasFilterDefault)
  const [eventTypesFilter, setEventTypesFilter] = useState(eventTypesFilterDefault)
  const [fieldsFilter, setFieldsFilter] = useState(fieldsFilterDefault)
  const [usersFilter, setUsersFilter] = useState(usersFilterDefault)

    const where: any = {}
    where["robot"] = robotsFilter
    where["aquila"] = aquilasFilter
    where["event_type"] = eventTypesFilter
    where["field"] = fieldsFilter
    where["user"] = usersFilter

  return (<>
        <EventList where={where} show={show}/>
    </>
  );
};

export default EventsWithFilter;
