import { IonLoading, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButton } from '@ionic/react';
import React, {useState} from 'react';
import { gql, ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import Error from '../components/Error'
import LoginCard from '../components/LoginCard'

const Login: React.FC<any> = ({setToken, graphqlUri}) => {
  const name = "farming revolution"

  const [loginError, setLoginError] = useState<any | null>(null);
  const [loginLoading, setLoginLoading] = useState<boolean>(false);

  // where is our keycloak?
  const params = new URLSearchParams(window.location.search);
  const sso_base_uri = (
    (params.get('production') !== "false" || process.env.NODE_ENV !== "development")
    ? 'https://sso.farming-revolution.com/'
    : 'http://localhost:8080/'
  )
  
  const register_uri = `${sso_base_uri}auth/realms/FarmingRevolution/protocol/openid-connect/registrations?client_id=account&response_type=code&scope=email&kc_locale=en?redirect_uri=${window.location.origin}/robots`;

  const login = async (data : any) => {
    setLoginLoading(true);
    try {
      // ------ authenticate to keycloak ------
      
      const keycloak_response = await fetch(sso_base_uri + "auth/realms/FarmingRevolution/protocol/openid-connect/token", {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        method: "POST",
        body: `client_id=jwt&grant_type=password&scope=openid&username=${encodeURIComponent(data.variables.username)}&password=${encodeURIComponent(data.variables.password)}`,
      });
      const keycloak_json = await keycloak_response.json();
      if (keycloak_json.id_token === undefined) {
        if (keycloak_json.error_description) {
          throw keycloak_json.error_description
        } else {
          throw "Error authenticating to Keycloak"
        }
      }
      
      // ------ make sure user exists on postgres/hasura ------
      const httpLink = createHttpLink({
        uri: graphqlUri,
        headers: {"Authorization": `Bearer ${keycloak_json.id_token}`}
      });
      const client = new ApolloClient({
        "link": httpLink,
        "cache": new InMemoryCache()
      });
      const mutation = gql`mutation InsertSelf {
        insert_users_one(object: {})
          {
            id
            name
          }
      }`;
      await client.mutate({mutation, "errorPolicy": "ignore"})

      // ------ proceed ------
      setToken(keycloak_json.id_token);
    } catch(e) {
      setLoginError({graphQLErrors : [{message: `${e}`}]});
    } finally {
      setLoginLoading(false);
    }
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{name}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
          <IonLoading
            isOpen={ loginLoading }
            message={'Please wait...'}
          />
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{name}</IonTitle>
          </IonToolbar>
        </IonHeader>

        {loginError && <Error error={loginError}/>}

        <LoginCard login={(data: any) => login(data).catch(console.error)}/>

        <IonButton fill="clear" color="secondary" expand="full" href={register_uri}>Register</IonButton>

      </IonContent>
    </IonPage>
  );
};

export default Login;
