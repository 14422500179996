import { IonContent, IonPage, } from '@ionic/react';
import React, {useState} from 'react';
import { useParams } from 'react-router';
import { useQuery, gql } from '@apollo/client';
import Loading from '../components/Loading'
import Error from '../components/Error'
import Toolbar from '../components/Toolbar'

const User: React.FC = () => {
  const { id, name } = useParams<{ id: string; name: string}>();
  const { loading, error, data, refetch } = useQuery(gql`query{user(id:"${id}"){id username description}}`);
  return (
    <IonPage>
      <Toolbar name={data?.user?.name ? data.user.username : "User"}/>
      <IonContent fullscreen>
        <Loading loading={loading}/>
        <Error error={error}/>
      </IonContent>
    </IonPage>
  );
};

export default User;
