import { IonList, IonLabel, IonCard, IonItem, IonContent, IonPage, IonSelect, IonModal, IonRow, IonCol, IonSelectOption, IonCardHeader, IonCardSubtitle, IonCardContent, IonBadge, IonChip, IonIcon, IonButton, IonToolbar, IonItemDivider, IonCardTitle, IonListHeader, IonItemGroup, IonPopover } from '@ionic/react';
import React, {useState} from 'react';
import { Group, Stage, Layer } from "react-konva";
import { useQuery, useMutation, gql } from '@apollo/client';
import ApolloStatus from '../components/ApolloStatus'
import useTeams from '../hooks/useTeams'
import Toolbar from '../components/Toolbar'
import FleetForm from '../components/FleetForm'
import useWindowDimensions from '../useWindowDimensions'
import history from '../history'
import { close, informationCircle } from 'ionicons/icons';
import './Fleets.css'
import { Axle, RobotConfigs, RobotArrow } from '../shared-components/KonvaRobot';
import { useTranslation } from 'react-i18next';
import { RobotConfigChecklistModal } from './RobotConfigChecklist';
import ReactMarkdown from 'react-markdown';


const SoftwareVersionBadge: React.FC<any> = ({softwareVersionState, lastSoftwareVersion}: any) => {
  const [event, setEvent] = useState<any>(null)
  const { t } = useTranslation();
  if (!softwareVersionState) {return null}
  if (!softwareVersionState.software_version.released_at) {return null}  // Skip: not released yet
  const color = (() => {
    if (softwareVersionState.dirty) {return "danger"}
    if (softwareVersionState.software_version.id != lastSoftwareVersion?.id) {return "light"}
    return "primary"
  })()
  return <>
    {softwareVersionState && <IonBadge onClick={(e) => {e.stopPropagation(); setEvent(e)}} color={color}>
      <IonLabel>
        v{softwareVersionState.software_version.released_at.split("T")[0]}{" "}
        {softwareVersionState.dirty && <>*</>}
      </IonLabel>
    </IonBadge>}
 
    <IonPopover isOpen={!!event} onDidDismiss={(e) => {e.stopPropagation(); setEvent(false)}} className="wide-popover">
      <IonContent>
        <IonItem>
          <strong>v{softwareVersionState.software_version.released_at.split("T")[0]}{" "}
          {softwareVersionState.dirty && <> {t("with local changes")}</>}</strong>
          <IonButton slot="end" onClick={(e) => {e.stopPropagation(); setEvent(null)}}>
            <IonIcon icon={close} slot="icon-only" />
          </IonButton>
        </IonItem>
        {lastSoftwareVersion && <IonItem lines="none">
          <IonIcon slot="start" icon={informationCircle}/>
          {t("New software version available: {{versionName}}", {versionName: `v${lastSoftwareVersion.released_at.split("T")[0]}`})}
        </IonItem>}
        <div style={{ paddingLeft: 20 }}>
          <ReactMarkdown>
            {softwareVersionState.software_version.description}
          </ReactMarkdown>
        </div>
      </IonContent>
    </IonPopover>
  </>
}


const RobotConfig: React.FC<any> = ({robot_config, isLargeScreen, lastSoftwareVersion}: any) => {
  const standaloneAquila = robot_config.aquila_configs.find(({standalone}: any) => standalone)
  const softwareVersionState = robot_config.software_version_states[0]

  const trackWidth = robot_config?.taurus_configs[0]?.track_width
  const wheelBase = robot_config?.taurus_configs[0]?.wheel_base
  const wheelRadius = 60
  const wheelWidth = 40
  const axleWidth = 15

  const name = standaloneAquila ? `AQL${standaloneAquila.aquila_id} (standalone)`: `GT${robot_config?.taurus_configs[0]?.taurus_id}`
  const scale = 0.15
    
  const onClick = () => {
      history.push(`/robot/${robot_config.id}`)
  }
 
  return <IonCard style={{
    height: "fit-content", width: "100%", cursor: "pointer", paddingRight: 10,
    display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between"
  }}
    onClick={onClick}
  >
    <IonCardHeader style={{padding: 8}}>
      <IonCardTitle style={{fontWeight: "bolder", textDecoration: "underline"}} onClick={onClick}>
        {name}
      </IonCardTitle>
      <IonCardSubtitle style={{display: "flex", alignItems: "center", marginTop: 0}}>
        <SoftwareVersionBadge softwareVersionState={softwareVersionState} lastSoftwareVersion={lastSoftwareVersion}/>

        {robot_config?.taurus_configs[0]?.has_pfeifer_u_langen_box && (
          <IonBadge color="secondary">
            P&L
          </IonBadge>
        )}

        {isLargeScreen && robot_config.taurus_configs[0]?.track_width && <IonBadge color="medium">
          <IonLabel>{robot_config.taurus_configs[0].track_width} m</IonLabel>
        </IonBadge>}
        <RobotConfigChecklistModal robotConfigId={robot_config.id} size="small" fill="clear" style={{padding: 0}}>
          Checklist
        </RobotConfigChecklistModal>
      </IonCardSubtitle>
    </IonCardHeader>
    <Stage
      width={(wheelBase * 150 + 300) * scale}
      height={(trackWidth * 150 + 100) * scale}
      style={{height: "fit-content", border: "solid 1px lightgray" }}
    >
      <Layer scaleX={scale} scaleY={scale}>
        <Group x={100} y={trackWidth * 100}>
          <Axle
            trackWidth={trackWidth * 150}
            axleWidth={axleWidth}
            wheelRadius={wheelRadius}
            wheelWidth={wheelWidth}
          />
          <RobotConfigs
            x={wheelBase * 150}
            robotConfig={robot_config}
            trackWidth={trackWidth * 150}
            axleWidth={axleWidth}
            magnify={1.5}
          />
          <Axle
            trackWidth={trackWidth * 150}
            axleWidth={axleWidth}
            wheelRadius={wheelRadius}
            wheelWidth={wheelWidth}
            x={wheelBase * 150}
          />
        </Group>
      </Layer>
    </Stage>

    </IonCard>
}


export const RobotConfigFields = gql`
  fragment RobotConfigFields on robot_configs {
    id
    fleet_id
    generator_id
    taurus_configs {
      taurus_id
      track_width
      wheel_base
      has_pfeifer_u_langen_box
      taurus {
        id
        taurus_hardware_version {
          battery_type
          has_generator_control
          pcu_version
          id
          release_date
        }
      }
    }
    aquila_configs(order_by: {offset_y: asc}){
      standalone
      aquila_id
      offset_y
      height
    }
    talpa_configs(order_by: {offset_y: asc}){
      talpa_id
      offset_y
    }
    taurusbox_configs {
      taurusbox_id
    }
    row_configs(order_by: {offset_y: asc}) {
      offset_y
    }
    description
    software_version_states(limit: 1, order_by: {id: desc}) {
      id dirty software_version {
        id released_at description developer_description
      }
    }
  }
`

const Fleets: React.FC = () => {
  const { teams } = useTeams()
  const {isLargeScreen} = useWindowDimensions()

  const { refetch, updateQuery, data, error, loading } = useQuery(gql`
    ${RobotConfigFields}
    {
      fleets(order_by: {updated_at: desc}) {
        id
        name
        description
        tauruses(order_by: {id: asc}) {
          taurus_configs(order_by: {id: desc}, limit: 1) {
            robot_config {
              ...RobotConfigFields
            }
          }
        }
        aquilas(order_by: {id: asc}) {
          aquila_configs(order_by: {id: desc}, limit: 1) {
            standalone
            robot_config {
              ...RobotConfigFields
            }
          }
        }
      }
      software_versions(limit: 1, order_by: {id: desc}, where: {released_at: {_is_null: false}}) {
        id released_at
      }
    }`, {notifyOnNetworkStatusChange: true});
    
    const lastSoftwareVersion = data?.software_versions?.pop()

  const [createFleet, createFleetApollo] = useMutation(gql`mutation createFleet($team_id: Int, $name: String) {
    insert_fleets_one(object: {
      teams: {data: {team_id: $team_id}},
      name: $name
    }) {
      id
      name
      robots {
        id
      }
      teams {
        id
        role
        team {
          id
        }
      }
    }
  }`, {"context": {"headers": {"X-Hasura-Role": "admin"}}})

  const [createTeamFleetRelation, createTeamFleetRelationApollo] = useMutation(gql`
  mutation createTeamFleetRelation($team_id: Int!, $fleet_id: Int!, $role: String) {
    insert_team_fleet_relations_one(object: {team_id: $team_id, role: $role, fleet_id: $fleet_id}) {
      id
      role
      team {
        id
      }
    }
  }`, {"context": {"headers": {"X-Hasura-Role": "admin"}}})
  const [edit, setEdit] = useState(null)
  const { t } = useTranslation();

  const createFleetClean = async (team: any) => {
    // create fleet with the team's name
    let _fleet = await createFleet({"variables": {"team_id": team.id, "name": `Fleet ${team.name}`}})
    // TODO: investigate what the below code was supposed to do
    // createTeamFleetRelation({variables: {team: team.id, role: "owner"}})
    //   .then(d => createFleet({variables: {teams: [d.data.createTeamFleetRelation.teamFleetRelation.id], name: `Fleet ${team.name}`}}))
    //   .then((createFieldData: any) => updateQuery((prev: any) => ({fleets: [...prev.fleets, createFieldData.data.createFleet.fleet]})))
    //   .catch(e => console.error(e))
  }

  // Only keep fleets with aquilas or tauruses
  const fleets = data?.fleets?.filter(({aquilas, tauruses}: any) => aquilas.map(({standalone}: any) => standalone).length > 0 || tauruses.length > 0)

  return (
    <IonPage>
      <Toolbar name="Fleets"/>
      <IonContent fullscreen>
         <ApolloStatus refetch={refetch} loadings={[loading]} errors={[error, createTeamFleetRelationApollo.error, createFleetApollo.error]}/>
            {fleets?.map((fleet: any) => <IonItemGroup key={fleet.id}>
              <IonItemDivider sticky color="light">
                <IonLabel>
                  {fleet.name || t("Unnamed fleet")}
                </IonLabel>
              </IonItemDivider>
              
              <div style={{display: "flex", flexWrap: "wrap", flexDirection: "row"}}>
                {fleet?.tauruses?.map(({taurus_configs}: any) => taurus_configs?.map(({robot_config}: any) => <RobotConfig
                  robot_config={robot_config} key={robot_config.id} isLargeScreen={isLargeScreen} lastSoftwareVersion={lastSoftwareVersion} />))}
                {/* Show standalone aquilas */}
                {fleet?.aquilas?.map(({aquila_configs}: any) => aquila_configs?.filter(({standalone}: any) => standalone)?.map(({robot_config}: any) => <RobotConfig
                  robot_config={robot_config} key={robot_config.id} isLargeScreen={isLargeScreen} lastSoftwareVersion={lastSoftwareVersion} />))}
              </div>
              {fleet && edit === fleet.id && <FleetForm updateFleetsQuery={updateQuery} onFinish={() => setEdit(null)} fleet={fleet}
                createTeamFleetRelation={createTeamFleetRelation} otherFleets={data?.fleets}/>}

            </IonItemGroup>)}
            {false && <IonItem>
              <IonLabel>{t("Add fleet")}</IonLabel>
              <IonSelect onIonChange={(e: any) => createFleetClean(e.detail.value)}>
                {teams?.map((t: any) => <IonSelectOption key={t.id} value={t}>{t("For")} {t.name}</IonSelectOption>)}
              </IonSelect>
            </IonItem>}

          </IonContent>
      </IonPage>
  );
};

export default Fleets;
