import { IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCheckbox, IonCol, IonContent, IonFab, IonFabButton, IonFabList, IonFooter, IonIcon, IonInput, IonItem, IonLabel, IonList, IonModal, IonPage, IonPopover, IonRow, IonSelect, IonSelectOption, IonTitle, IonToggle, IonToolbar } from '@ionic/react';
import { add, checkmarkCircle, save, triangleOutline, settingsOutline, trashOutline, close, list, informationCircle } from 'ionicons/icons';

import { gql, useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import ApolloStatus from '../components/ApolloStatus';
import Connect from '../components/Connect';
import { validateConfig } from '../components/lib/validateConfig';
import Loading from '../components/Loading';
import { MiniatureShape } from '../components/MinitatureShape';
import { TalpaItem } from '../components/TalpaItem';
import Toolbar from '../components/Toolbar';
import { Aquila, AquilaConfig, RobotConfig, RowConfig, Talpa, TalpaTool, TALPA_FRAGMENT, useRobot, SensorType, SprayerConfig, LaserConfig, SimCard, TaurusboxConfig, TreatmentDirection } from '../hooks/useRobot';
import Message from '../shared-components/Message';
import useWindowDimensions from '../useWindowDimensions';
import { t } from 'i18next';
import { RobotConfigChecklistModal } from './RobotConfigChecklist';
import { GeneratorSelect } from './Generators';
import { CharacteristicTable } from '../shared-components/SprayerCalibrationMatrix';
const moment = require('moment-twitter');

const MAX_Y_OFFSET = 150;
const TODO_OFFSET_LIMITS = 100;

// constants for rendering
const BOX_HEIGHT = 50;
const SIZE = 0.75*BOX_HEIGHT;
const PIXELS_PER_CM = 3;

const LAST_POSITION_QUERY = gql(`
query LastRobotGpsPosition($taurus_id : Int!) {
  last_gt_position(taurus_id: $taurus_id) {
    map_link
    lat
    lon
    time
  }
}`)

function validateHex(h : string) : string {
  let out = "";
  for (let c of h.substring(0, 3)) {
    if ("0123456789abcdefABCDEF".includes(c)) {
      out += c.toUpperCase();
    }
  };
  return out
}

function formatMeter(x : number) : string {
  return x.toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2})
}

function formatHalfCm(x : number | undefined) : string {
  if (x === undefined) {
    return "";
  }
  return x.toLocaleString("en-US", {minimumFractionDigits: 1, maximumFractionDigits: 2})
}

function format_angle(angle? : number | null) {
  if (angle === undefined || angle === null || angle === 0.0) {
    return "0 °"
  }
  if (angle < 0.0) {
    return `${-angle} ° ${t("to the front")}`
  }
  return `${angle} ° ${t("to the back")}`
}

const cmp_by_y_offset = (a : any, b: any)=>a.offset_y==b.offset_y?0:(a.offset_y<b.offset_y?-1:1)

const OffsetInputField : React.FC<{getInitial : ()=>number, setValue : (val : number)=>void, min: number, max : number}> = ({getInitial, setValue, min, max}) => {
  const [rendered, setRendered] = useState("");
  const [val, setVal] = useState(0);
  useEffect(()=>{
    setRendered(getInitial().toFixed(2));
    setVal(getInitial())
  }, []);

  const update = useMemo(()=>function(input : string) {
    let filtered = Array.from(input)
      .filter(c=>"1234567890-.,".includes(c))
      .map(c=>c===','?'.':c)
      .join('');
    if (filtered.includes(".")) {
      if (!["0", "2", "5", "7", "00","50", "25", "75"].includes(filtered.split(".")[1])) {
        filtered = filtered.replace(/\..*/, ".");
      }
    }
    if (parseFloat(filtered) > max) {
      filtered = max.toFixed(2)
    } else if (parseFloat(filtered) < min) {
      filtered = min.toFixed(2)
    }
    
    setRendered(filtered);
    const new_val = parseFloat(filtered) || 0.0;
    setValue(new_val);
    setVal(new_val)
  
  }, [])

  return <input type="text" value={rendered} inputMode='numeric' pattern="^-?\d+[,.]?\d*$" style={{textAlign:"right", width: "2cm"}} onChange={e=>{
    const input : string = (e.target as any).value;
    update(input);
  }} 
  onKeyDown={e=>{
    if (e.key === 'ArrowUp') {
      update((val + 0.5).toFixed(2))
    } else if (e.key === 'ArrowDown') {
      update((val - 0.5).toFixed(2))
    }
  }}
  />
}

const TwoSidedHoeViz : React.FC<{tool_type : TalpaTool, rotation? : number, treatment_direction? : TreatmentDirection}> = ({tool_type, rotation, treatment_direction})=> {
  let rear_tool;
  let front_tool;
  let rear_rotation;
  let front_rotation;
  switch (treatment_direction) {
    case TreatmentDirection.INWARD:
    case undefined:
      rear_tool = tool_type.shape_left;
      front_tool = tool_type.shape_right;
      rear_rotation = rotation || 0;
      front_rotation = -(rotation || 0);
      break;
    case TreatmentDirection.OUTWARD_LEFT:
      rear_tool = tool_type.shape_right;
      front_tool = tool_type.shape_right;
      rear_rotation = -(rotation || 0);
      front_rotation = 0;
      break;
    case TreatmentDirection.OUTWARD_RIGHT:
      rear_tool = tool_type.shape_left;
      front_tool = tool_type.shape_left;
      rear_rotation = 0;
      front_rotation = rotation || 0;
      break;
  }
  return <span style={{display: 'flex', flexDirection: 'row', zIndex: 10, padding: "0 .5em"}}>
          <MiniatureShape height={SIZE} width={SIZE/2} points={rear_tool} style={{marginRight: "0.2em", transform: `rotate(${rear_rotation}deg)`}} />
          <MiniatureShape height={SIZE} width={SIZE/2} points={front_tool} style={{transform: `rotate(${front_rotation}deg)`}} />
        </span>
}
TwoSidedHoeViz.defaultProps = {
  rotation: 0.0,
  treatment_direction: TreatmentDirection.INWARD
}

const TalpaSprayerCalibrationOverview : React.FC<{talpa : Talpa, rowName : string}> = ({talpa, rowName}) => {
  const sprayer_calibrations = talpa.sprayer_calibrations;
  const [selected_idx, set_selected_idx] = useState(0);
  const selected_calibration = sprayer_calibrations[selected_idx] || undefined;
  const {t} = useTranslation();

  const has_next = selected_idx > 0 && (sprayer_calibrations.length >= 2);
  const has_previous = selected_idx < (sprayer_calibrations.length - 1);
  if (talpa.id === 154) {
    console.log({'talpa_id': talpa.id, selected_idx, has_next, has_previous, 'num_calibs': sprayer_calibrations.length})
  }

  return <IonItem>
    <div style={{display: 'flex', flexDirection: 'column', margin: '1em 0'}}>
      <strong>{rowName}, TLP{talpa.id}</strong>
      {selected_calibration && <div>
        <span>ID: {selected_calibration.id} | {new Date(selected_calibration.created_at).toLocaleString()}</span>
        <div style={{display: 'flex', flexDirection: 'row'}}>
          <button style={{width: '3em', marginRight: '1em'}} disabled={!has_previous} onClick={_=>set_selected_idx(selected_idx+1)}>&lt;</button>
          <CharacteristicTable 
            pressures_bar={selected_calibration.pressure_bar}
            opening_durations_ms={selected_calibration.opening_duration_ms}
            spot_masses_mg={selected_calibration.spot_mass_mg}
            overall_progress={null}
            current_step={null}
          />
          <button style={{width: '3em', marginLeft: '1em'}} disabled={!has_next} onClick={_=>set_selected_idx(selected_idx-1)}>&gt;</button>
        </div>
      </div>}
      {selected_calibration === undefined && <p>{t("not yet calibrated")}</p>}
    </div>
  </IonItem>
}

const EditSimCard = ({ current, set_current_config }: any) => {

  const { loading, error, data } = useQuery(gql`
    query GetSimCardList {
      sim_cards {
        id
        card_number
        expires_at
        fleet_id
        supplier
      }
    }
  `);

  const { sim_cards } = data || [];

  const [selectedSlot, setSelectedSlot] = useState <number | null>(null);
  const { t } = useTranslation()

  const setSim = (slot:number | null ,value: SimCard | null) => {
    setSelectedSlot(null);
    const tempCurrent = {...current}
    if(slot ==0){
      tempCurrent.taurusbox_configs[0].sim_card1_id = value ? value.id : null;
      tempCurrent.taurusbox_configs[0].sim_card1 = value;

    } else if(slot ==1){
      tempCurrent.taurusbox_configs[0].sim_card2_id = value ? value.id : null;
      tempCurrent.taurusbox_configs[0].sim_card2 = value;
    }
    set_current_config(tempCurrent);
  };

  if (current?.taurus_configs?.length < 1) {
    return null
  }
  const { sim_card1, sim_card2 } = current?.taurusbox_configs[0];
  const combined = [sim_card1, sim_card2];
  return (
    <>
      {combined.map((sim_card: SimCard | null, idx: number) => {
        return (
          <IonCard>
            {loading && <Loading />}
            <IonCardHeader
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <IonCardSubtitle>{t("Sim card on slot")} {idx + 1}</IonCardSubtitle>
              <IonItem lines="none">
                <IonButton fill="clear" onClick={() => setSelectedSlot(idx)}>
                  <IonIcon
                    slot="icon-only"
                    icon={sim_card ? settingsOutline : add}
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      fontSize: 20,
                      margin: 10,
                    }}
                  />
                </IonButton>
                {sim_card && <IonButton fill="clear" onClick={() => setSim(idx, null)}>
                  <IonIcon
                    icon={trashOutline} slot="icon-only"
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      fontSize: 20,
                      margin: 10,
                    }}
                  />
                </IonButton>}
              </IonItem>
            </IonCardHeader>
            <IonCardContent>
              <IonRow>
                {sim_card && (
                  <>
                    <IonCol>
                      <IonItem lines="none">
                        {t("Card Number")} : {sim_card?.card_number}
                      </IonItem>
                    </IonCol>
                    <IonCol>
                      <IonItem lines="none">
                        {t("Supplier")} : {sim_card?.supplier}
                      </IonItem>
                    </IonCol>
                    <IonCol>
                      {sim_card?.expires_at && <IonItem lines="none">
                        {t("Expires At")} : {sim_card?.expires_at}
                      </IonItem>}
                    </IonCol>
                  </>
                )}
              </IonRow>
            </IonCardContent>
          </IonCard>
        );
      })}
      <IonPopover className='wide-popover'
        isOpen={selectedSlot !== null}
        onDidDismiss={() => setSelectedSlot(null)}
      >
        <IonToolbar>
          <IonTitle>
            {t("Available Simcards")}
          </IonTitle>
          <IonButton fill="clear" slot="end" onClick={() => setSelectedSlot(null)}>
            <IonIcon icon={close} slot="icon-only"/>
          </IonButton>
        </IonToolbar>

        <IonList style={{overflow: "auto", maxHeight: "500px"}}>
          {sim_cards?.map((sim_card: SimCard) => {
            return (
              sim_card && (
                <IonItem
                  key={sim_card.id}
                  button={true}
                  detail={false}
                  onClick={() => setSim(selectedSlot, sim_card)}
                >
                  {`${sim_card.card_number} (${sim_card.supplier})`}
                </IonItem>
              )
            );
          })}
        </IonList>
      </IonPopover>
    </>
  );
};

function addBodyClass(cls : string) {
  document.body.classList.add(cls)
}
function removeBodyClass(cls : string) {
  document.body.classList.remove(cls)
}

const Robot: React.FC = () => {
  const { id, name } = useParams<{ id: string; name: string }>();

  const [saveConfig, saveConfigRes] = useMutation(gql(`
    mutation($in : RobotConfigUpdateInput!) {
      updateRobotConfig(in: $in) {
        new_robot_config_id
      }
    }
  `));
  const new_robot_config_url = saveConfigRes.data?.updateRobotConfig?`/robot/${saveConfigRes.data?.updateRobotConfig.new_robot_config_id}`:window.location;

  const {isLargeScreen} = useWindowDimensions()
  const {data: talpa_tools_data} = useQuery(gql(`
  {
    talpa_tools(where: {_and: [
      {developer_only: {_eq: false}},
    ]}) {
      id,
      talpa_hardware_version_id,
      shape_left,
      shape_right,
      description
    }
  }`),{});
  const talpa_tools : TalpaTool[] = talpa_tools_data?.talpa_tools;
  let { data, refetch, robotApollo } = useRobot(id);
  let [dirty, setDirty] = useState(false);

  let [current_config, set_current_config_orig] = useState<RobotConfig | null>(null);
  useEffect(()=>{
    if ((current_config === null) && data?.current_config) {
      set_current_config_orig(data.current_config);
    }
  }, [current_config, data]);

  const setGeneratorId = (id: number) => {
    const tempCurrent = {...current_config}
    tempCurrent.generator_id = id;
    set_current_config(tempCurrent);
  }

  let set_current_config = (arg:any)=>{
    setDirty(true);
    return set_current_config_orig(arg)
  };

  Object.assign(window, {set_current_config});

  const reset = async () => {
    const res = await refetch();
    setDirty(false);
    set_current_config_orig(res.data.current_config);
  }
  
  const taurus_id = current_config?.taurus_configs.find(_=>true)?.taurus_id;
  const {data: new_configs_exist_res} = useQuery(gql(`
    query ($config_id : Int!, $taurus_id : Int!) {
      robot_configs(
        where: {_and: [
          {id: {_gt: $config_id}},
          {taurus_configs: {taurus_id: {_eq: $taurus_id}}}
        ]},
        order_by: {id: desc},
        limit: 1
      ) {
        id, created_at
      }
    }  
  `), {variables: {config_id: id, taurus_id}, fetchPolicy: "no-cache"});
  const new_config_props = new_configs_exist_res?.robot_configs[0];
  
  const lastPosition = useQuery(LAST_POSITION_QUERY, {
    variables: {taurus_id}, skip: !taurus_id
  });

  Object.assign(window, {lastPosition, taurus_id})


  const [edit_aquila_modal_open, set_edit_aquila_modal_open] = useState(false);
  const [edit_aquila_slot_id, set_edit_aquila_slot_id] = useState("");
  const [edit_talpa_modal_open, set_edit_talpa_modal_open] = useState(false);
  const [edit_talpa_slot_id, set_edit_talpa_slot_id] = useState("");
  const [edit_row_modal_open, set_edit_row_modal_open] = useState(false);
  const [edit_row_idx, set_edit_row_idx] = useState(0);
  const [edit_sprayer_modal_open, set_edit_sprayer_modal_open] = useState(false);
  const [edit_sprayer_idx, set_edit_sprayer_idx] = useState(0);
  const [edit_laser_modal_open, set_edit_laser_modal_open] = useState(false);
  const [edit_laser_idx, set_edit_laser_idx] = useState(0);
  const [addFabIsActive, setAddFabIsActive] = useState(false);

  // The available_aquilas, available_talpas can be selected when adding a new component
  const aquila_ids = current_config?.aquila_configs?.map((a:any)=>a.aquila_id);
  const talpa_ids = current_config?.talpa_configs?.map((t:any)=>t.talpa_id);
  const available_aquilas : Aquila[] = useQuery(gql`{aquilas(order_by: {id: asc}) {id name}}`, { fetchPolicy: "no-cache"}).data?.aquilas.filter((c:any)=>aquila_ids?.indexOf(c.id)===-1);
  const available_talpas : Talpa[] = useQuery(gql`${TALPA_FRAGMENT} {talpas(order_by: {id: asc}) {...TalpaParts}}`, { fetchPolicy: "no-cache"}).data?.talpas.filter((c:any)=>talpa_ids?.indexOf(c.id)===-1);
  
  const [newOffsetYRow, setNewOffsetYRow] = useState<number>(0);
  const { t } = useTranslation()

  useEffect(()=>{
    if ((edit_aquila_modal_open || edit_talpa_modal_open) && addFabIsActive) {
      setAddFabIsActive(false);
    }
  }, [edit_aquila_modal_open, edit_talpa_modal_open, addFabIsActive]);

  if (current_config === null) {
    return <IonPage>
      <Toolbar name={`${t("loading robot")}...`} />
      <IonContent fullscreen>
        <Loading />
      </IonContent>
    </IonPage>
  }
  
  // ↓↓↓ Code regarding the graphical config editor ↓↓↓ -------------------------------------------------

  let configs : [
      number, // offset_y
      'aquila_configs' | 'talpa_configs' | 'row_configs' | 'sprayer_configs',
      any     // config
  ][] = [];

  let max_abs_offset = 0;
  if (current_config !== undefined) {
    for (let config_kind of ['aquila_configs', 'talpa_configs', 'row_configs', 'sprayer_configs', 'laser_configs']) {
      let current_configs = (current_config as any)[config_kind];
      for (let c of current_configs) {
          let o = Math.abs(c.offset_y);
          if (o > max_abs_offset) {
              max_abs_offset = o;
          }
          configs.push([c.offset_y, config_kind as any, c])
      }
    }
  }

  for (let entry of configs) {
    entry[0] += max_abs_offset;
    entry[0] *= PIXELS_PER_CM;
  }

  // force-based adjustment
  const inp = configs.map((c:any, i: number, a)=>{return {width: BOX_HEIGHT, x: c[0], target_x: c[0], type: c[1][0], slot_id: c[2]["slot_id"] || i/a.length}});
  let offsets = adjust_layout(inp)
  for (let i=0;i<offsets.length;i++) {
    configs[i][0] = offsets[i]
  }

  // Assign each aquila a color, so we can mark it and all connected talpas with that color
  // ATTENTION: if you modify these, also modify the corresponding classes in global.css
  const COLOR_PALETTE = ["f34213","e6af2e","474b24","a4031f","283044","177e89"];
  let aquila_colors : any = {};
  let idx = 0;
  if (current_config !== undefined) {
    for (let ac of current_config.aquila_configs) {
        aquila_colors[ac.slot_id] = COLOR_PALETTE[idx];
        idx += 1;
        idx %= COLOR_PALETTE.length;
    }
  }
  aquila_colors["none"] = "ffffff";

  const STYLES : any = {
    "aquila_configs":  {"left": "60%", "width": "35%",            "height": BOX_HEIGHT+'px', "marginTop": -BOX_HEIGHT/2+'px',          "background":"white",                                                   "zIndex":2, "display": "flex", "justifyContent": "space-evenly"},
    "talpa_configs":   {"left": "15%", "width": "35%",            "height": BOX_HEIGHT+'px', "marginTop": -BOX_HEIGHT/2+'px',          "background":"white",                                                   "zIndex":2, "display": "flex", "justifyContent": "space-evenly"},
    "row_configs":     {"left":  "0%", "width":"100%",            "height": '150px',          "marginTop": "-73px",     'backgroundSize': 'contain',  "backgroundImage": 'url("assets/realCrop.png")',  'backgroundPosition': 'center',  'backgroundRepeat': 'repeat-x', "zIndex":1},
    "sprayer_configs": {"left": "7.5%", "width": BOX_HEIGHT+'px',  "height": BOX_HEIGHT+'px',"marginTop": -BOX_HEIGHT/2+'px', marginLeft: -BOX_HEIGHT/2+'px', "background": 'white', "zIndex":2, borderRadius: BOX_HEIGHT/2, fontSize: '1.5em'},
    "laser_configs":   {"left": "7.5%", "width": BOX_HEIGHT+'px',  "height": BOX_HEIGHT+'px',"marginTop": -BOX_HEIGHT/2+'px', marginLeft: -BOX_HEIGHT/2+'px', "background": 'white', "zIndex":2, borderRadius: BOX_HEIGHT/2, fontSize: '1.5em'},
  }

  const small_layout = document.documentElement.clientWidth < 800;

  let open_edit_modal : any = {};

  const editing_aquila = current_config?.aquila_configs.find((c:any)=>c.slot_id === edit_aquila_slot_id);
  open_edit_modal["aquila_configs"] = (config:any)=>{
    set_edit_aquila_slot_id(config.slot_id);
    set_edit_aquila_modal_open(true);
  }   

  const editing_talpa = current_config?.talpa_configs.find((c:any)=>c.slot_id === edit_talpa_slot_id);
  open_edit_modal["talpa_configs"] = (config:any)=>{
      set_edit_talpa_slot_id(config.slot_id);
      set_edit_talpa_modal_open(true);
  }
  
  open_edit_modal["row_configs"] = (config : any)=>{
    let idx = config.idx;
    set_edit_row_idx(idx);
    set_edit_row_modal_open(true);
  }
  let row_idx = 0;
  
  open_edit_modal["sprayer_configs"] = (config : any)=>{
    let idx = config.idx;
    set_edit_sprayer_idx(idx);
    set_edit_sprayer_modal_open(true);
  }

  open_edit_modal["laser_configs"] = (config : any)=>{
    let idx = config.idx;
    set_edit_laser_idx(idx);
    set_edit_laser_modal_open(true);
  }

  let sprayer_idx = 0;
  let laser_idx = 0;

  current_config?.row_configs.sort(cmp_by_y_offset);
  current_config?.aquila_configs.sort(cmp_by_y_offset);
  
  for (let sc of current_config?.sprayer_configs) {
    sc.aquila_slot_id = sc.aquila_slot_id || current_config!.aquila_configs.find(ac=>ac.aquila_id===sc.connected_aquila_id)?.slot_id;
  }
  // validation logic
  let warnings = validateConfig(current_config);

  // for easier debugging
  Object.assign(window, {
    available_aquilas,
    available_talpas,
    current_config,
    editing_aquila,
    editing_talpa,
    edit_aquila_modal_open,
    edit_talpa_modal_open,
    edit_row_modal_open,
    talpa_tools,
    talpa_tools_data,
    saveConfigRes
  });

  function part_viz(type : string, config : any, position : string) {
    const FALLBACK = <div style={{width: SIZE+'px', height: SIZE+'px', }}></div>;
    
    if (talpa_tools === undefined) {
      return FALLBACK
    }

    if (type === 'talpa_configs') {
      if (!config.talpa) {
        return
      }
      const side = config.talpa?.side_of_row;
      let tt = talpa_tools.find((tt)=>tt.id===config.talpa_tool_id);
      if (!tt) {
        return FALLBACK;
      }
      if ((side === 'left' && position === 'rear')||(side === 'right' && position === 'front')) {
        return <MiniatureShape height={SIZE} width={SIZE} points={(tt as any)["shape_"+side]} />
      } else if (side === 'both' && position === 'rear') {
        return <TwoSidedHoeViz tool_type={tt} rotation={config.blade_turning_angle_pointing_backwards_deg} treatment_direction={config.treatment_direction} />
      }
    } else if (type === 'sprayer_configs') {
      if (position === 'front') {
        return <div style={{
          position: 'absolute',
          right: '-.25em',
          top: 'calc(50% - 0.25em)',
          height: '0.5em',
          width: '0.5em',
          borderRadius: '0.5em',
          backgroundColor: '#'+(aquila_colors[config.aquila_slot_id] || aquila_colors['none'])
        }} />
      } else {
        return undefined;
      }
    }
    if (isLargeScreen) {
      return FALLBACK;
    }
  }

  const show_set_all_button =
    current_config
    .talpa_configs
    .filter(tc=>tc.talpa.hardware_version.id===editing_talpa?.talpa.hardware_version.id)
    .map(tc=>
      (tc.talpa_tool_id!==editing_talpa?.talpa_tool_id)
      || (tc.blade_turning_angle_pointing_backwards_deg!==editing_talpa.blade_turning_angle_pointing_backwards_deg)
      || (tc.treatment_direction != editing_talpa.treatment_direction)
    )
    .includes(true);
    
  return <IonPage>
      <Toolbar buttons={
        <IonButtons>
        {dirty && <IonButton onClick={_=>{
          if (new_config_props !== undefined) {
            if (!window.confirm(t("You are editing based on an old config. Are you sure you want to continue?"))) {
              return;
            }
          }
          console.log("saving config", current_config)
          setDirty(false);
          saveConfig({'variables': {'in': {
            'old_robot_config_id': current_config?.id,
            'updated_config': current_config
          }}});
          }}>
            {t("Save")}&nbsp;<IonIcon icon={save} />
          </IonButton>}
        {!dirty && <IonIcon icon={checkmarkCircle} style={{display: 'flex', justifyContent: 'space-around'}} />}
        {dirty && <IonButton onClick={reset}>{t("Reset")}</IonButton>}
        </IonButtons>
      }>
        <IonTitle>{taurus_id ? `GT ${taurus_id}` : t("Config with no Taurus")}</IonTitle>
        
      </Toolbar>
      <IonContent fullscreen>
        <ApolloStatus
          loadings={[robotApollo.loading]}
          errors={[robotApollo.error]}
          refetch={refetch}
        />

        {new_config_props && <Message>
          {t("Robot config is obsolete")} <a href={`/robot/${new_config_props.id}/`}>{t("Go to config #{{configId}} from {{configCreatedAt}}", { configId: new_config_props.id, configCreatedAt: new_config_props.created_at})}</a>.
          <br/>
          
          <IonButton size="small" onClick={() => {
            setDirty(false);
            saveConfig({'variables': {'in': {
              'old_robot_config_id': current_config?.id,
              'updated_config': current_config
            }}})
          }}>
            {t("Revert to this config")}
          </IonButton>

        </Message>}

        {current_config && <>
          <div style={{
            'position': 'relative',
            'width': '100%',
            backgroundImage: 'url("assets/soil.png")',
            backgroundSize: 'contain',
            'height': 2*max_abs_offset*PIXELS_PER_CM + 2*BOX_HEIGHT + 15 + 'px',
            marginBottom: 30,
            }}>

              <div className="scroll-anchor" style={{position: "absolute", top: "-50px", left: 0}} />
              { configs.map(([offset_y, type, config], idx)=>{
                  let text : any = "";
                  let borderLeft = "none";
                  let borderRight = "none";
                  let color = "transparent";
                  if (type === "aquila_configs") {
                      text = `AQL ${config.aquila_id || "(none)"}${small_layout?'\n':': '} ${formatHalfCm(config.offset_y)} cm`;
                      color = aquila_colors[config.slot_id];
                      borderLeft = `10px solid #${color}`;
                  } else if (type === "talpa_configs") {
                      text = `TLP ${config.talpa_id || "(none)"}${small_layout?'\n':': '} ${formatHalfCm(config.offset_y)} cm`;
                      color = aquila_colors[config.aquila_slot_id || 'none'];
                      borderRight = `10px solid #${color}`;
                  } else if (type === "row_configs") {
                    config = {
                      "idx": row_idx,
                      ...config
                    }
                    row_idx += 1;
                  } else if (type === "sprayer_configs") {
                    config = {
                      "idx": sprayer_idx,
                      ...config
                    }
                    text = <IonIcon icon={triangleOutline}/>;
                    sprayer_idx += 1;
                  } else if (type === "laser_configs") {
                    config = {
                      "idx": laser_idx,
                      ...config
                    }
                    text = <img src="assets/laser.svg" alt='laser' style={{width: "60%", height: "60%", marginTop: "5%", marginLeft: "20%"}} />
                    laser_idx += 1;
                  }
                  color = color || "white";
                  return <div
                      key={`${idx}-${color}-${type}-${offset_y}`} /* color needed in key because otherwise react apparently fails to update this (only in style!) */
                      style={{
                        'position':'absolute',
                        'whiteSpace':'pre',
                        "fontWeight":"bold",
                        "borderRadius":"0.5em",
                        borderLeft,
                        borderRight,
                        'display':'flex',
                        'alignItems':'center',
                        'justifyContent':'center',
                        'cursor':'pointer',
                        'top': BOX_HEIGHT + offset_y+'px',
                        ...STYLES[type],
                      }}
                      onClick={_=>open_edit_modal[type](config)}
                      className={type.replace("_configs","")}
                  >{part_viz(type, config, 'rear')}<span>{text}</span>{part_viz(type, config, 'front')}</div>
              })
            }

            <IonFab slot="fixed" vertical="bottom" horizontal="end" activated={addFabIsActive}>
              <IonFabButton color="secondary" onClick={()=>setAddFabIsActive(true)}>
                <IonIcon icon={add}></IonIcon>
              </IonFabButton>
              <IonFabList side="bottom" style={{
                left: isLargeScreen?'calc(100% - 100vw)':'calc(100% + 10px + var(--ion-safe-area-right, 0px) - 100vw)',
                width: '100vw',
                alignItems: 'end',
              }}>
                <IonCard style={{maxWidth: "100%", margin: 0}}>
                  <IonCardContent>
                    <IonItem>
                      <IonLabel>{t("Add Aquila to robot:")}</IonLabel>
                      <IonSelect style={{"flexGrow":0}} placeholder={t("Click to select Aquila")} onIonChange={e => {
                        let aquila_id = e.detail.value || null;
                        let slot_id = genSlotId();
                        current_config!.aquila_configs.push({
                          aquila: available_aquilas.find((a:any)=>a.id===aquila_id)!,
                          aquila_id,
                          offset_y: 0,
                          slot_id,
                          standalone: false
                        })
                        set_current_config({...current_config});
                        set_edit_aquila_slot_id(slot_id);
                        set_edit_aquila_modal_open(true);
                      }}>
                        {available_aquilas?.map((aquila : any, index : number)=> 
                        <IonSelectOption key={index} value={aquila.id}>
                          AQL {aquila.id}
                        </IonSelectOption>
                        )}
                        <IonSelectOption value={null}>
                          {t("(placeholder)")}
                        </IonSelectOption>
                      </IonSelect>
                    </IonItem>
                    <IonItem>
                      <IonLabel>{t("Add Talpa to robot:")}</IonLabel>
                      <IonSelect placeholder={t("Click to select Talpa")} onIonChange={e => {
                        const talpa_id = e.detail.value || null;
                        const slot_id = genSlotId();
                        const talpa = available_talpas.find((t:any)=>t.id===talpa_id)!;
                        const talpa_tool_id = talpa_tools.find(tt=>tt.talpa_hardware_version_id===talpa.hardware_version.id)?.id || null;
                        current_config!.talpa_configs.push({
                          offset_y: 0,
                          talpa_id: talpa_id,
                          connected_aquila_id: null,
                          aquila_slot_id: null,
                          slot_id,
                          disabled: false,
                          talpa_tool_id,
                          talpa,
                          blade_turning_angle_pointing_backwards_deg: talpa.hardware_version.id===9?0.0:null,
                          treatment_direction: TreatmentDirection.INWARD
                        });
                        set_current_config({...current_config});
                        set_edit_talpa_slot_id(slot_id);
                        set_edit_talpa_modal_open(true);

                      }}>
                        {available_talpas?.map((talpa : any, index : number)=> 
                        <IonSelectOption key={index} value={talpa.id}>
                          TLP {talpa.id}
                        </IonSelectOption>
                        )}
                      </IonSelect>
                    </IonItem>
                    <IonItem>
                      <IonLabel>{t("Add crop row:")}</IonLabel>
                      <OffsetInputField max={MAX_Y_OFFSET} min={-MAX_Y_OFFSET} getInitial={()=>newOffsetYRow} setValue={setNewOffsetYRow} />
                      <IonButton onClick={_=>{
                        for (let row of current_config!.row_configs) {
                          if (row.offset_y === newOffsetYRow) {
                            return
                          }
                        }
                        current_config!.row_configs.push({offset_y : newOffsetYRow});
                        setNewOffsetYRow(0);
                        set_current_config({...current_config});
                        document.querySelector(".scroll-anchor")?.scrollIntoView();
                        setAddFabIsActive(false);
                        window.scrollTo(0, 0);
                      }}>{t("Add")}</IonButton>
                    </IonItem>
                    <IonItem>
                      <IonLabel>{t("Add Sprayer")}</IonLabel>
                      {(current_config?.row_configs.length)?(current_config?.row_configs.map((r: RowConfig)=>{
                        return <IonButton onClick={_evt=>{
                          let best_aql = current_config?.aquila_configs.reduce(
                            ({p_dist, p_ac}, val)=>{
                              const dist = Math.abs(r.offset_y - val.offset_y);
                              if (dist < p_dist) {
                                return {p_dist : dist, p_ac : val}
                              } else {
                                return {p_dist, p_ac}
                              }
                            },
                            {p_dist: Infinity, p_ac : (undefined as undefined|AquilaConfig)}).p_ac;
                          const new_sc : SprayerConfig = {
                            offset_x: 0,
                            offset_y: r.offset_y,
                            sensor_type: null,
                            connected_aquila_id: best_aql?.aquila_id || null,
                            aquila_slot_id: best_aql?.slot_id || undefined,
                            sprayer_calibration_id : null,
                            can_address_spray_hex: "480",
                            can_address_query_hex: "481",
                            can_address_pump_hex: "482",
                          };
                          current_config!.sprayer_configs.push(new_sc);
                          current_config!.sprayer_configs.sort(cmp_by_y_offset);
                          set_current_config({...current_config});
                          set_edit_sprayer_idx(current_config!.sprayer_configs.indexOf(new_sc));
                          setAddFabIsActive(false);
                          set_edit_sprayer_modal_open(true);
                        }}>{r.offset_y} cm</IonButton>
                      })): <small style={{cursor: 'not-allowed'}}>{t("no crop rows")}<br />{t("configured")}</small>}
                    </IonItem>

                    <IonItem>
                      <IonLabel>{t("Add Laser")}</IonLabel>
                      {(current_config?.row_configs.length)?(current_config?.row_configs.map((r: RowConfig)=>{
                        return <IonButton onClick={_evt=>{
                          const new_lc : LaserConfig = {
                            offset_x: 0,
                            offset_y: r.offset_y,
                            offset_z: 0,
                            calibration_pitch: 0,
                            calibration_roll: 0,
                            calibration_yaw: 0
                          };
                          current_config!.laser_configs.push(new_lc);
                          current_config!.laser_configs.sort(cmp_by_y_offset);
                          set_current_config({...current_config});
                          set_edit_laser_idx(current_config!.laser_configs.indexOf(new_lc));
                          setAddFabIsActive(false);
                          set_edit_laser_modal_open(true);
                        }}>{r.offset_y} cm</IonButton>
                      })): <small style={{cursor: 'not-allowed'}}>{t("no crop rows")}<br />{t("configured")}</small>}
                    </IonItem>
                  </IonCardContent>
                </IonCard>
              </IonFabList>
            </IonFab>

          </div>

       <RobotConfigChecklistModal robotConfigId={current_config.id} expand="block">
          <IonIcon icon={list} slot="start"/>
          {t("Show checklist")}
        </RobotConfigChecklistModal>

        {(warnings.length>0)?<IonCard style={{"backgroundColor":"yellow"}}>
          <IonCardHeader>
            <IonCardTitle>
                {t("Configuration issues")}
            </IonCardTitle>
          </IonCardHeader>
          <IonCardContent><ul style={{"listStyleType":"none", "paddingLeft":0, "color":"black"}}>
            {warnings.map((warning, idxa)=><li key={idxa}>{warning}</li>)}
            </ul></IonCardContent>
        </IonCard>:<></>}

        {/* aquila modal */}
        <IonModal isOpen={edit_aquila_modal_open} key={`${id}-edit-aql-${edit_aquila_slot_id}`} onDidDismiss={_=>set_edit_aquila_modal_open(false)}>
          <IonToolbar color="primary">
            <IonTitle>{t("Edit Aquila {{aquilaId}}", { aquilaId: editing_aquila?.aquila_id })}</IonTitle>
          </IonToolbar>
          <IonContent>
            <IonItem>
            <IonLabel style={{"flexShrink": 0}}>Offset (cm)</IonLabel>
              <OffsetInputField min={-MAX_Y_OFFSET} max={MAX_Y_OFFSET} getInitial={()=>editing_aquila?.offset_y || 0.0} setValue={val=>{
                editing_aquila!.offset_y = val;
                set_current_config({...current_config});
              }} />
            </IonItem>
            <IonItem>
              <IonLabel>Swap with replacement part:</IonLabel>
              <IonSelect style={{"flexGrow":0}} placeholder="Click to select Aquila" onIonChange={e => {
                  const ac = current_config!.aquila_configs.find((ac:any)=>ac.slot_id===edit_aquila_slot_id)!;
                  const new_aquila_id = e.detail.value;
                  ac.aquila_id = new_aquila_id;
                  const new_aquila = available_aquilas.find((aql:any)=>aql.id==new_aquila_id)!;
                  ac.aquila = new_aquila;
                  
                  // connect sprayers and talpas to new aquila
                  current_config?.talpa_configs.filter(tc=>tc.aquila_slot_id==edit_aquila_slot_id).forEach(tc=>tc.connected_aquila_id = new_aquila_id);
                  current_config?.sprayer_configs.filter(sc=>sc.aquila_slot_id==edit_aquila_slot_id).forEach(sc=>sc.connected_aquila_id = new_aquila_id);

                  set_current_config({...current_config});
                  set_edit_aquila_modal_open(false)
                }}>
                {available_aquilas?.map((aquila : any, index : number)=> 
                <IonSelectOption key={index} value={aquila.id}>
                  AQL {aquila.id}
                </IonSelectOption>
                )}
                <IonSelectOption value={null}>
                  {t("(placeholder)")}
                </IonSelectOption>
              </IonSelect>
            </IonItem>

          </IonContent>
          <IonFooter style={{"display":"flex"}}>
            <IonButton onClick={_=>{
              current_config!.aquila_configs = current_config!.aquila_configs.filter((c:any)=>c!==editing_aquila)!;
              for (let tc of current_config!.talpa_configs) {
                if (tc.aquila_slot_id == editing_aquila!.slot_id) {
                  tc.aquila_slot_id = null;
                  tc.connected_aquila_id = null;
                }
              }
              set_current_config({...current_config});
              set_edit_aquila_modal_open(false)
            }} color="danger">{t("Remove Aquila")}</IonButton>
            <IonButton onClick={_=>{
              set_edit_aquila_modal_open(false)
            }} style={{"flexGrow":1}}>Ok</IonButton>
          </IonFooter>
        </IonModal>

        {/* talpa modal */}
        <IonModal isOpen={edit_talpa_modal_open} key={`${id}-edit-tlp-${edit_talpa_slot_id}`} onDidDismiss={_=>set_edit_talpa_modal_open(false)} className='talpa-edit-modal'>
          <IonToolbar color="primary">
            <IonTitle>{t("Edit Talpa {{talpaId}}", {talpaId: editing_talpa?.talpa_id})}</IonTitle>
          </IonToolbar>
          <IonContent>
            <IonItem>
              <IonLabel style={{"flexShrink": 0}}>{t("Offset (cm)")}</IonLabel>
              <OffsetInputField min={-MAX_Y_OFFSET} max={MAX_Y_OFFSET} getInitial={()=>editing_talpa?.offset_y || 0.0} setValue={val=>{
                editing_talpa!.offset_y = val;
                set_current_config({...current_config});
              }} />    
            </IonItem>
            <IonItem style={{ "borderRight":  `10px solid #${aquila_colors[editing_talpa?.aquila_slot_id || 'none']}`}}>
              <IonLabel style={{"flexGrow": 2}}>Connected to</IonLabel>
              <IonSelect placeholder={t("Select camera")} onIonChange={e => {
                editing_talpa!.aquila_slot_id = e.detail.value;
                editing_talpa!.connected_aquila_id = current_config!.aquila_configs.find((ac:any)=>ac.slot_id===e.detail.value)!.aquila_id;
                set_current_config({...current_config});
              }}
              value={editing_talpa?.aquila_slot_id} interfaceOptions={{"cssClass": "colored-aquila-slot-select"}}>
                {current_config.aquila_configs.map((aquila : any, index : number)=> 
                <IonSelectOption key={index} value={aquila.slot_id} class={`color-${aquila_colors[aquila.slot_id]}`}>
                  {aquila.aquila_id?`AQL ${aquila.aquila_id}`:t("(placeholder)")}
                </IonSelectOption>
                )}
              </IonSelect>
            </IonItem>
            <IonItem>
              <IonLabel>{t("Swap with replacement part:")}</IonLabel>
              <IonSelect style={{"flexGrow":0}} placeholder={t("Click to select Talpa")} onIonChange={e => {
                const tc = current_config!.talpa_configs.find((tc:any)=>tc.talpa_id===editing_talpa?.talpa_id)!;
                const new_talpa_id = e.detail.value;
                tc.talpa_id = new_talpa_id;
                const new_talpa = available_talpas.find((tlp:any)=>tlp.id==new_talpa_id)!;
                // if switching to a talpa with a different hardware version, use some compatible tool shape
                if (new_talpa.hardware_version.id !== editing_talpa!.talpa.hardware_version.id) {
                  tc.talpa_tool_id = talpa_tools.find(tt=>tt.talpa_hardware_version_id==new_talpa.hardware_version.id)?.id || null;
                }
                tc.talpa = new_talpa;
                set_current_config({...current_config});
                set_edit_aquila_modal_open(false)
                }}>
                {available_talpas?.map((talpa : any, index : number)=> 
                <IonSelectOption key={index} value={talpa.id}>
                  TLP {talpa.id}
                </IonSelectOption>
                )}
                <IonSelectOption value={null}>
                  {t("(placeholder)")}
                </IonSelectOption>
              </IonSelect>
            </IonItem>
            <IonItem>
              <IonLabel>{t("Side of row:")}</IonLabel>
              {editing_talpa?.talpa?.side_of_row || '-'}
            </IonItem>
            {editing_talpa?.talpa?.hardware_version.id===9 && <IonItem>
              <IonLabel>{t("Hoeing direction:")}</IonLabel>
              <IonSelect
                value={editing_talpa?.treatment_direction || TreatmentDirection.INWARD}
                onClick={_=>addBodyClass("select-treatment-direction")}
                onIonChange={evt=>{
                  removeBodyClass("select-treatment-direction");
                  editing_talpa.treatment_direction = evt.target.value;
                  set_current_config({...current_config});
                }}
                onIonCancel={_=>removeBodyClass("select-treatment-direction")}
              >
                <IonSelectOption value={TreatmentDirection.INWARD}>{t("inward →←")}</IonSelectOption>
                <IonSelectOption value={TreatmentDirection.OUTWARD_LEFT}>{t("both hoes to the left ←←")}</IonSelectOption>
                <IonSelectOption value={TreatmentDirection.OUTWARD_RIGHT}>{t("both hoes to the right →→")}</IonSelectOption>
              </IonSelect>
            </IonItem>}

            {talpa_tools && (editing_talpa?.talpa?.hardware_version.id !== 5) && 
              <IonItem>
                <div className='talpa-tool-chooser-inner'>
                  <IonLabel style={{"width": "100%", "paddingBottom": "0.8em", "marginTop": "10px"}}>{t("Hoe shape:")}</IonLabel>
                  <br />
                  {talpa_tools?.filter((tt : any)=>tt.talpa_hardware_version_id===editing_talpa?.talpa?.hardware_version.id).map((tt : any, index : number)=>{ 
                  return <div
                    className={(tt.id === editing_talpa?.talpa_tool_id)?'talpa-tool-chooser-item selected':'talpa-tool-chooser-item'}
                    onClick={_=>{
                      editing_talpa!.talpa_tool_id = tt.id;
                      set_current_config({...current_config});
                    }}
                  >
                    {editing_talpa?.talpa.side_of_row==="both"
                      ?<TwoSidedHoeViz tool_type={tt} rotation={editing_talpa.blade_turning_angle_pointing_backwards_deg || undefined} treatment_direction={editing_talpa.treatment_direction} />
                      :<MiniatureShape key={tt.id} points={tt[`shape_${editing_talpa?.talpa?.side_of_row === 'right'?'right':'left'}`]} />
                    }
                      <small className='talpa-tool-chooser-description'>{tt.description}</small>
                  </div>
                  })}
                  </div>
              </IonItem>
            }

            {editing_talpa?.talpa.hardware_version.id === 9 && <IonItem>
              <IonLabel style={{flexGrow: 1, flexShrink: 0}}>{t("Blade angle")}</IonLabel>
              <div style={{flexGrow: 1, textAlign: 'right'}}>
                <input type="range"
                  min="-45" max="22.5" step="22.5"
                  value={(editing_talpa.blade_turning_angle_pointing_backwards_deg || 0.0) * -1}
                  style={{"width": "calc(100% - 5em)", "maxWidth": "4cm",}}
                  onChange={e=>{
                    const selected_value = -parseFloat((e.target.value || 0).toString().replace(",","."));
                    editing_talpa.blade_turning_angle_pointing_backwards_deg = selected_value;
                    set_current_config({...current_config});
                  }}
                /><br />
                <div>{format_angle(editing_talpa.blade_turning_angle_pointing_backwards_deg)}</div>
                {editing_talpa.treatment_direction !== TreatmentDirection.INWARD && editing_talpa.blade_turning_angle_pointing_backwards_deg !== 0.0 && <small>{t("Note: Blade angle only applies to the outwards-working hoe.")}</small>}
              </div>  
            </IonItem>}

            {show_set_all_button && <IonItem>
              <IonButton style={{whiteSpace:'normal'}} onClick={_=>{
                for (let tc of current_config!.talpa_configs) {
                  if (tc.talpa.hardware_version.id === editing_talpa!.talpa.hardware_version.id) {
                    tc.talpa_tool_id = editing_talpa!.talpa_tool_id;
                    tc.blade_turning_angle_pointing_backwards_deg = editing_talpa?.blade_turning_angle_pointing_backwards_deg!;
                    tc.treatment_direction = editing_talpa?.treatment_direction!;
                  }
                }
                set_current_config({...current_config});
              }}>{t("Set this hoe configuration for all compatible talpas on this robot")}</IonButton>
            </IonItem>}

          </IonContent>
          <IonFooter style={{"display":"flex"}}>
            <IonButton onClick={_=>{
              current_config!.talpa_configs = current_config!.talpa_configs.filter((tc:any)=>tc!==editing_talpa);
              set_current_config({...current_config});
              set_edit_talpa_modal_open(false)
            }} color="danger">{t("Remove talpa")}</IonButton>
            <IonButton onClick={_=>{
              set_edit_talpa_modal_open(false);
            }} style={{"flexGrow":1}}>Ok</IonButton>
          </IonFooter>
        </IonModal>

        {/* row modal */}
        <IonModal isOpen={edit_row_modal_open} key={`${id}-edit-row-${edit_row_idx}`} onDidDismiss={_=>set_edit_row_modal_open(false)}>
          <IonToolbar color="primary">
            <IonTitle>{t("Edit crop row")}</IonTitle>
          </IonToolbar>
          <IonContent>
            <IonItem>
              <IonLabel style={{"flexShrink": 0}}>{t("Offset (cm)")}</IonLabel>
              <OffsetInputField min={-MAX_Y_OFFSET} max={MAX_Y_OFFSET} getInitial={()=>current_config?.row_configs[edit_row_idx].offset_y || 0.0} setValue={val=>{
                current_config!.row_configs[edit_row_idx].offset_y = val;
                set_current_config({...current_config});
              }} />
            </IonItem>
          </IonContent>
          <IonFooter style={{"display":"flex"}}>
            <IonButton onClick={_=>{
              current_config!.row_configs.splice(edit_row_idx, 1);
              set_current_config({...current_config});
              set_edit_row_modal_open(false)
            }} color="danger">{t("Remove Row")}</IonButton>
            <IonButton onClick={_=>{
              set_current_config({...current_config});
              set_edit_row_modal_open(false);
            }} style={{"flexGrow":1}}>Ok</IonButton>
          </IonFooter>
        </IonModal>

        {/* sprayer modal */}
        <IonModal isOpen={edit_sprayer_modal_open} key={`${id}-edit-sprayer-${edit_sprayer_idx}`} onDidDismiss={_=>set_edit_sprayer_modal_open(false)}>
          <IonToolbar color="primary">
            <IonTitle>{t("Edit sprayer")}</IonTitle>
          </IonToolbar>
          <IonContent>
            <IonItem>
              <IonLabel style={{"flexShrink": 0}}>{t("Side offset (cm)")}</IonLabel>
              <OffsetInputField max={MAX_Y_OFFSET} min={-MAX_Y_OFFSET} getInitial={()=>current_config!.sprayer_configs[edit_sprayer_idx]?.offset_y} setValue={(val)=>{
                current_config!.sprayer_configs[edit_sprayer_idx].offset_y = val;
                set_current_config({...current_config});
              }} />
            </IonItem>
            <IonItem>
              <IonLabel style={{"flexShrink": 0}}>{t("Offset to camera (cm)")}</IonLabel>
              <OffsetInputField max={0} min={-200} getInitial={()=>current_config!.sprayer_configs[edit_sprayer_idx]?.offset_x} setValue={(val)=>{
                current_config!.sprayer_configs[edit_sprayer_idx].offset_x = val;
                set_current_config({...current_config});
              }} />
            </IonItem>
            <IonItem>
              <IonLabel style={{"flexShrink": 0}}>{t("attached Sensor")}</IonLabel>
              <IonSelect value={current_config?.sprayer_configs[edit_sprayer_idx]?.sensor_type} onIonChange={evt=>{
                current_config!.sprayer_configs[edit_sprayer_idx].sensor_type = evt.detail.value;
                set_current_config({...current_config});
              }}>
                <IonSelectOption value={null}>{t("None")}</IonSelectOption>
                <IonSelectOption value={SensorType.Pressure}>{t("Pressure")}</IonSelectOption>
                <IonSelectOption value={SensorType.VolumetricFlowRate}>{t("Volumetric Flow Rate")}</IonSelectOption>
                <IonSelectOption value={SensorType.FillingLevel}>{t("Filling Level")}</IonSelectOption>
              </IonSelect>
            </IonItem>
            <IonItem style={{ "borderRight":  `10px solid #${aquila_colors[current_config!.sprayer_configs[edit_sprayer_idx]?.aquila_slot_id || 'none']}`}}>
              <IonLabel style={{"flexGrow": 2}}>{("Connected to")}</IonLabel>
              <IonSelect placeholder="Select camera" onIonChange={e => {
                const editing_sprayer = current_config!.sprayer_configs[edit_sprayer_idx];
                editing_sprayer!.aquila_slot_id = e.detail.value;
                editing_sprayer!.connected_aquila_id = current_config!.aquila_configs.find((ac:any)=>ac.slot_id===e.detail.value)!.aquila_id;
                set_current_config({...current_config});
              }}
              value={current_config!.sprayer_configs[edit_sprayer_idx]?.aquila_slot_id} interfaceOptions={{"cssClass": "colored-aquila-slot-select"}}>
                {current_config.aquila_configs.map((aquila : any, index : number)=> 
                <IonSelectOption key={index} value={aquila.slot_id} class={`color-${aquila_colors[aquila.slot_id]}`}>
                  {aquila.aquila_id?`AQL ${aquila.aquila_id}`:t("(placeholder)")}
                </IonSelectOption>
                )}
              </IonSelect>
            </IonItem>
            <IonItem>
              <IonLabel style={{"flexGrow": 2}}>{t("Calibration ID")}</IonLabel>
              <IonInput value={current_config!.sprayer_configs[edit_sprayer_idx]?.sprayer_calibration_id} onIonChange={evt=>{
                let id : number | null = parseInt(evt.target.value as string);
                if (isNaN(id)) {
                  id = null;
                }
                current_config!.sprayer_configs[edit_sprayer_idx].sprayer_calibration_id = id;
                set_current_config({...current_config});
              }} />
            </IonItem>

            <IonItem>
              <IonLabel style={{"flexGrow": 2}}>{t("CAN ID (spray)")}</IonLabel>
              0x<IonInput style={{marginLeft: '-8px'}} value={current_config!.sprayer_configs[edit_sprayer_idx]?.can_address_spray_hex} onIonChange={evt=>{
                current_config!.sprayer_configs[edit_sprayer_idx].can_address_spray_hex = validateHex((evt.target.value || "") as string);
                set_current_config({...current_config});
              }} />
            </IonItem>
            <IonItem>
              <IonLabel style={{"flexGrow": 2}}>{t("CAN ID (ping/sensor)")}</IonLabel>
              0x<IonInput style={{marginLeft: '-8px'}} value={current_config!.sprayer_configs[edit_sprayer_idx]?.can_address_query_hex} onIonChange={evt=>{
                current_config!.sprayer_configs[edit_sprayer_idx].can_address_query_hex = validateHex((evt.target.value || "") as string);
                set_current_config({...current_config});
              }} />
            </IonItem>
            <IonItem>
              <IonLabel style={{"flexGrow": 2}}>{t("CAN ID (pump)")}</IonLabel>
              0x<IonInput style={{marginLeft: '-8px'}} value={current_config!.sprayer_configs[edit_sprayer_idx]?.can_address_pump_hex} onIonChange={evt=>{
                current_config!.sprayer_configs[edit_sprayer_idx].can_address_pump_hex = validateHex((evt.target.value || "") as string);
                set_current_config({...current_config});
              }} />
            </IonItem>

          </IonContent>
          <IonFooter style={{"display":"flex"}}>
            <IonButton onClick={_=>{
              current_config!.sprayer_configs.splice(edit_sprayer_idx, 1);
              set_current_config({...current_config});
              set_edit_sprayer_modal_open(false)
            }} color="danger">{t("Remove Sprayer")}</IonButton>
            <IonButton onClick={_=>{
              set_current_config({...current_config});
              set_edit_sprayer_modal_open(false);
            }} style={{"flexGrow":1}}>Ok</IonButton>
          </IonFooter>
        </IonModal>

        {/* laser modal */}
        <IonModal isOpen={edit_laser_modal_open} key={`${id}-edit-laser-${edit_laser_idx}`} onDidDismiss={_=>set_edit_laser_modal_open(false)}>
          <IonToolbar color="primary">
            <IonTitle>{t("Edit laser")}</IonTitle>
          </IonToolbar>
          <IonContent>
            {/* TODO: determine appropriate range for these values */}
            <IonItem>
              <IonLabel style={{"flexShrink": 0}}>X {t("Offset (cm)")}</IonLabel>
              <OffsetInputField max={TODO_OFFSET_LIMITS} min={-TODO_OFFSET_LIMITS} getInitial={()=>current_config!.laser_configs[edit_laser_idx]?.offset_x} setValue={(val)=>{
                current_config!.laser_configs[edit_laser_idx].offset_x = val;
                set_current_config({...current_config});
              }} />
            </IonItem>
            <IonItem>
              <IonLabel style={{"flexShrink": 0}}>Y {t("Offset (cm)")}</IonLabel>
              <OffsetInputField max={MAX_Y_OFFSET} min={-MAX_Y_OFFSET} getInitial={()=>current_config!.laser_configs[edit_laser_idx]?.offset_y} setValue={(val)=>{
                current_config!.laser_configs[edit_laser_idx].offset_y = val;
                set_current_config({...current_config});
              }} />
            </IonItem>
            <IonItem>
              <IonLabel style={{"flexShrink": 0}}>Z {t("Offset (cm)")}</IonLabel>
              <OffsetInputField max={TODO_OFFSET_LIMITS} min={-TODO_OFFSET_LIMITS} getInitial={()=>current_config!.laser_configs[edit_laser_idx]?.offset_z} setValue={(val)=>{
                current_config!.laser_configs[edit_laser_idx].offset_z = val;
                set_current_config({...current_config});
              }} />
            </IonItem>

            {/* TODO: find out how we calibrate these and choose a better input widget */}
            <IonItem>
              <IonLabel style={{"flexShrink": 0, flexGrow: 1}}>{t("Calibration roll (radian)")}</IonLabel>
              <input style={{textAlign: "right", width: "2cm"}} max={TODO_OFFSET_LIMITS} min={-TODO_OFFSET_LIMITS} value={current_config!.laser_configs[edit_laser_idx]?.calibration_roll} onChange={(val)=>{
                current_config!.laser_configs[edit_laser_idx].calibration_roll = parseFloat(""+val.target.value || "0");
                set_current_config({...current_config});
              }} />
            </IonItem>
            <IonItem>
              <IonLabel style={{"flexShrink": 0, flexGrow: 1}}>{t("Calibration roll (radian)")}</IonLabel>
              <input style={{textAlign: "right", width: "2cm"}} max={TODO_OFFSET_LIMITS} min={-TODO_OFFSET_LIMITS} value={current_config!.laser_configs[edit_laser_idx]?.calibration_pitch} onChange={(val)=>{
                current_config!.laser_configs[edit_laser_idx].calibration_pitch = parseFloat(""+val.target.value || "0");
                set_current_config({...current_config});
              }} />
            </IonItem>
            <IonItem>
              <IonLabel style={{"flexShrink": 0, flexGrow: 1}}>{t("Calibration roll (radian)")}</IonLabel>
              <input style={{textAlign: "right", width: "2cm"}} max={TODO_OFFSET_LIMITS} min={-TODO_OFFSET_LIMITS} value={current_config!.laser_configs[edit_laser_idx]?.calibration_yaw} onChange={(val)=>{
                current_config!.laser_configs[edit_laser_idx].calibration_yaw = parseFloat(""+val.target.value || "0");
                set_current_config({...current_config});
              }} />
            </IonItem>
          </IonContent>
          <IonFooter style={{"display":"flex"}}>
            <IonButton onClick={_=>{
              current_config!.laser_configs.splice(edit_laser_idx, 1);
              set_current_config({...current_config});
              set_edit_laser_modal_open(false)
            }} color="danger">{t("Remove Laser")}</IonButton>
            <IonButton onClick={_=>{
              set_current_config({...current_config});
              set_edit_laser_modal_open(false);
            }} style={{"flexGrow":1}}>Ok</IonButton>
          </IonFooter>
        </IonModal>

          <IonCard>
            <IonCardContent>
              {current_config.description && <IonItem>
                <IonIcon icon={informationCircle} slot="start"/>
                {current_config.description}
              </IonItem>}

              {((taurus_config, taurusbox_config)=>{
                if (!taurus_config || !taurusbox_config) {
                  return t("no taurus_config found")
                }
                return <>
         
                  <IonItem>
                    <div style={{display: "flex", width: "100%"}}>
                      <IonLabel style={{flexGrow: 1, flexShrink: 0}}>{t("Track width")}</IonLabel>
                      <div style={{flexGrow: 1, textAlign: 'right'}}>
                        <input type="range"
                          min="1.35" max="2.25" step="0.01"
                          value={formatMeter(taurus_config.track_width)}
                          style={{"width": "calc(100% - 2em)", "maxWidth": "8cm",}}
                          onChange={e=>{
                            const selected_value = parseFloat((e.target.value || 0).toString().replace(",","."));
                            const VALID_VALUES = [1.35, 1.5, 1.8, 2, 2.25];
                            const snapped_value = VALID_VALUES.reduce(({min_dist, best_match},vv)=>{ const d = Math.abs(vv-selected_value); if (d<min_dist) { return {min_dist: d, best_match: vv} } else { return {min_dist, best_match} } },{min_dist: Infinity, best_match: 1.35}).best_match;
                            taurus_config.track_width = snapped_value;
                            set_current_config({...current_config});
                          }}
                        />
                      </div>
                      <span style={{paddingLeft: "1em", flexShrink: 0, flexGrow: 0}}>
                        {formatMeter(taurus_config.track_width)} m
                      </span>
                    </div>
                  </IonItem>
                  <IonItem>
                    <IonLabel>{t("Key box code")}</IonLabel>{taurus_config.keybox_code || t("no code set yet")}
                  </IonItem>
                  <IonItem>
                    <IonLabel>{t("GPS type")}</IonLabel>{taurusbox_config.gps_type}
                  </IonItem>
                  {taurus_config.has_pfeifer_u_langen_box && <IonItem>{t("Has Pfeifer und Langen box")}</IonItem>}
                  <IonItem>
                    <div style={{flexWrap: 'wrap', display: 'flex', justifyContent: 'space-between', alignItems: isLargeScreen?'center':'start', width: '100%', flexDirection: isLargeScreen?'row':'column'}}>
                      <IonLabel style={{flexGrow: 1}}>{t("Drive motors")}</IonLabel>

                      {[t('front'), t('back')].map(x=>[t('left'), t('right')].map(y=>{
                        const key = `disable_drive_motor_${x}_${y}`;
                        const tc = taurus_config as any;
                        return <div style={{display: 'inline-flex', alignItems: 'center', 'margin': '0 1em'}}>
                          <span style={{cursor:'pointer', color: tc[key]?'#333':'unset'}} onClick={_=>{
                            tc[key] = !tc[key];
                            set_current_config({...current_config});
                          }}>{x} {y}</span>
                          <IonToggle checked={!tc[key]} onIonChange={e=>{
                            tc[key] = !e.detail.checked;
                            set_current_config({...current_config});
                          }} />
                        </div>
                      }))}
                    </div>
                  </IonItem>
                  <IonItem>
                    <IonLabel>{t("Generator")}</IonLabel>
                    <GeneratorSelect 
                      componentId={current_config?.generator_id || null} 
                      setComponentId={setGeneratorId}
                      selectChildren={<>
                        <IonSelectOption value={null}>No Selection</IonSelectOption>
                      </>}
                      />
                  </IonItem>
                </>
              })(current_config.taurus_configs[0], current_config.taurusbox_configs[0])}
            </IonCardContent>
          </IonCard>
        </>}
          
        <Connect current_config={current_config} />
              
        <IonCard>
          <IonCardHeader><IonCardSubtitle>Talpas</IonCardSubtitle></IonCardHeader>
          <IonCardContent>
            {current_config?.talpa_configs?.length < 1 && t("No talpas")}
            {current_config?.talpa_configs?.filter((t: any) => t.talpa)?.map((t: any) => <TalpaItem key={t.slot_id} talpa={t.talpa}/>)}
          </IonCardContent>
        </IonCard>

        <IonCard>
          <IonCardHeader><IonCardSubtitle>Sprayer calibrations</IonCardSubtitle></IonCardHeader>
          <IonCardContent>
            {current_config?.talpa_configs?.map(tc=>{
              const talpa = tc.talpa;
              const ROMAN_NUMERALS = ["I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX", "X", "XI", "XII"];
              let closest_row_idx = null;
              let closest_row_dist = Infinity;
              let idx = 0;
              for (const rc of current_config!.row_configs) {
                const dist = Math.abs(rc.offset_y - tc.offset_y);
                if (dist < closest_row_dist) {
                  closest_row_dist = dist;
                  closest_row_idx = idx;
                }
                idx += 1;
              }
              let rowName;
              if (closest_row_idx !== null && closest_row_dist < 20) {
                rowName = `row${ROMAN_NUMERALS[closest_row_idx]}`
              } else {
                rowName = "no row"
              }
              return <TalpaSprayerCalibrationOverview talpa={talpa} key={talpa.id} rowName={rowName} />
            })}
          </IonCardContent>
        </IonCard>
        
        <IonModal isOpen={saveConfigRes.called} onDidDismiss={_=>(window.location as any)=new_robot_config_url}>
          {saveConfigRes.loading && <>
            <IonCardHeader><IonCardTitle>{`${t("Saving")}...`}</IonCardTitle></IonCardHeader>
            <IonCardContent><Loading /></IonCardContent>
          </>}
          {saveConfigRes.data && <>
            <IonCardHeader><IonCardTitle>{t("Saved")}</IonCardTitle></IonCardHeader>
            <IonCardContent>
              <div style={{display: 'flex'}}>
                <div style={{fontSize: "30px", marginRight: ".3em"}}>✅</div>
                <div style={{flexGrow: 1}}>
                  <p>{t("Updated robot configuration!")}</p>
                  <p><a href={(new_robot_config_url as string)}>{t("proceed")}</a></p>
                </div>
              </div>
            </IonCardContent>
          </>}
          {saveConfigRes.error && <>
            <IonCardHeader><IonCardTitle>{t("Error saving config")}</IonCardTitle></IonCardHeader>
            <IonCardContent>
              {saveConfigRes.error?.message && <strong>{saveConfigRes.error?.message}</strong>}
              <pre>
                {JSON.stringify(saveConfigRes.error, null, 2)}
              </pre>
            </IonCardContent>
          </>}
        </IonModal>
      

        {lastPosition.data &&
          <IonCard>
            <IonCardHeader><IonCardSubtitle>{t("Status")}</IonCardSubtitle></IonCardHeader>
            <IonCardContent>
                <IonList>
                    <IonItem>{t("Last time seen:")} {moment(lastPosition.data.last_gt_position.time).twitterShort()}</IonItem>
                    <IonItem>{t("Lat")} {lastPosition.data.last_gt_position.lat.toFixed(5) || "unknown"}</IonItem>
                    <IonItem>{t("Long")} {lastPosition.data.last_gt_position.lon.toFixed(5) || t("unknown")}</IonItem>
                    <IonItem><a href={lastPosition.data.last_gt_position.map_link} target="_blank">{t("map view")}</a></IonItem>
                </IonList>
            </IonCardContent>
          </IonCard>
        }
        <EditSimCard current={current_config} set_current_config={set_current_config}/>
        <div style={{width: "100%", height: "100px"}} /> {/* ensure more button can always be shown (not hidden by refresh) */}
      </IonContent>
    </IonPage>
}

// Iterative force-based layout algorithm for the graphical robot config editor
// puts component in the place specified by `.offset_y`, but moves them a bit
// apart if they are so close together that they would overlap. This is
// necessary, because the edit dialog is accessed by clicking on the component,
// which becomes difficult if they overlap.
function adjust_layout( data : {width: number, x: number, target_x : number, type : any, slot_id : string}[]) {
  const MAX_ITER = 100;
  let SPRING_CONSTANT_COLLISION = 0.05;
  let spring_constant_offset = 0.03;

  let best_force_abs_sum = Infinity;
  let best_xs = data.map(a=>a.x);

  function arr_sum (arr : number[]) { 
      let acc = 0;
      for (let x of arr) {
          acc += x;
      }
      return acc
  }

  // If two objects of the same type have the same offset_y, disambiguate the order
  // in which they should appear based on their persistent slot_id. This way, the
  // order is consistent over re-renders.
  function slot_to_fac(slot_id : string) {
    let rv = parseInt(slot_id,16);
    return rv
  }

  for (let i=0;i<MAX_ITER;i++) {
      let collides = 0.0;
      const forces = data.map(a=>arr_sum(data.map(b=>{
          if (a === b) return 0
          if (a.type != b.type) return 0;
          const dist = a.x - b.x;
          if (dist == 0.0) {
            let fac;
            if (typeof a.slot_id === 'string') {
              fac = slot_to_fac(a.slot_id) - slot_to_fac(b.slot_id);
            } else {
              fac = (a.slot_id as any) - (b.slot_id as any);
            }
            if (fac === 0) {
              console.warn("fallback to random", a, b)
              fac = Math.random();
            }
            while (Math.abs(fac) < 0.5) {
              fac *= 2;
            }
            while (Math.abs(fac) > 1.0) {
              fac /= 2;
            }
            return fac * SPRING_CONSTANT_COLLISION;
          }
          const min_dist = (a.width + b.width) / 2
          if (Math.abs(dist) < min_dist) {
              collides = 1.0;
              return SPRING_CONSTANT_COLLISION * dist
          }
          return 0;
      })) - spring_constant_offset * (a.x - a.target_x))

      const force_abs_sum = arr_sum(forces.map(Math.abs)) * (1 + 10*collides);
      if (force_abs_sum < best_force_abs_sum) {
          best_xs = data.map(a=>a.x);
          best_force_abs_sum = force_abs_sum;
      }

      for (let j=0; j<data.length; j++) {
          data[j].x += forces[j]
      }
      
      spring_constant_offset *= 0.9
  }
  return best_xs
}

// 32 random characters out of [a-z0-9]
function genSlotId() : string {
  return Array(32)
    .fill(0)
    .map(_=>(Math.round(Math.random()*16))
    .toString(16))
    .join("")
}

export default Robot;

