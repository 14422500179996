import { IonContent, IonPage, IonProgressBar, IonLabel, IonItem, IonItemDivider} from '@ionic/react';
import React  from 'react';
import Toolbar from '../components/Toolbar'
import { useQuery, gql } from '@apollo/client';


const Stats: React.FC = () => {
  const { loading, data} = useQuery(gql`
    query Stats {
        all_bags: bags_aggregate {aggregate {count}}
        tauruses(order_by: {id: asc}) {
          id
          bags_aggregate {aggregate {count}}
        }
        aquilas(order_by: {id: asc}) {
          id
          bags_aggregate {aggregate {count}}
        }
    }
  `);
  return (
    <IonPage>
      <Toolbar name="Stats"/>
      <IonContent fullscreen>
        {loading && <IonProgressBar type="indeterminate"/>}
        {data && <>
          <IonItem>
            <IonLabel>Total</IonLabel>
            {data.all_bags.aggregate.count}
          </IonItem>
          <IonItemDivider>Bags per Taurus</IonItemDivider>
          {data.tauruses.map((taurus: any) => <IonItem>
            <IonLabel>GT {taurus.id}</IonLabel>
            {taurus.bags_aggregate.aggregate.count}
          </IonItem>)}
          <IonItemDivider>Bags per Aquila</IonItemDivider>
          {data.aquilas.map((aquila: any) => <IonItem>
            <IonLabel>AQL {aquila.id}</IonLabel>
            {aquila.bags_aggregate.aggregate.count}
          </IonItem>)}
        </>}
      </IonContent>
    </IonPage>
  );
};

export default Stats;
