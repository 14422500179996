import { IonButton, IonItem, IonBadge, IonIcon, IonCardContent, IonLabel, IonPopover, IonCard, IonCardHeader, IonCardSubtitle, } from '@ionic/react';
import React, { useState } from 'react';
import useWindowDimensions from '../useWindowDimensions';
import { chatbox, checkmark, close, } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';
import ReactMarkdown from 'react-markdown';
const moment = require('moment-twitter');


export const TimelineItem: React.FC<any> = ({createdAt, eventType, isSim, header, collapsableContent}) => {
  const [event, setEvent] = useState<any>(null)
  return <>
    <IonPopover event={event} isOpen={!!event} onDidDismiss={() => setEvent(null)} className="wide-popover">
      <IonItem>
        {createdAt && <IonBadge color="medium" slot="start">{moment(createdAt).twitterShort()}</IonBadge>}
        {isSim && <IonBadge color="danger" slot="start">sim</IonBadge>}
        <IonButton onClick={() => setEvent(null)} fill="clear" slot="end"><IonIcon icon={close} slot="icon-only"/></IonButton>
      </IonItem>
      {header && <IonItem>{header}</IonItem>}
      {collapsableContent && <IonCardContent>
        {collapsableContent}
      </IonCardContent>}
    </IonPopover>
    <IonItem lines={"full"} onClick={setEvent} button={!!collapsableContent}>
      {createdAt && <IonBadge color="medium" slot="start">{moment(createdAt).twitterShort()}</IonBadge>}
      {isSim && <IonBadge color="danger" slot="start">sim</IonBadge>}
      {header}
      {eventType && <IonBadge>{eventType}</IonBadge>}
    </IonItem>
  </>
}


const gtIdFromRobotConfig = (robotConfig: any) => {
  const taurusConfigs = robotConfig?.taurus_configs
  if (taurusConfigs?.length > 0) {return taurusConfigs[0]?.taurus_id}
  else {return undefined}
}


export const RobotConfigEvent = ({event: robotConfig}: any) => {
  const {t} = useTranslation()
  const gtId = gtIdFromRobotConfig(robotConfig)
  const header = <>
    <IonLabel style={{marginLeft: 5}}>
      {gtId ? t("New robot config for GT{{ gtId }}", {gtId: gtId}) : t("New robot config")}
    </IonLabel>
    {gtId && <IonBadge>GT{gtId}</IonBadge>}
  </>
  const collapsableContent = <>
    {JSON.stringify(robotConfig)}
  </>
  return <TimelineItem header={header} collapsableContent={collapsableContent} createdAt={robotConfig.created_at}/>
}


export const ProcessExecutionEvent = ({data}: any) => {
  const {isLargeScreen} = useWindowDimensions()
  const header = <>
    {data.taurus_id && <IonBadge color="success">GT{data.taurus_id}</IonBadge>}
    {data.talpa_id && <IonBadge color="success">TLP{data.talpa_id}</IonBadge>}
    {data.aquila_id && <IonBadge color="success">AQL{data.aquila_id}</IonBadge>}
    {data.generator_id && <IonBadge color="success">GEN{data.generator_id}</IonBadge>}
    {isLargeScreen && <IonBadge>
      {data.author_name?.split("@")?.slice(0, 1)}
    </IonBadge>}
    <IonLabel style={{marginLeft: 5}}>
      {data.process?.description}
    </IonLabel>
  </>
  return <TimelineItem header={header} createdAt={data.created_at}/>
}


export const EventTimelineItem = ({data}: any) => {
  const gtId = gtIdFromRobotConfig(data?.robot_config)
  const header = <>
    {gtId && <IonBadge>GT{gtId}</IonBadge>}
    <IonLabel style={{marginLeft: 5}}>
      {data?.data?.type && <>{data.data.type}</>}
    </IonLabel>
  </>
  const collapsableContent = <>
    {data.id && data.data?.type == "An error occurred for more than 60s" && <IonButton expand="block" onClick={(e: any) => {e.stopPropagation(); window.open(`/event/${data.id}`)}}>
      Open event page to see errors
    </IonButton>}
    {data.is_sim && data.simulation_name && <IonItem>
      <IonLabel position="stacked">
        Simulation name
      </IonLabel>
      {data.simulation_name}
    </IonItem>}
  </>
  return <TimelineItem header={header} collapsableContent={collapsableContent} createdAt={data.created_at} isSim={data.is_sim} />
}


export const MessageTimelineItem = ({data}: any) => {
  const gtId = gtIdFromRobotConfig(data?.robot_config)
  const header = <>
    <IonLabel>
      <ReactMarkdown>{data.text_md}</ReactMarkdown>
    </IonLabel>
    {data.author_user?.name && <IonBadge color="secondary">{data.author_user.name.split("@").at(0)}</IonBadge>}
    {gtId && <IonBadge>GT{gtId}</IonBadge>}
  </>
  const collapsableContent = <>
    <ReactMarkdown>{data.text_md}</ReactMarkdown>
    {data.is_sim && data.simulation_name && <IonItem>
      <IonLabel position="stacked">
        Simulation name
      </IonLabel>
      {data.simulation_name}
    </IonItem>}
  </>
  return <TimelineItem header={header} collapsableContent={collapsableContent} createdAt={data.created_at} isSim={data.is_sim} />
}



export const ParamTimelineItem = ({data}: any) => {
  const header = <>
    <IonLabel style={{marginLeft: 5}}>
      {data.last_update_description && <>{" "} {data.last_update_description}</>}
    </IonLabel>
    {data.param_config_id && <IonBadge color="medium">#{data.param_config_id}</IonBadge>}
    {data.taurus_id && <IonBadge>GT{data.taurus_id}</IonBadge>}
  </>
  const collapsableContent = <>
    <IonItem>
      <IonLabel>
      {data.last_update_description}
      </IonLabel>
    </IonItem>
    {data.param_config_id && <IonItem>
      <IonLabel position="stacked">
      Param config
      </IonLabel>
      #{data.param_config_id}
    </IonItem>}
    {data.simulation_name && <IonItem>
      <IonLabel position="stacked">
        Simulation name
      </IonLabel>
      {data.simulation_name}
    </IonItem>}
    {data.overrides && <IonCard>
      <IonCardHeader><IonCardSubtitle>Overrides</IonCardSubtitle></IonCardHeader>
      <IonCardContent>
        {Object.keys(data.overrides).map((k: string) => <IonItem key={k}>
          <IonLabel>
            {k}
          </IonLabel>
          {`${data.overrides[k]}`}
        </IonItem>)}
      </IonCardContent>
    </IonCard>}
  </>
  return <TimelineItem header={header} collapsableContent={collapsableContent} createdAt={data.created_at} isSim={!!data.simulation_name} />
}


export const FieldProgressTimeline = ({data}: any) => {
  const header = <>
    <IonLabel style={{marginLeft: 5}}>
      {t("{{fieldProgressPercentage}} % of {{totalFieldAcreage_ha}} ha treated", {
        fieldProgressPercentage: (data.treated_ratio * 100).toFixed(2),
        totalFieldAcreage_ha: (data.total_area_m2 / 10000.).toFixed(2),
      })}
    </IonLabel>
    {data.field_id && <IonBadge color="success">Field {data.field_id}</IonBadge>}
    {data.taurus_id && <IonBadge>GT{data.taurus_id}</IonBadge>}
  </>
  const collapsableContent = <>
    {data.simulation_name && <IonItem>
      <IonLabel position="stacked">
        Simulation name
      </IonLabel>
      {data.simulation_name}
    </IonItem>}
  </>
  return <TimelineItem header={header} collapsableContent={collapsableContent} createdAt={data.created_at} isSim={!!data.simulation_name} />
}


export const GenericEventItem = ({data}: any) => {
  // Use different event visualization depending on type of event
  if (data.__typename === "process_executions") {
    return <ProcessExecutionEvent data={data} />
  }

  else if (data.__typename === "events") {
    return <EventTimelineItem data={data}/>
  }

  else if (data.__typename === "robot_configs") {
    return <RobotConfigEvent event={data}/>
  }

  else if (data.__typename === "param_config_states") {
    return <ParamTimelineItem data={data}/>
  }

  else if (data.__typename === "field_progresses") {
    return <FieldProgressTimeline data={data}/>
  }

  else if (data.__typename === "messages") {
    return <MessageTimelineItem data={data}/>
  }

  else {
    return <TimelineItem eventType={data.__typename} createdAt={data.createdAt} collapsableContent={<>
      {JSON.stringify(data)}
    </>}/>
  }
}