import { IonList as IonGrid, IonContent, IonPage, IonProgressBar, IonItem, IonRow, IonCol, IonToggle, IonInput, IonButton, IonChip, IonBadge, } from '@ionic/react';
import React, { useState } from 'react';
import Toolbar from '../components/Toolbar'
import { useQuery, gql } from '@apollo/client';
import { useMutationWithElevatedRole } from '../hooks/hasuraHooks';
import { EditableShape, MiniatureShape } from '../components/MinitatureShape';


export const TalpaVersion: React.FC = () => {

  const { loading, data } = useQuery(gql`query{
      talpa_hardware_versions (order_by: {id: asc}, where: {archived: {_eq: false}}) {
        id

        has_chopper
        has_arm
        archived

        release_date
        description
      }
  }`, {pollInterval: 1000})
  return (
    <IonPage>
      <Toolbar name="Talpa Versions"/>
      <IonContent fullscreen>
         {loading && <IonProgressBar type="indeterminate"/>}
         {data && <IonGrid>
            <VersionItem header/>
            {data.talpa_hardware_versions.map((version: any) => <VersionItem version={version} key={version.id}/>)}
        </IonGrid>}
      </IonContent>
    </IonPage>
  );
};


export const VersionItem: React.FC<any> = ({version, header}: any) => {
  const [data, setData] = useState(version)
  const changed = !areEqual(data, version)
  // Helper to handle onIonChange events
  const changeHandler = (dataKey: string, eventKey: string = "value") => (e: any) => {
    setData((prev: any) => ({...prev, [dataKey]: e.detail[eventKey]}))
  }

  const [updateVersion] = useMutationWithElevatedRole(gql`
    mutation UpdateVersion($id: Int!, $_set: talpa_hardware_versions_set_input = {}) {
      update_talpa_hardware_versions_by_pk(pk_columns: {id: $id}, _set: $_set) {
        id
      }
    }
  `)

  const save = () => {
    const _set = {...data}
    if (_set.id) {delete _set.id}  // Should not be changed
    if (_set.__typename) {delete _set.__typename}   // Remove (from query but cannot be set)
    updateVersion({variables: {id: version.id, _set}})
  }

  return <IonRow style={{fontWeight: header ? "bold": undefined, alignItems: "center"}}>
    <IonCol size="1">
      {header && <>Version</>}
      {!header && <>V{version.id}</>}
    </IonCol>

    <IonCol size="1">
      {header && "Fräser"}
      {!header && <IonToggle checked={data.has_chopper} onIonChange={changeHandler("has_chopper", "checked")}/>}
    </IonCol>

    <IonCol size="1">
      {header && "Arm"}
      {!header && <IonToggle checked={data.has_arm} onIonChange={changeHandler("has_arm", "checked")}/>}
    </IonCol>

    <IonCol size="1">
      {header && "Archiviert"}
      {!header && <IonToggle onIonChange={changeHandler("archived", "checked")} checked={data.archived}/>}
    </IonCol>

    <IonCol>
      {header && "Beschreibung"}
      {!header && <IonInput value={data.description} style={{border: "1px solid lightgray"}} onIonChange={changeHandler("description")}/>}
    </IonCol>

    <IonCol size="1">
      {!header && changed && <>
        <IonButton size="small" onClick={() => setData({...version})}>Reset</IonButton>
        <IonButton size="small" onClick={save}>Save</IonButton>
      </>}
    </IonCol>
  </IonRow>
}


const areEqual = (obj1: any, obj2: any) => {
  // Compare objects (with stringify the key order matters)
  const keys1 = Object.keys(obj1 || {}).sort()
  const keys2 = Object.keys(obj2 || {}).sort()
  if (JSON.stringify(keys1) !== JSON.stringify(keys2)) {return false}
  return !keys1.some((k: string) => JSON.stringify(obj1[k]) !== JSON.stringify(obj2[k]))
}