import { ApolloCache, ApolloQueryResult, DefaultContext, DocumentNode, MutationTuple, OperationVariables, QueryHookOptions, QueryResult, SubscriptionHookOptions, SubscriptionResult, TypedDocumentNode, useMutation, useQuery, useSubscription } from '@apollo/client';
import { useCallback } from 'react';
import getMaxRole from '../components/lib/getMaxRole';

export function useQueryWithElevatedRole<TData = any, TVariables extends OperationVariables = OperationVariables>
  (query: DocumentNode | TypedDocumentNode<TData, TVariables>, options: QueryHookOptions<TData, TVariables> = {}): QueryResult<TData, TVariables> {
  options.context = { headers: { "x-hasura-role": getMaxRole() } }
  const q = useQuery(query, options)
  // Fix for refetch (crashes app if refetch is called after component is unmounted). See https://github.com/apollographql/react-apollo/issues/3862#issuecomment-629856710
  const _refetch =
    useCallback(
      (data: Partial<TVariables>) => {
        const p = new Promise((res, rej) => {
          setTimeout(
            () =>
              q.refetch(data).then(res).catch(rej),
            0
          )
        });
        return p as Promise<ApolloQueryResult<TData>>;
      },
      [q]
    )
  return { ...q, refetch: _refetch }
}


export const useMutationWithElevatedRole: (query: any, options?: any) => MutationTuple<any, OperationVariables, DefaultContext, ApolloCache<any>> = (query: any, options: any = {}) => {
  options.context = { headers: { "x-hasura-role": getMaxRole() } }
  return useMutation(query, options)
}

export function useSubscriptionWithElevatedRole<TData = any, TVariables extends OperationVariables = OperationVariables>(subscription: DocumentNode | TypedDocumentNode<TData, TVariables>, options?: SubscriptionHookOptions<TData, TVariables>): SubscriptionResult<TData, any> {
  if (options === undefined) {
    options = {}
  }
  options.context = { use_max_role : true, ...options.context }
  return useSubscription(subscription, options)
}