import { IonButton, IonCard, IonCardContent, IonIcon, IonItem, IonList, IonModal } from '@ionic/react';
import { close, settings } from 'ionicons/icons';
import React, {useState} from 'react';


export const SidePanel: React.FC<any> = ({ info, children}) => {
    const [showSettings, setShowSettings] = useState(false)
    return <>
        {children && !showSettings && <IonButton onClick={() => setShowSettings(true)} style={{position: "absolute", right: 0, zIndex: 1000}}>
            <IonIcon icon={settings} slot="icon-only"/>
        </IonButton>}
        {info && <IonCard style={{maxWidth: 400, right: 0, position: "absolute", marginTop: 40, padding: 0, zIndex: 1000}}>
            <IonCardContent>
                {info}
            </IonCardContent>
        </IonCard>}
        {children && <IonModal isOpen={showSettings} showBackdrop={false} class="side-panel-modal" onDidDismiss={() => setShowSettings(false)}>
            <IonItem>
                <IonButton slot="end" onClick={() => setShowSettings(false)}><IonIcon icon={close} slot="icon-only"/></IonButton>
            </IonItem>
            {children}
        </IonModal>}
    </>
}