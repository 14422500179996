import { useRef, useState } from "react";


export const useKonvaStageProps = ({onChange = () => {}, height, width}: any) => {
  const [stageProps, setStageProps] = useState<any>({
    // Offsets and scale of stage
    scale: 10,
    x: 0,
    y: 0,

    // For mobile touch
    lastCenter: null,
    lastDist: 0,
  });
  const stageRef = useRef<any>(null)

  width = width || window.visualViewport.width;
  height = height || window.visualViewport.height;

  function isTouchEnabled() {
    return ( 'ontouchstart' in window ) ||
          ( navigator.maxTouchPoints > 0 ) ||
          ( navigator.maxTouchPoints > 0 );
  }

  const handleWheel = (event: any) => {
    const scaleBy = 1.05
    event.evt.preventDefault();
    if (stageRef.current !== null) {
      const stage = stageRef.current;
      const oldScale = stage.scaleX();
      const { x: pointerX, y: pointerY } = stage.getPointerPosition();
      const mousePointTo = {
        x: (pointerX - stage.x()) / oldScale,
        y: (pointerY - stage.y()) / oldScale,
      };
      const newScale = event.evt.deltaY < 0 ? oldScale * scaleBy : oldScale / scaleBy;
      stageProps.scale = newScale
      stageProps.x = pointerX - mousePointTo.x * newScale
      stageProps.y = pointerY - mousePointTo.y * newScale
      setStageProps({...stageProps})
      onChange()
    }
  }

   const handleDblClick = (event: any) => {
    const scaleBy = 2.5

    if (stageProps.lastCenter !== null) {
      return
    }

    event.evt.preventDefault();
    if (stageRef.current !== null) {
      const stage = stageRef.current;
      const oldScale = stage.scaleX();
      const { x: pointerX, y: pointerY } = stage.getPointerPosition();
      const mousePointTo = {
        x: (pointerX - stage.x()) / oldScale,
        y: (pointerY - stage.y()) / oldScale,
      };
      const newScale = oldScale * scaleBy;
      stageProps.scale = newScale
      stageProps.x = pointerX - mousePointTo.x * newScale
      stageProps.y = pointerY - mousePointTo.y * newScale
      setStageProps({...stageProps})
      onChange()
    }
  }

  function getDistance(p1: any, p2: any) {
    return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
  }

  function getCenter(p1: any, p2: any) {
    return {
      x: (p1.x + p2.x) / 2,
      y: (p1.y + p2.y) / 2,
    };
  }

  function handleTouch(e: any) {
    e.evt.preventDefault();
    var touch1 = e.evt.touches[0];
    var touch2 = e.evt.touches[1];

    const stage = stageRef.current;
    if (stage === null) {
      return
    }

    if (touch1 && touch2) {
      if (stage.isDragging()) {
        stage.stopDrag();
      }

      var p1 = {
        x: touch1.clientX,
        y: touch1.clientY
      };
      var p2 = {
        x: touch2.clientX,
        y: touch2.clientY
      };

      if (!stageProps.lastCenter) {
        return setStageProps((prev: any) => ({...prev, lastCenter: getCenter(p1, p2)}))
      }
      var newCenter = getCenter(p1, p2);

      var dist = getDistance(p1, p2);

      if (!stageProps.lastDist) {
        stageProps.lastDist = dist;
      }

      // local coordinates of center point
      var pointTo = {
        x: (newCenter.x - stageProps.x) / stageProps.scale,
        y: (newCenter.y - stageProps.y) / stageProps.scale
      };

      stageProps.scale = stageProps.scale * (dist / stageProps.lastDist);

      // calculate new position of the stage
      var dx = newCenter.x - stageProps.lastCenter.x
      var dy = newCenter.y - stageProps.lastCenter.y
      stageProps.x = newCenter.x - pointTo.x * stageProps.scale + dx
      stageProps.y =  newCenter.y - pointTo.y * stageProps.scale + dy
      stageProps.lastDist = dist;
      stageProps.lastCenter = newCenter;
      setStageProps({...stageProps})
      onChange()
    }

    else if (touch1) {
      const point = ({x: touch1.clientX, y: touch1.clientY})
      if (!stageProps.lastCenter) {
        return setStageProps((prev: any) => ({...prev, lastCenter: point}))
      }
      // local coordinates of center point
      var pointTo = {
        x: (point.x - stageProps.x) / stageProps.scale,
        y: (point.y - stageProps.y) / stageProps.scale
      };
      var dx = point.x - stageProps.lastCenter.x
      var dy = point.y - stageProps.lastCenter.y
      stageProps.x = point.x - pointTo.x * stageProps.scale + dx
      stageProps.y =  point.y - pointTo.y * stageProps.scale + dy
      stageProps.lastCenter = point;
      setStageProps({...stageProps})
      onChange()
    }
  }

  function handleTouchEnd(e: any) {
    e.evt.preventDefault()
    // Hack to prevent double tap from firing: only reset lastCenter to null after timeout. Double tap callback checks that is is null
    setTimeout(() => setStageProps((prev: any) => ({...prev, lastCenter: null, lastDist: 0})), 300)
  }

  const center = (newScale: number, offsetX : number = 0, offsetY = 0, rotation = 0) => {
    const stage = stageRef?.current
    if (!stage) return
    const rotationDeg = rotation * 180. / Math.PI
    const x = width / 2 - offsetX * newScale * Math.cos(rotation) - offsetY * newScale * Math.sin(rotation)
    const y = height / 2 + offsetY * newScale * Math.cos(rotation) - offsetX * newScale * Math.sin(rotation)
    stage.position({x: x, y: y})
    stage.scale({x: newScale, y: newScale})
    stage.rotation(rotationDeg)  // Konva needs degrees, not radians!
    setStageProps((prev: any) => ({...prev, scale: newScale, x: x, y: y, rotation: rotationDeg}))
  }

  return [stageProps.scale, {
    onWheel: handleWheel,
    onDblClick: handleDblClick,
    onDblTap: handleDblClick,
    onTouchMove: handleTouch,
    onTouchEnd: handleTouchEnd,
    width: width,
    height: height,
    scaleX: stageProps.scale,
    scaleY: stageProps.scale,
    x: stageProps.x,
    y: stageProps.y,
    rotation: stageProps.rotation,
    ref: stageRef,
    draggable: !isTouchEnabled(),
  }, center]
}