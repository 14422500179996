import { IonModal, IonContent, IonButton, IonToolbar, IonTitle, } from '@ionic/react';
import React, {useMemo} from 'react';
import { gql, useMutation } from '@apollo/client';
import EditableCard from './EditableCard'
import Input, {yup, useYupValidationResolver, Toggle} from './Input'
import { useForm } from "react-hook-form";
import { goBackOrGoto } from '../history'
import ApolloStatus from '../components/ApolloStatus'


const XavierForm: React.FC<any> = ({xavier, onFinish}) => {
  const { control, errors, reset, handleSubmit, formState } = useForm({
      resolver: useYupValidationResolver(useMemo(() => yup.object({
          hostname: yup.string().nullable().required("Required"),
          partNumber: yup.string().nullable(),
          serialNumber: yup.string().nullable(),
          operational: yup.bool().nullable().required(),
      }), [])),
      defaultValues: xavier,
  })

  const [updateXavier, updateXavierApollo] = useMutation(gql`mutation
      update($hostname: String, $description: String, $partNumber: String, $serialNumber: String, $operational: Boolean, $eth0Mac: String) {
          updateXavier(
              input: {where: {id: ${xavier.id}},
              data: {hostname: $hostname, description: $description, partNumber: $partNumber, serialNumber: $serialNumber, operational: $operational, eth0Mac: $eth0Mac}}
          ) {xavier {id}}
  }`)

  const [deleteXavier, deleteXavierApollo] = useMutation(gql`mutation {
      deleteXavier(input: {where: {id: ${xavier.id}}}) {xavier {id}}
  }`)
  const onSubmit = handleSubmit((data: any) => {updateXavier({variables: data}).then(() => {onFinish()}).catch(e=>null)})


  return <IonModal onDidDismiss={() => onFinish()} isOpen={true}>
        <IonToolbar color="primary"><IonTitle>Edit xavier</IonTitle>
            {!formState.isDirty && <IonButton color="light" slot="end" fill="clear" onClick={() => onFinish()}>cancel</IonButton>}
            {formState.isDirty && <IonButton slot="end" fill="clear" color="light" onClick={() => reset()}>Undo</IonButton>}
            {formState.isDirty && <IonButton slot="end" color="secondary" onClick={() => onSubmit()}>Save changes</IonButton>}
        </IonToolbar>
        <IonContent>
        <ApolloStatus loadings={[updateXavierApollo.loading, deleteXavierApollo.loading]} errors={[updateXavierApollo.error, deleteXavierApollo.error]}/>
        <form onSubmit={onSubmit}>
            <Input control={control} errors={errors} name="hostname"/>
            <Input control={control} errors={errors} name="partNumber"/>
            <Input control={control} errors={errors} name="serialNumber"/>
            <Input control={control} errors={errors} name="eth0Mac"/>
            <Input control={control} errors={errors} name="operational" component={Toggle}/>
            <Input control={control} errors={errors} name="description" component={EditableCard} showLabel={false}/>
            <input type="submit" style={{visibility: "hidden"}}/>
            <IonButton onClick={() => {deleteXavier().then(() => {onFinish(); goBackOrGoto("/xaviers")}).catch(e => null)}} color="danger" expand="full">delete xavier</IonButton>
        </form>
        </IonContent>
    </IonModal>
}

export default XavierForm;
