import { IonContent, IonPage, IonItem, IonLabel, IonBadge, IonFab, IonFabButton, IonSelect, IonSelectOption, IonItemDivider, IonSearchbar, IonToolbar, IonButton, IonIcon, IonInput, IonGrid, IonRow, IonCol, IonTextarea, } from '@ionic/react';
import React, { useCallback, useState } from 'react';
import Toolbar from '../components/Toolbar'
import { useMutationWithElevatedRole, useSubscriptionWithElevatedRole } from '../hooks/hasuraHooks';
import { gql } from '@apollo/client';
import { GraphQLState } from '../components/GraphQLState';


export const TranslationRow: React.FC<any> = ({data, langKeys, updateTranslation}) => {
  const [selected, setSelected] = useState(false)
  const [hover, setHover] = useState(false)
  
  const langValues = langKeys.map((langKey: string) => data[langKey])
  const [tmpLangValues, setTmpLangValues] = useState(langValues)
  const setTmpLangValue = useCallback(
    // Replace value at index
    (index: number, newValue: string) => setTmpLangValues(tmpLangValues.map((v: string, i: number) => i === index ? newValue : v))
  , [tmpLangValues, setTmpLangValues])
  const changed = langValues.some((v: string, i: number) => v !== tmpLangValues[i])
  
  const save = useCallback(() => {
    updateTranslation({variables: {
      id: data.id,
      _set: Object.fromEntries(tmpLangValues.map((v: string, i: number) => [langKeys[i], v])),
    }})
    setSelected(false)
  }, [tmpLangValues, setSelected])
  const reset = useCallback(() => {
    setTmpLangValues(langValues)
    setSelected(false)
  }, [setTmpLangValue, langValues])
 
  return <IonRow style={{minHeight: 100, display: "flex", cursor: "pointer", border: (hover || selected) ? '1px solid lightgray' : null}}
    onClick={() => !selected && setSelected(true)} onMouseOver={() => setHover(true)} onMouseOut={() => setHover(false)}>
    {langValues.map((v: string, i: number) => <IonCol key={i}>

      {selected ? <>
        <IonTextarea color="secondary" autoGrow={true} style={{"--padding-start": 0, "--padding-top": 0}}
          disabled={data?.used_by_app && langKeys[i] === "en" /* changing English app key does not work, change must be made in app.*/}
          value={tmpLangValues[i]} onIonChange={({detail: {value}}: any) => value?.length && setTmpLangValue(i, value)}/>
      </> : <>
        <TranslationField text={v}/>
      </>}
    </IonCol>)}
  
    <IonCol size="auto">
      <div style={{width: 80, display: "flex", flexWrap: "wrap"}}>
        {selected && <>
          {changed && <IonButton size="small" onClick={save}>Save</IonButton>}
          {changed && <IonButton size="small" onClick={reset}>Reset</IonButton>}
          {!changed && <IonButton size="small" onClick={(e: any) => setSelected(false)}>Cancel</IonButton>}
        </>}
        {hover && !selected && <a>
          Click to edit
        </a>}
      </div>
    </IonCol>

  </IonRow>
}


export const TranslationField: React.FC<any> = ({text}) => {
  if (text) {
    return <>
      {text}
    </>
  }
  else {
    return <IonBadge>No translation yet</IonBadge>
  }
}


export const TranslationsPage: React.FC = () => {
  const [limit, setLimit] = useState<any>(100)
  const [langKeys, setLangKeys] = useState(["en", "de"])  // Languages to be used
  const [searchString, setSearchString] = useState("")
  const langKeyForSorting = langKeys[0] || "en"  // Used for sorting
  const where = (() => {
    if (searchString?.length < 1) {return {}}  // No filter (searchbar is empty)
    return {_or: langKeys.map((langKey: string) => ({[langKey]: {_regex: searchString}}))}  // Must regex the search string with one of the languages
  })()

  const { loading, data, error } = useSubscriptionWithElevatedRole(gql`
    subscription GetTranslations($limit: Int!, $where: translations_bool_exp) {
      translations(order_by: {${langKeyForSorting}: asc}, limit: $limit, where: $where) {
        id
        ${langKeys.join(" ")}
        used_by_app
      }
  }`, {variables: {limit, where}});
  
  const [insertTranslations] = useMutationWithElevatedRole(gql`
    mutation InsertTranslation($objects: [translations_insert_input!] = []) {
      insert_translations(objects: $objects) {
        returning {
          id
        }
      }
    }
  `)

  const [updateTranslation] = useMutationWithElevatedRole(gql`
    mutation UpdateTranslation($id: Int!, $_set: translations_set_input!) {
      update_translations_by_pk(pk_columns: {id: $id}, _set: $_set) {
        id
      }
    }
  `)

  return (
    <IonPage>
      <Toolbar name="Translations"/>
      <IonToolbar>
        <div style={{display: "flex"}}>
          <IonItem lines="none">
            <IonLabel position="stacked">Languages</IonLabel>
            <IonSelect multiple value={langKeys} onIonChange={(e: any) => setLangKeys(e.detail.value)} style={{minWidth: 100}}>
              {["en", "de", "fr", "pl", "pt", "es", "it", "ua", "la"].map((l: string) => <IonSelectOption key={l} value={l}>{l}</IonSelectOption>)}
            </IonSelect>
          </IonItem>
          <IonItem lines="none" style={{display: "flex", flexGrow: 2}}>
            <IonSearchbar onIonChange={((e: any) => setSearchString(e.detail.value))} style={{padding: 2}}/>
          </IonItem>
        </div>
      </IonToolbar>

      <GraphQLState error={error} loading={loading} />

      <IonContent fullscreen>
        <IonGrid>
          
          <IonRow>
            {langKeys.map((langKey: string) => <IonCol style={{fontWeight: 700}}>
              {langKey}
            </IonCol>)}

            <IonCol size="auto">
              <div style={{width: 80, display: "flex", flexWrap: "wrap"}}/>
            </IonCol>

          </IonRow>

          {data?.translations?.map((data: any) => <TranslationRow data={data} key={data.id} langKeys={langKeys} updateTranslation={updateTranslation} />)}
        </IonGrid>
        {data?.translations?.length === limit && <IonItemDivider>
          Only showing {limit} elements. 
          <IonButton onClick={() => setLimit(null)}>
            Click to show all
          </IonButton>
        </IonItemDivider>}
        {data?.translations?.length === 0 && <IonItemDivider>No translations found</IonItemDivider>}
      </IonContent>
    </IonPage>
  );
};