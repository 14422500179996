import { useUserId } from '../hooks/userHooks';
import React, { useEffect, useState } from 'react';

import { useMutationWithElevatedRole, useSubscriptionWithElevatedRole, } from '../hooks/hasuraHooks';
import { gql } from '@apollo/client';
import { IonButton, IonIcon, IonSelectOption, IonSpinner, IonTextarea } from '@ionic/react';
import { send } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import { ComponentIdSelect, ComponentType } from './ComponentIdSelect';


export const AddMessage: React.FC<any> = ({fixedTaurusId = null}) => {
  const [text_md, setTextMd] = useState("")
  const [taurusId, setTaurusId] = useState<any>(fixedTaurusId)
  const author_user_id = useUserId()
  const {t} = useTranslation()
  const [insertMessages, insertMessagesContext] = useMutationWithElevatedRole(gql`
    mutation InsertMessages($objects: [messages_insert_input!] = []) {
      insert_messages(objects: $objects) {
        returning {
          id text_md
        }
      }
    }
  `)
  
  const {data} = useSubscriptionWithElevatedRole(
    gql`
      subscription TaurusesWithConfig {
        tauruses(order_by: {id: asc}) {
          id 
          taurus_configs(limit: 1, order_by: {id: desc}) {
            robot_config_id
          }
        }
      }
    `, {}
  )
  const taurusAndConfigs = (data?.tauruses
    ?.map(({id, taurus_configs}: any) => ({id, robotConfigId: taurus_configs?.at(0)?.robot_config_id}))
    ?.filter(({robotConfigId}: any) => robotConfigId)
  ) || []
  const robot_config_id = taurusAndConfigs.find(({id}: any) => taurusId === id)?.robotConfigId
  useEffect(() => {
    if (insertMessagesContext?.data?.insert_messages?.returning?.at(0)?.text_md === text_md) {
        setTextMd("")
        insertMessagesContext.reset()
    }
  }, [insertMessagesContext, text_md])

  return <div style={{display: "flex", flexDirection: "column", minHeight: 150}}>
    <IonTextarea autoGrow={false} rows={4} placeholder={t("Type message here")} value={text_md} onIonChange={({detail: {value}}: any) => setTextMd(value)}/>
    {text_md && !insertMessagesContext?.loading && <div style={{display: "flex"}}>
        {fixedTaurusId === null && <ComponentIdSelect componentType={ComponentType.Taurus} componentId={taurusId}
            showProgressBar={false}
            setComponentId={(newValue: number) => setTaurusId(newValue)}
            selectChildren={<IonSelectOption value={null}>{t("No specific robot")}</IonSelectOption>}/>}

        <IonButton disabled={!author_user_id  || (fixedTaurusId && !robot_config_id)} slot="end" color="secondary" onClick={() => insertMessages({variables: {objects: [
            {author_user_id, text_md, robot_config_id}
        ]}})}>
        <IonIcon icon={send} slot="start"/>
        {t("Send")}
        </IonButton>
        {insertMessagesContext?.loading && <IonSpinner slot="end"/>}
    </div>}
  </div>
}