import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from "i18next-http-backend";

import translationDE from './locales/de/translation.json';
import translationES from './locales/es/translation.json';
import translationFR from './locales/fr/translation.json';
import translationPL from './locales/pl/translation.json';
import { gql } from '@apollo/client';
import client, { getJwt } from './ApolloClient';

const resources = {
  de: {
    translation: translationDE,
  },
  es: {
    translation: translationES,
  },
  fr: {
    translation: translationFR,
  },
  pl: {
    translation: translationPL,
  },
};


i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(Backend)
  .use({
    name: 'returnKeyIfEnglish', // handling english translation keys
    type: 'postProcessor',
    process: (value:any, key:any, options:any, translator:any) => {
      if (translator.language.startsWith('en')) {
        return key;
      }
      return value;
    },
  })
  .init({
    resources,
    detection: { order: ["navigator", 'cookie', 'localStorage', "path"] },
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;