import React from 'react';
import Loading from '../components/Loading'
import Refresher from '../components/Refresher'
import Error from '../components/Error'

const ApolloStatus: React.FC<any> = ({errors = [], loadings = [], refetch = null}) => {
    return <>
        {loadings.some((l: any) => l) && <Loading/>}
         <Error errors={errors}/>
        {refetch && <Refresher refetch={refetch}/>}
    </>
}
export default ApolloStatus;
