import { IonIcon, IonButton } from '@ionic/react';
import React from 'react';
import { expand } from 'ionicons/icons';

const ExpandButton: React.FC<any> = ({...props}) => {
    props.fill = props.fill || "clear"

    return <IonButton {...props}>
        <IonIcon icon={expand} slot="start"/>
        see more
    </IonButton>
};

export default ExpandButton;
