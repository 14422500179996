import { IonList as IonGrid, IonContent, IonPage, IonProgressBar, IonItem, IonRow, IonCol, IonToggle, IonInput, IonButton, IonChip, IonBadge, IonSelect, IonSelectOption, IonTextarea, IonLabel, } from '@ionic/react';
import React, { useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import { useMutationWithElevatedRole } from '../hooks/hasuraHooks';
import { EditableShape, } from '../components/MinitatureShape';
import Toolbar from '../components/Toolbar';
import { areEqual } from '../components/lib/areEqual';


export const Nozzles: React.FC = () => {

  const { loading, data } = useQuery(gql`query{
      sprayer_nozzles(order_by: {id: asc}) {
        id

        spray_pattern
        spray_pattern_height_m
        description

        archived
        developer_only
      }
  }`, {pollInterval: 1000})
  return (
    <IonPage>
      <Toolbar name="Spritzdüsen"/>
      <IonContent fullscreen>
         {loading && <IonProgressBar type="indeterminate"/>}
         {data && <IonGrid>
            <NozzleItem header/>
            {data.sprayer_nozzles.map((nozzle: any) => <NozzleItem nozzle={nozzle} key={nozzle.id}/>)}
        </IonGrid>}
      </IonContent>
    </IonPage>
  );
};


export const NozzleItem: React.FC<any> = ({nozzle, header}: any) => {
  const [data, setData] = useState(nozzle)
  const changed = !areEqual(data, nozzle)

  // Helper to handle onIonChange events
  const changeHandler = (dataKey: string, eventKey: string = "value", floatify=false) => (e: any) => {
    let newValue = e.detail[eventKey]
    if (floatify) {
      newValue = parseFloat(newValue)
    }
    setData((prev: any) => ({...prev, [dataKey]: newValue}))
  }

  const [updateNozzle] = useMutationWithElevatedRole(gql`
    mutation UpdateNozzle($id: Int!, $_set: sprayer_nozzles_set_input = {}) {
      update_sprayer_nozzles_by_pk(pk_columns: {id: $id}, _set: $_set) {
        id
      }
    }
  `)

  const [insertNozzle] = useMutationWithElevatedRole(gql`
    mutation InsertNozzle($object: sprayer_nozzles_insert_input!) {
      insert_sprayer_nozzles_one(object: $object) {
        id
      }
    }
  `)

  const save = () => {
    const _set = {...data}
    if (_set.id) {delete _set.id}  // Should not be changed
    if (_set.__typename) {delete _set.__typename}   // Remove (from query but cannot be set)
    updateNozzle({variables: {id: nozzle.id, _set}})
  }

  const insert = (object: any) => {
    if (object.id) {delete object.id}  // Should not be changed
    if (object.__typename) {delete object.__typename}   // Remove (from query but cannot be set)
    insertNozzle({variables: {object}})
  }

  return <IonRow style={{fontWeight: header ? "bold": undefined, alignItems: "center"}}>
    <IonCol size="1">
      {header && <>Spritzbild</>}
      {!header && <div style={{display: "flex"}}>
        <EditableShape points={data.spray_pattern} onChange={changeHandler("spray_pattern")}/>
      </div>}
    </IonCol>

    <IonCol size="1">
      {header && <>Höhe bei Spritzbild-Messung (m)</>}
      {!header && <div>
        <IonInput min="0.001" max="1.5" step="0.001" onIonChange={changeHandler("spray_pattern_height_m", "value", true)} value={data.spray_pattern_height_m} type="number"/>
      </div>}
    </IonCol>

    <IonCol>
      {header && "Beschreibung"}
      {!header && <IonTextarea rows={2} value={data.description} style={{border: "1px solid lightgray"}} onIonChange={changeHandler("description")}/>}
    </IonCol>

    <IonCol size="0.5">
      {header && "Status"}
      {!header && <div style={{display: "flex", flexDirection: "column"}}>
        <IonLabel position="stacked">archived</IonLabel>
        <IonToggle checked={data.archived} onIonChange={changeHandler("archived", "checked")} style={{paddingBottom: "4px !important"}}/>
        <IonLabel position="stacked">dev only</IonLabel>
        <IonToggle checked={data.developer_only} onIonChange={changeHandler("developer_only", "checked")}/>
      </div>}
    </IonCol>

    <IonCol size="1">
      {!header && <div style={{display: "flex", flexDirection: "column"}}>
        {changed && <>
          <IonButton size="small" onClick={save}>Save</IonButton>
          <IonButton size="small" onClick={() => setData({...nozzle})}>Reset</IonButton>
        </>}
        {!changed && <IonButton fill="clear" size="small" onClick={() => insert({...nozzle})}>Copy</IonButton>}
      </div>}
    </IonCol>
  </IonRow>
}