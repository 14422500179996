import { IonBackButton, IonBadge, IonButton, IonButtons, IonCard, IonCardContent, IonCheckbox, IonChip, IonContent, IonIcon, IonInput, IonItem, IonLabel, IonPopover, IonProgressBar, IonRange } from '@ionic/react';
import { pause, play, playForward, playSkipBack, playSkipForward } from 'ionicons/icons';
import React, {useEffect, useState} from 'react';
import {Stage, Layer, Line, Arrow, Text, Circle, Label, Tag, Group} from 'react-konva';
import { KonvaStage, useKonvaStageProps } from '../shared-components/KonvaStage';
import { rainbowColor } from '../rainbowColor';
import { ScoreErrors } from './EvaluationScore';
import { useFetchJson } from './ShowEvaluation';
import { SidePanel } from './SidePanel';
import { Stem, StemSliders, useStemConfig } from './Stem';
import { Axle, RobotArrow } from '../shared-components/KonvaRobot';
import { KonvaCroplines } from '../shared-components/KonvaCroplines';


export const CroplineTimeline: React.FC<any> = ({evaluation}: any) => {
  const {data, loading} = useFetchJson(`/evaluation-files/ev${evaluation["id"]}.croplines_base_link.json`)
  const [index, setIndex] = useState(0)
  const [playing, setPlaying] = useState(false)
  const [period, setPeriod] = useState(100)
  useEffect(() => {
    if (playing) {
      const newIndex = index >= data.length - 1 ? 0 : index + 1
      setTimeout(() => setIndex(newIndex), period)
    }
  }, [playing, index])
  return <IonContent>
    {loading && <IonProgressBar type="indeterminate"/>}
    {data && <>
      <IonItem>
        <IonButtons slot="start">
          <IonButton fill="clear" disabled={index <= 0} onClick={() => setIndex(index - 1)}><IonIcon slot="icon-only" icon={playSkipBack}/></IonButton>
          <IonInput value={period} onIonChange={(e: any) => setPeriod(parseInt(e.detail.value))} type="number" min="1" step="1" max="10000"/>
          <IonButton fill="clear" onClick={() => setPlaying(!playing)}><IonIcon slot="icon-only" icon={playing ? pause : play }/></IonButton>
        </IonButtons>
        <IonRange value={index} onIonChange={(e: any) => setIndex(e.detail.value)} step={1} min={0} max={data.length - 1}/>
        <IonButtons slot="end">
          <IonButton fill="clear" disabled={index >= data.length - 1} onClick={() => setIndex(index + 1)} slot="end"><IonIcon slot="icon-only" icon={playSkipForward}/></IonButton>
        </IonButtons>
      </IonItem>
      <CroplineVisualization msg={data[index]}/>
    </>}
  </IonContent>
}


export const CroplineVisualization: React.FC<any> = ({msg}: any) => {
  const konvaStageProps = useKonvaStageProps({})
  const {stageProps, center, setFixed, state} = konvaStageProps
  useEffect(() => {center(80.); setFixed(true)}, [])
  const trackWidth = 1.5  // assumption for visualization
  const wheelBase = 2.95  // assumption for visualization
  const [event, setEvent] = useState<any>(null)

  if (!msg) {return null}
    
  return <>
    <Group x={0} y={0}>
      <KonvaStage contexts={[]} stageProps={stageProps}>
        <RobotArrow trackWidth={trackWidth}/>
        <Axle trackWidth={trackWidth} x={0}/>
        <Axle trackWidth={trackWidth} x={-wheelBase}/>
        <KonvaCroplines msg={msg}/>
      </KonvaStage>
    </Group>
    <IonItem onClick={setEvent} style={{position: "absolute", width: "100%", height: "100px", overflowY: "auto", display: "flex", flexDirection: "column"}}>
      <IonChip>{new Date(msg.header.stamp.secs * 1e3 + msg.header.stamp.nsecs / 1e6).toISOString()}</IonChip>
      {msg.errors.map((e: any) => <IonBadge color="danger">{e}</IonBadge>)}
    </IonItem>
    <IonPopover event={event} isOpen={!!event} onDidDismiss={() => setEvent(null)} class="fullscreen">
      <code>
        {JSON.stringify(msg)}
      </code>
    </IonPopover>
  </>
}