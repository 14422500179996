import { IonIcon, IonButton } from '@ionic/react';
import React from 'react';
import { add } from 'ionicons/icons';

const AddButton: React.FC<any> = ({...props}) => {
    props.fill = props.fill || "clear"

    return <IonButton {...props}>
        <IonIcon icon={add} slot="start"/>
        add event
    </IonButton>
};

export default AddButton;
